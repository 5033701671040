import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import Common_form from '../../components/Common_form';
import { AiFillHome, AiOutlineAppstore } from 'react-icons/ai';
import LoginModal from '../../components/LoginModel'; // Import the modal
import { useAuth } from '../../context/auth'; // Import the useAuth hook

const ITR_filing_online = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [auth] = useAuth(); // Access auth state from context
  const isLoggedIn = Boolean(auth.token); // Determine if user is logged in based on token

  useEffect(() => {
    // Show the modal if the user is not logged in
    if (!isLoggedIn) {
      setIsModalOpen(true); // Show the modal if not logged in
    }
  }, [isLoggedIn]); // Run effect whenever isLoggedIn changes

  const closeModal = () => {
    setIsModalOpen(false); // Function to close modal
  };

  return (
    <div>
      <Header />
      {/* Page Title */}
      <div className="page-title py-6 bg-slate-300" data-aos="fade">
        <div className="container mx-auto px-4 lg:px-20 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          {/* Title */}
          <h1 className=" text-black text-xl md:text-2xl font-semibold">ITR Filling </h1>

          {/* Refund message */}
          <style jsx>{`
            @keyframes intenseBlink {
              0%, 100% { opacity: 1; color: #f20000; }
              20% { opacity: 1; color: #000000; }
            }
          `}</style>
          <h1
            className="
              text-lg md:text-xl font-bold underline underline-offset-8 
              mb-2 lg:mb-0 lg:ml-4 
              animate-[intenseBlink_1s_ease-in-out_infinite]
            "
          >
            100% Fees Refundable, if Service is not Completed!
          </h1>

          {/* Breadcrumbs */}
          <nav className="breadcrumbs mt-2 lg:mt-0">
            <ol className="flex space-x-2 lg:space-x-4 text-sm items-center">
              <li className="flex items-center">
                <AiFillHome className="mr-1 text-base hover:underline" />
                <Link to={'/'} className="text-black hover:underline text-base">Home</Link>
              </li>
              <li className="text-black">/</li>
              <li className="flex items-center">
                <AiOutlineAppstore className="mr-1 text-base" />
                <Link to={'/all_service'} className="text-black hover:underline text-base">All Services</Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container mx-auto px-4 lg:px-16 py-8">
        <div className="text-center mb-8">
          <h2 className=" lg:text-2xl font-bold border-b-4 border-red-600 inline-block pb-2">
            ITR Filling Online
          </h2>
          <h3 className='pt-4 text-base font-semibold'>Income Tax Return | e-Filing Anywhere Anytime through Make Easy Documents</h3>
        </div>
        
        <hr className="h-px my-9 bg-gray-700 border-none dark:bg-gray-700" />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Left side - Form */}
          <Common_form applicationName="ITR Filling Online" />

          {/* Right side - Content */}
          <div className='bg-slate-200 p-3'>
            <p className='pt-4 mb-4'>
              Filing income tax return is mandated by the Government of India on individuals who earn a specified amount of annual income.
              One must File ITR within the Due Date. This is called ITR Filing Procedure.
            </p>
            <h1 className="text-2xl font-bold mb-4">
              Income Tax Return Filing
            </h1>
            <p>
              Every responsible Indian citizen is required by law to file income taxes with the government. However, you may file your income tax online using today's technologies. This procedure is quick and precise, with no time limits.
            </p>
            <br />
            <p>However, submitting an income tax return in India via the official website necessitates the completion of ITR forms. Individuals must complete a different ITR form than salaried employees. This is an important activity since the income tax agency has a variety of documents designated ITR 1 through ITR 7.</p>
            <br />
            <p>Each form serves a unique purpose and belongs to a distinct category.</p>
          </div>
        </div>
      </div>
      
      {/* Render Login Modal only if not logged in */}
      {!isLoggedIn && isModalOpen && <LoginModal closeModal={closeModal} />}
      
      <Footer />
    </div>
  );
};

export default ITR_filing_online;
