import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaTrash } from 'react-icons/fa';
import axios from 'axios';
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';

const Add_team = () => {
  const [name, setName] = useState('');
  const [designation, setDesignation] = useState('');
  const [description, setDescription] = useState('');
  const [photo, setPhoto] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  // Safe image conversion helper function
  const convertToBase64 = (buffer) => {
    try {
      const uint8Array = new Uint8Array(buffer);
      const chunks = [];
      for (let i = 0; i < uint8Array.length; i++) {
        chunks.push(String.fromCharCode(uint8Array[i]));
      }
      return btoa(chunks.join(''));
    } catch (error) {
      console.error('Error converting image to base64:', error);
      return null;
    }
  };

  // Fetch existing team members on component mount
  useEffect(() => {
    fetchTeamMembers(); // Fetch team members on mount
  }, []);

  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get('/api/teammembers/getAllTeamMembers');
      console.log('Fetched team members:', response.data.teamMembers); // Debug log
      setTeamMembers(response.data.teamMembers || []); // Ensure it's an array
    } catch (error) {
      console.error('Error fetching team members:', error);
      showCustomToast('Failed to fetch team members.','error'); // Show error notification
      setTeamMembers([]); // Reset team members on error
    }
  };

  const handleFileChange = (event) => {
    setImageFiles(event.target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('designation', designation);
    formData.append('description', description);
    if (photo) {
      formData.append('images', photo); // Append photo as well
    }

    // Append additional images if any
    for (let i = 0; i < imageFiles.length; i++) {
      formData.append('images', imageFiles[i]);
    }

    try {
      const response = await axios.post('/api/teammembers/createTeamMember', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      console.log('Team Member created successfully:', response.data);
      showCustomToast('Team member created successfully!'); // Show success notification
      fetchTeamMembers(); // Refresh the team members list
      setName('');
      setDesignation('');
      setDescription('');
      setPhoto(null);
      setImageFiles([]); // Reset image files
    } catch (error) {
      console.error('Error creating team member:', error);
      showCustomToast('Error creating team member.','error'); // Show error notification
    }
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setIsModalOpen(true);
  };

  const confirmDeletion = async () => {
    try {
      await axios.delete(`/api/teammembers/deleteTeamMember/${deleteId}`);
      fetchTeamMembers(); // Refresh the list after deletion
      showCustomToast('Team member deleted successfully!'); // Show success notification
    } catch (error) {
      console.error('Error deleting team member:', error);
      showCustomToast('Error deleting team member.','error'); // Show error notification
    } finally {
      setIsModalOpen(false);
      setDeleteId(null); // Reset deleteId after deletion
    }
  };

  const getImageSrc = (member) => {
    if (Array.isArray(member.images) && member.images.length > 0 && member.images[0].data) {
      const byteArray = new Uint8Array(member.images[0].data.data || []);
      const base64String = convertToBase64(byteArray);
      return `data:${member.images[0].contentType};base64,${base64String}`;
    }
    return 'https://via.placeholder.com/150'; // Fallback to a default image if needed
  };

  return (
    <div>
      <h2 className="mt-3 mx-3 mb-3 font-bold text-3xl border border-black text-blue-800 text-center bg-gray-300 p-2 rounded-lg shadow-sm transition-transform duration-300">
      Add Our Team Menmbers
      </h2>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: 'auto', opacity: 1 }}
        exit={{ height: 0, opacity: 0 }}
        className="overflow-x-auto w-full"
      >
        <section className="max-w-4xl p-6 mb-12 mt-10 mx-4 md:mx-auto border border-black bg-blue-100 rounded-md shadow-xl">
        <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
              <div>
                <label className=" font-semibold text-xl " htmlFor="name">
                  Name <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  id="name"
                  type="text"
                  placeholder="Enter Name..."
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-black rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>

              <div>
                <label className=" font-semibold text-xl " htmlFor="photo">
                  Photo
                </label>
                <input
                  id="photo"
                  type="file"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-black rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  onChange={(e) => setPhoto(e.target.files[0])}
                  required
                />
              </div>

              <div>
                <label className=" font-semibold text-xl" htmlFor="designation">
                  Designation <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  id="designation"
                  type="text"
                  placeholder="Enter Designation..."
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-black rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  required
                />
              </div>

              <div>
                <label className=" font-semibold text-xl" htmlFor="description">
                  Description
                </label>
                <input
                  id="description"
                  type="text"
                  placeholder="Enter Description..."
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-black rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
            <button
              type="submit"
              className="mt-4 px-4 py-2 font-bold text-white bg-blue-500 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Add Team Member
            </button>
          </form>
        </section>

        {/* Display existing team members */}
        <section className="mt-8">
          <h2 className="text-2xl text-gray-600 font-semibold mb-4 text-center">Existing Team Members</h2>
          <div className="grid grid-cols-1 gap-4 p-5 sm:grid-cols-2 lg:grid-cols-3">
            {teamMembers.map((member) => (
              <div key={member._id} className="bg-white p-4 justify-center text-center rounded-md shadow-md">
                <div className="flex justify-center items-center">
                  <img
                    src={getImageSrc(member)}
                    alt={member.name}
                    className="w-32 h-32 object-cover rounded-md"
                  />
                </div>

                <h3 className="text-lg font-semibold py-2">Name : {member.name}</h3>
                <p className="text-gray-600">Designation : {member.designation}</p>
                <p className="text-gray-500">Description : {member.description}</p>
                <div className="flex justify-center">
                  <button
                    onClick={() => handleDelete(member._id)}
                    className="mt-2 bg-green-200 rounded-1xl p-2 px-7 text-red-600 hover:underline"
                  >
                    <FaTrash className="inline mr-1" /> Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Confirmation Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-md shadow-md mx-2 sm:mx-0">
              <h3 className="text-lg font-semibold">Are you sure you want to delete this team member?</h3>
              <div className="flex justify-end mt-4">
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="px-4 py-2 mr-2 text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmDeletion}
                  className="px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}

        <CustomToastContainer />
      </motion.div>
    </div>
  );
};

export default Add_team;
