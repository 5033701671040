import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

const TermsConditions = () => {
  return (
    <div>
      <Header />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 bg-white shadow-md rounded-md my-6">
        <h1 className="text-xl sm:text-xl font-semibold text-gray-800 mb-4 pt-3">TERMS & CONDITIONS:</h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-base leading-relaxed ">
        Make Easy Documents is a website owned and operated by Viransh Globle Legal Services Private Limited, 1034, 10th Floor, Gera Imperium Rise, Wipro Circle, Rajiv Gandhi InfoTech Park – Phase 2, Hinjawadi, Pune. India. You understand that by accessing information, resources, services, products, and tools provided by our website, it is necessary for you to accept and comply with the terms and conditions as set forth in this policy (hereinafter referred to as ‘User Agreement’), and as more particularly detailed in As outlined in our Privacy Policy below. 
        You may alter from time to time without notice this User Agreement. You do acknowledge and accept that you must review this User Agreement from time to time in order to become aware of any changes made therein. Your use of the site following such changes will amount to your acceptance of such changes.
        </p>

        <h1 className="text-xl sm:text-xl font-semibold text-gray-800 mb-4 pt-3">RESPONSIBLE USE AND CONDUCT</h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-base leading-relaxed ">
        Whenever you decide to use the Resources, you may be required to give some information about yourself (like ID, email, phone number, addresses, etc.) during registration or in the process of using the Resources. You agree that whatever information you provide, it shall always be true, correct, accurate, and up to date. You will take all measures to ensure that any login information to any accounts created to access our Resources remains secure. This means that you are responsible for what happens with your accounts. Accessing (or trying to access) any of our Resources in any way, except for the way we offer, is not allowed. You agree not to use and/or access (or try to access) any of our Resources using any mechanical, unethical or non-standard means. It is also expressly forbidden to engage in any activities that may engage in denial of service attacks towards our Resources or any servers and/or networks connected to or hosting our Resources. It is strictly forbidden to attempt to copy, reproduce, pirating selling, or even swapping our assets. 
        You Are the Only Entity to Blame and Bear the Weight of Any Charges, Losses, or Damages Able to Be Caused to Us directly or indirectly by Your Unauthorized Attempts Above and You May also incur Some Criminal or Civil Responsibility. You may see that we allow various open communication tools on our site, for example, blog comments and postings, public chat, forums, message boards, newsgroups, product reviews and ratings and many social networks, etc. You comprehend that normally we do not monitor or review effectively prior to the posting of any information which is submitted by users of these various communication means, therefore if the user places any remaining content on our website using these tools.
        </p>

        <h1 className="text-xl sm:text-xl font-semibold text-gray-800 mb-4 pt-3">PRIVACY</h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-base leading-relaxed ">
        We understand that your privacy is extremely important, which is the reason why a separate Privacy Policy has been developed to elaborate how we collect, manage, process, secure and store your personal information. Our Privacy Policy falls within the ambit of this User Agreement.        </p>

        <h1 className="text-xl sm:text-xl font-semibold text-gray-800 mb-4 pt-3">LIMITATION OF WARRANTIES
        </h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-base leading-relaxed ">
          By using our website, you understand and agree that all Resources we provide are “as is” and “as available”. This means that we do not represent or warrant to you that:
        </p>

        <h1 className="text-xl sm:text-xl font-semibold text-gray-800 mb-4 pt-3">LIMITATION OF LIABILITY
        </h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-base leading-relaxed ">
          In conjunction with the Limitation of Warranties as explained above, you expressly understand and agree that any claim against us shall be limited to the amount you paid, if any, for use of products and/or services. www.makeeasydocumentsindia.com will not be liable for any direct, indirect, incidental, consequential or exemplary loss or damages which may be incurred by you as a result of using our Resources, or as a result of any changes, data loss or corruption, cancellation, loss of access, or downtime to the full extent that applicable limitation of liability laws apply.
        </p>

        <h1 className="text-xl sm:text-xl font-semibold text-gray-800 mb-4 pt-3">COPYRIGHTS / TRADEMARKS
        </h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-base leading-relaxed ">
        All the content and materials shown on www.makeeasydocuments.com, including but not limited to text, graphics, website layout, code, images and logos belong to Viransh Globle legal Private Limited and are protected under the relevant copyright and trademark law. Any abuse of this site’s content, which includes but is not limited to the duplication, distribution, presentation or communication of any materials on this site is expressly forbidden unless it has the express permission of Viransh Globle legal Private Limited. TAT of the services provided by us: We follow first come first serve model.</p>

        <h1 className="text-xl sm:text-xl font-semibold text-gray-800 mb-4 pt-3">TERMINATION OF USE

        </h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-base leading-relaxed ">
          You agree that we may, at our sole discretion, suspend or terminate your access to all or part of our website and Resources with or without notice and for any reason, including, without limitation, breach of this User Agreement. Any suspected illegal, fraudulent or abusive activity may be grounds for terminating your relationship and may be referred to appropriate law enforcement authorities. Upon suspension or termination, your right to use the resources we provide will immediately cease, and we reserve the right to remove or delete any information that you may have on file with us, including any account or login information.
        </p>

        <h1 className="text-xl sm:text-xl font-semibold text-gray-800 mb-4 pt-3">GOVERNING LAW
        </h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-base leading-relaxed ">
        The operation of this site is administered by Viransh Globle legal Private Limited situated at the state of Kolkata, India. The site is expected to be available to most countries of the world. And since every country has its own laws that might vary with those in Kolkata, India, upon accessing the said site, you accept the use of the website and any products or services bought on this site shall be governed by the laws and statutes of Kolkata, India without respect to the principle of conflicts of laws and the United Nations Convention on Contracts for the international Sale of Goods. In addition, any proceeding to enforce this User Agreement shall be commenced only in state court seated in Kolkata, India. You hereby submit to the personal jurisdiction of such courts, and any objections to such courts based on jurisdiction, venue or inconvenient forum are hereby waived.

Priority Service : In case of any urgency, additional payment of Rs.499/- + GST on the service fee will help you avail our priority service. Our average respond time of priority service is only between 10 Minutes and 24 business hours.
        </p>

        <h1 className="text-xl sm:text-xl font-semibold text-gray-800 mb-4 pt-3">CANCELLATION AND REFUND
        </h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-base leading-relaxed ">
          Cancellation of order is not possible once the payment has been made. No refunds will be given except in the event of cancellation or non-performance of service by Viransh Globle Legal Services Private Limited. For further information, refer to the Cancellation and Refund Policy.
          Udyog Aadhaar
        </p>

        <h1 className="text-xl sm:text-xl font-semibold text-gray-800 mb-4 pt-3">GUARANTEE
        </h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-base leading-relaxed ">
          Unless otherwise expressed, www.makeeasydocuments.Com expressly disclaims all warranties and conditions of any kind, whether express or implied, including, but not limited to the implied warranties and conditions of merchantability, fitness for a particular purpose and non-infringement.
        </p>

        <h1 className="text-xl sm:text-xl font-semibold text-gray-800 mb-4 pt-3">CONTACT INFORMATION

        </h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-base leading-relaxed ">
          If you have any questions or comments about these our Terms of Service as outlined above, you can contact us:
          Make Easy Documents
          1034, 10th Floor, Gera Imperium Rise, Wipro Circle,
          Rajiv Gandhi InfoTech Park - Phase 2, Hinjawadi, Pune. India        </p>

      </div>
      <Footer />
    </div>
  )
}

export default TermsConditions
