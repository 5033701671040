import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import "react-toastify/dist/ReactToastify.css";

const User_incomecertificate = () => {
    const { id } = useParams();
    const [certificateData, setCertificateData] = useState({
        fullNameEnglish: "",
        fullNameMarathi: "",
        fatherFullNameEnglish: "",
        fatherFullNameMarathi: "",
        incomeCertificate: "",
        dateOfBirth: "",
        age: "",
        gender: "",
        mobileNumber: "",
        service: "",
        address: "",
        state: "",
        district: "",
        pincode: "",
        sourceOfIncome: "",
        purposeOfIncomeCertificate: "",
        application_type: "",
        formPrice: 0,
        Status: "In-Progress",
        rejectedNote: "",
        submitNote: "",
        completedNote: "",
    });

    const [documents, setDocuments] = useState({
        rationCard: null,
        aadharCard: null,
        photoIdentity: null,
        signature: null,
        talithiIncome: null,
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDocument, setShowDocument] = useState(null);
    const [licenseData, setLicenseData] = useState(null);
    const statusEnum = ["In-Progress", "Submitted", "Rejected", "Completed"];

    useEffect(() => {
        const fetchCertificateData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(
                    `/api/incomeCertificate/getbyidincome-certificate/${id}`
                );
                const data = response.data;

                // Populate state with fetched data
                setCertificateData({
                    fullNameEnglish: data.fullNameEnglish || "",
                    fullNameMarathi: data.fullNameMarathi || "",
                    fatherFullNameEnglish: data.fatherFullNameEnglish || "",
                    fatherFullNameMarathi: data.fatherFullNameMarathi || "",
                    incomeCertificate: data.incomeCertificate || "",
                    dateOfBirth: data.dateOfBirth ? new Date(data.dateOfBirth).toISOString().split("T")[0] : "",
                    age: data.age || "",
                    gender: data.gender || "Male",
                    mobileNumber: data.mobileNumber || "",
                    service: data.service || "",
                    address: data.address || "",
                    state: data.state || "",
                    district: data.district || "",
                    pincode: data.pincode || "",
                    sourceOfIncome: data.sourceOfIncome || "",
                    purposeOfIncomeCertificate: data.purposeOfIncomeCertificate || "",
                    application_type: data.application_type || "",
                    formPrice: data.formPrice || 0,
                    Status: data.Status || "In-Progress",
                    rejectedNote: data.rejectedNote || "",
                    submitNote: data.submitNote || "",
                    completedNote: data.completedNote || "",
                    createdAt: data.createdAt || "",
                    updatedAt: data.updatedAt || "",
                });
            } catch (err) {
                setError("Error fetching income certificate data", 'error');
            } finally {
                setLoading(false);
            }
        };
        fetchCertificateData();
    }, [id]);

    const handleUpdate = async (event) => {
        event.preventDefault();
        const formData = new FormData();

        for (const key in certificateData) {
            formData.append(key, certificateData[key]);
        }
        for (const key in documents) {
            if (documents[key]) {
                formData.append(`documents.${key}`, documents[key]); // Match the structure expected by the backend
            }
        }

        try {
            const response = await axios.put(
                `/api/incomeCertificate/updateincome-certificate/${id}`,
                formData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );
            showCustomToast(response.data.message);
        } catch (error) {
            showCustomToast("Failed to update income certificate.", 'error');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCertificateData({ ...certificateData, [name]: value });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (files && files[0]) {
            setDocuments({ ...documents, [name]: files[0] });
        }
    };
    const renderImage = (imageData) => {
        if (imageData && imageData.contentType && imageData.data && imageData.data.data) {
            const byteArray = new Uint8Array(imageData.data.data);
            const base64String = btoa(String.fromCharCode(...byteArray));
            return `data:${imageData.contentType};base64,${base64String}`;
        }
        return null;
    };
    const DocumentPreviewModal = ({ document, onClose }) => {
        if (!document) return null;

        return (
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full">
                    <img
                        src={document}
                        alt=" Make Easy Documents Document"
                        className="max-w-full max-h-[80vh] mx-auto"
                    />
                    <div className="mt-4 flex justify-between">
                        <button
                            className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <a
                            href={document}
                            download
                            className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
                        >
                            Download
                        </a>
                    </div>
                </div>
            </div>
        );
    };

    const DownloadableDocuments = () => {
        if (!licenseData || !licenseData.documents) {
            console.log("License Data or Documents not available"); // Debug log
            return null; // Ensure licenseData and documents are defined
        }
    
        const { receipt, finisheddoc } = licenseData.documents;
        const status = licenseData.Status;
    
        return (
            <div className="col-span-3 bg-white shadow-md p-6 rounded-lg mb-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">
                    Downloadable Documents
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {status === "Submitted" && receipt && (
                        <div className="border p-4 rounded-lg text-center bg-gray-50">
                            <h3 className="font-semibold mb-2">Receipt</h3>
                            <button
                                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                                onClick={() => setShowDocument(renderImage(receipt))}
                            >
                                View Receipt
                            </button>
                        </div>
                    )}
                    {status === "Completed" && finisheddoc && (
                        <div className="border p-4 rounded-lg text-center bg-gray-50">
                            <h3 className="font-semibold mb-2">Finished Document</h3>
                            <button
                                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                                onClick={() => setShowDocument(renderImage(finisheddoc))}
                            >
                                View Document
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    };
    

    if (loading) return <div className="text-center mt-10">Loading...</div>;
    if (error) return <div className="text-center mt-10 text-red-500">{error}</div>;

    // const inputClass = "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm";
    const labelClass = "block text-sm mb-2 font-medium text-gray-700";
    const inputClass = "border border-gray-300 rounded-md p-2 w-full"; // Add other necessary styles as needed

    return (
        <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
            <div className="max-w-5xl mx-auto">
                <div className="bg-white shadow-xl rounded-lg overflow-hidden">
                    <div className="px-4 py-5 sm:p-6">
                        <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">Income Certificate</h1>
                        <DownloadableDocuments />
                        <form onSubmit={handleUpdate} className="space-y-6">
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                {/* Full Name English */}
                                <div>
                                    <label className={labelClass} htmlFor="fullNameEnglish">Full Name (English)</label>
                                    <input
                                        id="fullNameEnglish"
                                        name="fullNameEnglish"
                                        type="text"
                                        className={inputClass}
                                        value={certificateData.fullNameEnglish}
                                        onChange={handleInputChange}
                                        required
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                {/* Full Name Marathi */}
                                <div>
                                    <label className={labelClass} htmlFor="fullNameMarathi">Full Name (Marathi)</label>
                                    <input
                                        id="fullNameMarathi"
                                        name="fullNameMarathi"
                                        type="text"
                                        className={inputClass}
                                        value={certificateData.fullNameMarathi}
                                        onChange={handleInputChange}
                                        required
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                {/* Father's Full Name English */}
                                <div>
                                    <label className={labelClass} htmlFor="fatherFullNameEnglish">Father's Full Name (English)</label>
                                    <input
                                        id="fatherFullNameEnglish"
                                        name="fatherFullNameEnglish"
                                        type="text"
                                        className={inputClass}
                                        value={certificateData.fatherFullNameEnglish}
                                        onChange={handleInputChange}
                                        required
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                {/* Father's Full Name Marathi */}
                                <div>
                                    <label className={labelClass} htmlFor="fatherFullNameMarathi">Father's Full Name (Marathi)</label>
                                    <input
                                        id="fatherFullNameMarathi"
                                        name="fatherFullNameMarathi"
                                        type="text"
                                        className={inputClass}
                                        value={certificateData.fatherFullNameMarathi}
                                        onChange={handleInputChange}
                                        required
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="incomeCertificate" className="block text-gray-600 font-semibold mb-2">Income Certificate</label>
                                    <select
                                        name="incomeCertificate"
                                        id="incomeCertificate"
                                        className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                                        value={certificateData.incomeCertificate}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="" disabled>Select Duration</option>
                                        <option value="1-year">1 Year</option>
                                        <option value="3-year">3 Year</option>
                                    </select>
                                </div>
                                {/* Date of Birth */}
                                <div>
                                    <label className={labelClass} htmlFor="dateOfBirth">Date of Birth</label>
                                    <input
                                        id="dateOfBirth"
                                        name="dateOfBirth"
                                        type="date"
                                        className={inputClass}
                                        value={certificateData.dateOfBirth}
                                        onChange={handleInputChange}
                                        required
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                {/* Age */}
                                <div>
                                    <label className={labelClass} htmlFor="age">Age</label>
                                    <input
                                        id="age"
                                        name="age"
                                        type="number"
                                        className={inputClass}
                                        value={certificateData.age}
                                        onChange={handleInputChange}
                                        required
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                {/* Gender */}
                                <div>
                                    <label className={labelClass} htmlFor="gender">Gender</label>
                                    <select
                                        id="gender"
                                        name="gender"
                                        className={inputClass}
                                        value={certificateData.gender}
                                        onChange={handleInputChange}
                                        required
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    >
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>

                                {/* Mobile Number */}
                                <div>
                                    <label className={labelClass} htmlFor="mobileNumber">Mobile Number</label>
                                    <input
                                        id="mobileNumber"
                                        name="mobileNumber"
                                        type="text"
                                        className={inputClass}
                                        value={certificateData.mobileNumber}
                                        onChange={handleInputChange}
                                        required
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                {/* Service */}
                                <div>
                                    <label className={labelClass} htmlFor="service">Service</label>
                                    <input
                                        id="service"
                                        name="service"
                                        type="text"
                                        className={inputClass}
                                        value={certificateData.service}
                                        onChange={handleInputChange}
                                        required
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                {/* Address */}
                                <div>
                                    <label className={labelClass} htmlFor="address">Address</label>
                                    <input
                                        id="address"
                                        name="address"
                                        type="text"
                                        className={inputClass}
                                        value={certificateData.address}
                                        onChange={handleInputChange}
                                        required
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                {/* State */}
                                <div>
                                    <label className={labelClass} htmlFor="state">State</label>
                                    <input
                                        id="state"
                                        name="state"
                                        type="text"
                                        className={inputClass}
                                        value={certificateData.state}
                                        onChange={handleInputChange}
                                        required
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                {/* District */}
                                <div>
                                    <label className={labelClass} htmlFor="district">District</label>
                                    <input
                                        id="district"
                                        name="district"
                                        type="text"
                                        className={inputClass}
                                        value={certificateData.district}
                                        onChange={handleInputChange}
                                        required
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                {/* Pincode */}
                                <div>
                                    <label className={labelClass} htmlFor="pincode">Pincode</label>
                                    <input
                                        id="pincode"
                                        name="pincode"
                                        type="text"
                                        className={inputClass}
                                        value={certificateData.pincode}
                                        onChange={handleInputChange}
                                        required
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                {/* Source of Income */}
                                <div>
                                    <label className={labelClass} htmlFor="sourceOfIncome">Source of Income</label>
                                    <input
                                        id="sourceOfIncome"
                                        name="sourceOfIncome"
                                        type="text"
                                        className={inputClass}
                                        value={certificateData.sourceOfIncome}
                                        onChange={handleInputChange}
                                        required
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                {/* Purpose of Income Certificate */}
                                <div>
                                    <label className={labelClass} htmlFor="purposeOfIncomeCertificate">Purpose of Income Certificate</label>
                                    <input
                                        id="purposeOfIncomeCertificate"
                                        name="purposeOfIncomeCertificate"
                                        type="text"
                                        className={inputClass}
                                        value={certificateData.purposeOfIncomeCertificate}
                                        onChange={handleInputChange}
                                        required
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>




                                <div className="md:col-span-3"> {/* Make this span across all columns */}
                                    <h2 className="text-green-600 font-semibold text-center text-2xl">
                                        - Documents Upload -
                                    </h2>
                                </div>
                                {/* File Uploads */}
                                <div>
                                    <label className={labelClass} htmlFor="rationCard">Ration Card</label>
                                    <input
                                        type="file"
                                        id="rationCard"
                                        name="rationCard"
                                        className={inputClass}
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                <div>
                                    <label className={labelClass} htmlFor="aadharCard">Aadhar Card</label>
                                    <input
                                        type="file"
                                        id="aadharCard"
                                        name="aadharCard"
                                        className={inputClass}
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                <div>
                                    <label className={labelClass} htmlFor="photoIdentity">Passport Photo</label>
                                    <input
                                        type="file"
                                        id="photoIdentity"
                                        name="photoIdentity"
                                        className={inputClass}
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                <div>
                                    <label className={labelClass} htmlFor="signature">Signature</label>
                                    <input
                                        type="file"
                                        id="signature"
                                        name="signature"
                                        className={inputClass}
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                <div>
                                    <label className={labelClass} htmlFor="talathiIncomeCertificate">Talathi Income Certificate</label>
                                    <input
                                        type="file"
                                        id="talathiIncomeCertificate"
                                        name="talathiIncomeCertificate"
                                        className={inputClass}
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        disabled={certificateData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>
                            </div>
                            {/* Application History */}
                            <div className="bg-green-50 shadow-md p-6 rounded-lg w-full lg:w-full space-y-4">
                                <h2 className="text-xl text-center font-bold mb-4 text-gray-700 border-b-2 border-gray-400 pb-2">
                                    Application History
                                </h2>

                                <div className="flex justify-between text-gray-800">
                                    <strong className="text-gray-600 flex-1">Created At (Date & Time):</strong>
                                    <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                                        {new Date(certificateData.createdAt).toLocaleString('en-IN', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: true,
                                        })}
                                    </span>
                                </div>

                                <div className="flex justify-between text-gray-800">
                                    <strong className="text-gray-600 flex-1">Updated At (Date & Time):</strong>
                                    <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                                        {certificateData.updatedAt
                                            ? new Date(certificateData.updatedAt).toLocaleString('en-IN', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                                hour12: true,
                                            })
                                            : 'N/A'}
                                    </span>
                                </div>

                                <div className="flex justify-between text-gray-800">
                                    <strong className="text-gray-600 flex-1">Current Status:</strong>
                                    <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                                        {certificateData.Status ? certificateData.Status : 'N/A'}
                                    </span>
                                </div>

                                {/* Rejected Note */}
                                {certificateData.Status === "Rejected" && (
                                    <div className="relative">
                                        <label className={labelClass} htmlFor="rejectedNote">
                                            Rejected Note
                                        </label>
                                        <textarea
                                            disabled
                                            id="rejectedNote"
                                            name="rejectedNote"
                                            className={`border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                                            value={certificateData.rejectedNote}
                                            onChange={handleInputChange}

                                        />
                                    </div>
                                )}
                            </div>
                            {/* Submit Button */}
                            <button
                                type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 transition duration-200"
                            >
                                Update License
                            </button></form>
                        <DocumentPreviewModal
                            document={showDocument}
                            onClose={() => setShowDocument(null)}
                        />
                    </div>
                </div>
            </div>
            <CustomToastContainer />
        </div>
    );
};

export default User_incomecertificate;
