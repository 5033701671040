import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import "react-toastify/dist/ReactToastify.css";

const User_companyregistration = () => {
  const { id } = useParams();
  const [licenseData, setLicenseData] = useState({
    fullName: "",
    companyName: "", // Updated to match schema
    email: "",
    address: "", // Updated to match schema
    pincode: "", // Updated to match schema
    mobileNumber: "",
    dateOfBirth: "", // Updated to match schema
    formPrice: "",
    application_type: "",
    submitNote: "",
    completedNote: "",
    Status: "In-Progress",
  });

  const [documents, setDocuments] = useState({
    aadharCard: null,
    panCard: null,
    photo: null,
    electricBill: null,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const statusEnum = ["In-Progress", "Submitted", "Rejected", "Completed"];

  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `/api/companyRegistration/getCompanyRegistration/${id}`
        );
        const data = response.data;
        setLicenseData({
          fullName: data.fullName || "",
          companyName: data.companyName || "", // Match schema
          email: data.email || "",
          address: data.address || "", // Match schema
          pincode: data.pincode || "", // Match schema
          mobileNumber: data.mobileNumber || "",
          dateOfBirth: data.dateOfBirth || "", // Match schema
          formPrice: data.formPrice || "",
          application_type: data.application_type || "",
          submitNote: data.submitNote || "",
          completedNote: data.completedNote || "",
          Status: data.Status || "In-Progress",
          rejectedNote: data.rejectedNote || "",
          createdAt: data.createdAt || "",
          updatedAt: data.updatedAt || "",
        });
      } catch (err) {
        setError("Error fetching license data",'error');
      } finally {
        setLoading(false);
      }
    };
    fetchLicenseData();
  }, [id]);

  const handleUpdate = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    for (const key in licenseData) {
      formData.append(key, licenseData[key]);
    }
    for (const key in documents) {
      if (documents[key]) {
        formData.append(`documents.${key}`, documents[key]); // Match schema structure
      }
    }
    try {
      const response = await axios.put(
        `/api/companyRegistration/updateCompanyRegistration/${id}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      showCustomToast(response.data.message);
    } catch (error) {
      showCustomToast("Failed to update license.",'error');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLicenseData({ ...licenseData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      setDocuments({ ...documents, [name]: files[0] });
    }
  };

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error)
    return <div className="text-center mt-10 text-red-500">{error}</div>;

  const inputClass =
    "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm";
  const labelClass = "block text-sm font-medium text-gray-700";

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
              Company Registration
            </h1>
            <form onSubmit={handleUpdate} className="space-y-6">
              {/* Full Name and Company Name in the first row */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className={`${labelClass} block text-gray-700 mb-1`} htmlFor="fullName">
                    Full Name
                  </label>
                  <input
                    id="fullName"
                    name="fullName"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 w-full p-2`}
                    value={licenseData.fullName}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                <div>
                  <label className={`${labelClass} block text-gray-700 mb-1`} htmlFor="companyName">
                    Company Name
                  </label>
                  <input
                    id="companyName"
                    name="companyName"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 w-full p-2`}
                    value={licenseData.companyName}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>
              </div>

              {/* Email and Business Address in the second row */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className={`${labelClass} block text-gray-700 mb-1`} htmlFor="email">
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className={`${inputClass} border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 w-full p-2`}
                    value={licenseData.email}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                <div>
                  <label className={`${labelClass} block text-gray-700 mb-1`} htmlFor="address">
                   Address
                  </label>
                  <input
                    id="address"
                    name="address"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 w-full p-2`}
                    value={licenseData.address}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>
              </div>

              {/* Pincode, Date of Birth, and Status in the third row */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className={`${labelClass} block text-gray-700 mb-1`} htmlFor="pincode">
                    Pincode
                  </label>
                  <input
                    id="pincode"
                    name="pincode"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 w-full p-2`}
                    value={licenseData.pincode}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>
                <div>
                  <label className={`${labelClass} block text-gray-700 mb-1`} htmlFor="mobileNumber">
                    Mobile Number
                  </label>
                  <input
                    id="mobileNumber"
                    name="mobileNumber"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 w-full p-2`}
                    value={licenseData.mobileNumber}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                <div>
                  <label className={`${labelClass} block text-gray-700 mb-1`} htmlFor="dateOfBirth">
                    Date of Birth
                  </label>
                  <input
                    id="dateOfBirth"
                    name="dateOfBirth"
                    type="date"
                    className={`${inputClass} border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 w-full p-2`}
                    value={licenseData.dateOfBirth}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                
              </div>

              {/* Document Uploads */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="md:col-span-3"> {/* Make this span across all columns */}
                  <h2 className="text-green-600 font-semibold text-center text-2xl">
                    - Documents Upload -
                  </h2>
                </div>
                {['aadharCard', 'panCard', 'photo', 'electricBill'].map((field) => (
                  <div key={field}>
                    <label className={`${labelClass} block text-gray-700 mb-1`} htmlFor={field}>
                      {field.replace(/([A-Z])/g, ' $1').trim()}
                    </label>
                    <input
                      id={field}
                      name={field}
                      type="file"
                      className={`${inputClass} border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 w-full p-2`}
                      accept="image/*"
                      onChange={handleFileChange}
                      disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                    />
                  </div>
                ))}
              </div>

              {/* Application History */}
              <div className="bg-green-50 shadow-md p-6 rounded-lg w-full lg:w-full space-y-4">
                <h2 className="text-xl text-center font-bold mb-4 text-gray-700 border-b-2 border-gray-400 pb-2">
                  Application History
                </h2>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">Created At (Date & Time):</strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {new Date(licenseData.createdAt).toLocaleString('en-IN', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                      hour12: true,
                    })}
                  </span>
                </div>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">Updated At (Date & Time):</strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {licenseData.updatedAt
                      ? new Date(licenseData.updatedAt).toLocaleString('en-IN', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: true,
                      })
                      : 'N/A'}
                  </span>
                </div>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">Current Status:</strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {licenseData.Status ? licenseData.Status : 'N/A'}
                  </span>
                </div>

                {/* Rejected Note */}
                {licenseData.Status === "Rejected" && (
                  <div className="relative">
                    <label className={labelClass} htmlFor="rejectedNote">
                      Rejected Note
                    </label>
                    <textarea
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                    id="rejectedNote"
                      name="rejectedNote"
                      className={`border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                      value={licenseData.rejectedNote}
                      onChange={handleInputChange}
                      
                      
                    />
                  </div>
                )}
              </div>

              {/* Submit Button */}
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 transition duration-200"
                >
                  Update License
                </button>
              </div>
            </form>

          </div>
        </div>
      </div>
      <CustomToastContainer />
    </div>
  );
};

export default User_companyregistration;
