import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const RefundPolicy = () => {
  return (
    <div>
      <Header />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 bg-white shadow-md rounded-md my-6">
        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 pt-3">Refund and Cancellation Policy</h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-lg leading-relaxed ">
        Our aim is to ensure that every customer is satisfied. In the improbable event where you are unsatisfied with the services provided, you may be entitled to a refund of your money, as long as the reasons given are genuine and proven after investigation. Make sure to understand the conditions that apply to each service before engaging in such services.
        </p>

        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 pt-3">Cancellation Policy</h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-lg leading-relaxed ">
        If a client defeats services already availed of, we shall nit be accountable for the cancelation of the service. However provision shall be made whereby in the event services already rendered are requested returned to customer, were shall hold deposits made for a period not exceeding 1 year. <br />
        We first undertake the best efforts to finish the work before considering a refund request. Refund requests received before the work is completed will carry a minimum 20% cancellation fee in addition to the fee earned up to that point and any government or third-party fees.
        </p>

        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 pt-3">Refund Policy</h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-lg leading-relaxed ">
        We will issue a refund solely if we are unable to render services to our customers from our side after an internal investigation. Instead, the Applicant has to email at info@makeeasydocuments.com in order to make such application.
        <br /> Such refund will not be made to the user for any amount after it has already been earned by the Company as such time and efforts have already been put in by the Company’s manpower for work done which is not refundable. So in other words, all processing fees, payment gateway charges, admin charges, and the cost incurred in providing the services, will be deducted. All in all, after this, we cannot reimburse any amount which has been remitted to the state designated agencies, including but not limited to filing fees or taxes, which are instrumental in processing your request, or to other associates concerning your order.
        </p>

        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 pt-3">Ungovernable Factors</h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-lg leading-relaxed ">
        The outcome of any given process/service cannot be assured as the same relies on the relevant authority associated with the process/service. This means that Make Easy Documents shall not be liable for any rejections/approvals/responses of any Govt Authorities or technical issues arising from Government websites including but not limited to Government backlogs on files lodged, etc. There may be periodic interruptions in the course of attending to this process simply because of the above, and such are implications that are beyond our means; therefore not subject for any refund.        </p>

        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 pt-3">Force Majeure</h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-lg leading-relaxed ">
        Make Easy Documents shall not be held accountable in any way for violations of the customer satisfaction guarantee or any service term. The Client shall not be owed any responsibility by the Company for any interruption, restraint, or inability to execute its duties due to the causes of earthquake, flood, fire, storm, lightning draught, landslide, hurricane, cyclone, typhoon, tornado or other acts of nature, any rebellion, sickness or other action, natural occurrence deemed to be an act of the enemy or put the country at risk, famine or sickness, any degreed regular acts from governmental institutions or agencies, alteration of the statute, internal/ external violence or conflicts, embassies or countries ridded of terrorism, pandemics, civil war, employers refusing to work, work being shelved against the warmapy manager, boycotts, and events or circumstances of a similar nature, which are outside of our Effective Control, whether they were expected or not (each a "Force Majeure").        <br />
        <br />
        This refund policy is subject to change without prior notice. Clients are encouraged to review the refund policy periodically for any updates.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
