import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import logo from '../assets/About/logo.png'
import { Link } from 'react-router-dom'

const Success = () => {
  const iconStyle = {
    animation: 'zoom-in-out 1.5s ease-in-out infinite'
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-sm w-full text-center">
        {/* Logo */}
        <div className="mb-6">
          <img
            src={logo}
            alt="Make Easy Documents"
            className="mx-auto w-44 h-auto"
          />
        </div>
        
        {/* Animated Success Icon */}
        <div className="flex justify-center items-center text-green-500 mb-4" style={iconStyle}>
          <FaCheckCircle size={60} />
        </div>
        
        {/* Success Message */}
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">
          Payment Successful..!
        </h2>
        <p className="text-gray-600 mb-6">
          Thank you for your payment. Your transaction has been completed successfully.
        </p>
        
        {/* Button */}
        <Link
          to="/dashboard/my_application"
          className="px-6 py-2 text-white bg-green-500 rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition duration-200"
        >
          Go to Dashboard
        </Link>
      </div>

      {/* Animation Keyframes */}
      <style jsx>{`
        @keyframes zoom-in-out {
          0%, 100% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.2);
          }
        }
      `}</style>
    </div>
  );
};

export default Success;
