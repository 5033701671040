import React, { useState, useEffect } from 'react';
import logo from "../assets/footer_logo.png";
import google from "../assets/why/googleplay.webp";
import { Link } from 'react-router-dom';

const Footer = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [isChatOpen, setIsChatOpen] = useState(false);

    // Show or hide the scroll-to-top button based on scroll position
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Scroll to top smoothly
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <footer className="bg-center bg-fixed bg-gradient-to-t from-blue-800 to-blue-900">
            <div className="container p-6 mx-auto">
                <div className="lg:flex">
                    <div className="w-full -mx-6 lg:w-2/5">
                        <div className="flex flex-col items-center justify-center px-6 text-center">
                            <Link to={'/'} className="flex justify-center">
                                <img src={logo} alt="Make Easy Documents" className="h-32" />
                            </Link>
                            <p className="max-w-md mx-auto mt-2 text-white text-center px-4">
                                1034, 10th Floor, Gera Imperium Rise, Wipro Circle,
                                Rajiv Gandhi InfoTech Park - Phase 2, Hinjawadi, Pune.
                            </p>
                        </div>
                    </div>

                    <div className="mt-6 lg:mt-0 lg:flex-1">
                        <div className="grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                            <div className="text-center lg:text-left">
                                <h3 className="text-white uppercase font-bold">Useful Links</h3> <br />
                                <Link to={'/about'} className="block mt-2 text-sm text-white hover:underline">About us</Link>
                                <Link to={'/all_service'} className="block mt-2 text-sm text-white hover:underline">ALL Services</Link>
                                <Link to={'/contact'} className="block mt-2 text-sm text-white hover:underline">Contact Us</Link>
                                <Link to={'/gst'} className="block mt-2 text-sm text-white hover:underline">GST Services</Link>
                                <Link to={'/refund_policy'} className="block mt-2 text-sm text-white hover:underline">( Refund Policy )</Link>
                            </div>

                            <div className="text-center lg:text-left">
                                <h3 className="text-white uppercase font-bold">Our Services</h3> <br />
                                <Link to={'/driving_Lic'} className="block mt-2 text-sm text-white hover:underline">Driving Licenses</Link>
                                <Link to={'/company_registration'} className="block mt-2 text-sm text-white hover:underline">Company Registration</Link>
                                <Link to={'/legal_consultancy'} className="block mt-2 text-sm text-white hover:underline">Legal Consultancy</Link>
                                <Link to={'/pan_service'} className="block mt-2 text-sm text-white hover:underline">PAN Services</Link>
                                <Link to={'/privacy_policy'} className="block mt-2 text-sm text-white hover:underline">( Privacy Policy )</Link>
                            </div>

                            <div className="text-center lg:text-left">
                                <h3 className="text-white uppercase font-bold">Our Services</h3> <br />
                                <Link to={'/it_services'} className="block mt-2 text-sm text-white hover:underline">Digital Marketing</Link>
                                <Link to={'/annual_compliances_other'} className="block mt-2 text-sm text-white hover:underline">Tax & Compliances</Link>
                                <Link to={'/mahsule'} className="block mt-2 text-sm text-white hover:underline">Mahsul Legal</Link>
                                <Link to={'/food_lic'} className="block mt-2 text-sm text-white hover:underline">Food License</Link>
                                <Link to={'/terms_conditions'} className="block mt-2 text-sm text-white hover:underline">( Terms and Conditions )</Link>
                            </div>

                            <div className="text-center lg:text-left">
                                <h3 className="text-white uppercase font-bold">Contact Details</h3>
                                <span className="block mt-2 text-sm text-white">Number: +91 916 874 1515</span>
                                <span className="block mt-2 text-sm text-white">info@makeeasydocuments.com</span>

                                <div className="flex justify-center mt-4 lg:justify-start">
                                    {/* Chatbot Toggle Button */}
                                    <div className="fixed bottom-36 right-3 flex items-center space-x-2">
                                        <button
                                            onClick={() => setIsChatOpen(!isChatOpen)}
                                            className="p-3 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 transform transition-transform duration-200 ease-in-out hover:scale-110"
                                            aria-label="Toggle Chatbot"
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
                                            }}
                                        >
                                            {isChatOpen ? '✕' : '💬'}
                                        </button>
                                        {isChatOpen && (
                                            <iframe
                                                allow="microphone;"
                                                width="350"
                                                height="430"
                                                src="https://console.dialogflow.com/api-client/demo/embedded/9803d8d9-705a-439d-8811-27e9e4e7b30c"
                                                title="Chatbot"
                                                className="shadow-2xl rounded-md"
                                                style={{
                                                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3)',
                                                }}
                                            ></iframe>
                                        )}
                                    </div>


                                    <a href="tel:+919168741515" target="_blank" rel="noopener noreferrer">
                                        <div className="fixed bottom-20 left-3 z-50 bg-gradient-to-r from-blue-600 to-blue-700 text-white w-12 h-12 rounded-full flex justify-center items-center shadow-[0_5px_10px_rgba(1,1,1,1)] hover:shadow-[0_10px_30px_rgba(0,0,0,3)] transition-all duration-300 ease-in-out transform hover:scale-105">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="25"
                                                height="25"
                                                fill="currentColor"
                                                className="bi bi-telephone"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                            </svg>
                                        </div>
                                    </a>

                                    <a href="https://wa.me/9168741515" target="_blank" rel="noopener noreferrer">
                                        <div className="fixed bottom-20 right-3 z-50 bg-gradient-to-r from-green-500 to-green-600 text-white w-12 h-12 rounded-full flex justify-center items-center shadow-[0_5px_10px_rgba(1,1,1,1)] hover:shadow-[0_10px_30px_rgba(0,0,0,0.8)] transition-all duration-300 ease-in-out transform hover:scale-105">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor"
                                                className="bi bi-whatsapp"
                                                viewBox="0 0 16 16"
                                                width="28"
                                                height="28"
                                            >
                                                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                                            </svg>
                                        </div>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* Back-to-Top Button */}
            {isVisible && (
                <div
                    onClick={scrollToTop}
                    className="fixed bottom-5 right-3 z-50 bg-[#FD3400] w-10 h-10 rounded-full flex justify-center items-center cursor-pointer transition-opacity duration-400 opacity-100 hover:bg-green-500">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="white"
                        className="bi bi-arrow-up-short"
                        viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 12a.5.5 0 0 1-.5-.5V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5A.5.5 0 0 1 8 12z" />
                    </svg>
                </div>
            )}
        </footer>
    );
};

export default Footer;
