import { useAuth } from "../context/auth";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Add this import

const Modal = ({ isOpen, onClose, appDetails }) => {
    const [auth] = useAuth();
    const navigate = useNavigate(); // Add navigation hook
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [applications, setApplications] = useState([]);
    const [filteredApplications, setFilteredApplications] = useState([]);
    const [receipt, setReceipt] = useState(null);
    const [showDocument, setShowDocument] = useState(null);
    const [licenseData, setLicenseData] = useState(null);
    const [statusMessage, setStatusMessage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            if (!isOpen) return;

            try {
                const response = await axios.get(
                    "/api/getAll/Form11"
                );
                console.log("Fetched Applications:", response.data);

                if (Array.isArray(response.data)) {
                    setApplications(response.data);
                    if (auth.user && auth.user._id) {
                        const filtered = response.data.filter(
                            (item) => item.user === auth.user._id
                        );
                        console.log("Filtered Applications:", filtered);
                        setFilteredApplications(filtered);
                    } else {
                        setError("User information is not available");
                    }
                } else {
                    setError("Unexpected data format");
                }
                setLoading(false);
            } catch (err) {
                console.error("API Error:", err);
                setError("Failed to fetch applications");
                setLoading(false);
            }
        };

        fetchData();
    }, [isOpen, auth]);

    useEffect(() => {
        const fetchLicenseData = async () => {
            try {
                const response = await axios.get(
                    `/api/domicileCertificate/getbyiddomicile-certificate/${appDetails.id}`
                );
                setLicenseData(response.data);
            } catch (err) {
                console.error('Error fetching license data:', err);
                setError('Error fetching license data');
            } finally {
                setLoading(false);
            }
        };

        if (appDetails?.id) {
            fetchLicenseData();
        }
    }, [appDetails]);

    useEffect(() => {
        if (appDetails?.Status) {
            switch (appDetails.Status) {
                case "Submitted":
                    setStatusMessage("Click 'Go to Application' to download the receipt.");
                    break;
                case "Completed":
                    setStatusMessage("Click 'Go to Application' to download the finished document.");
                    break;
                case "Rejected":
                    setStatusMessage("Application is rejected, please complete the requirements.");
                    break;
                default:
                    setStatusMessage("Application in progress.");
                    break;
            }
        }
    }, [appDetails]);

    const handleGoToApplication = () => {
        if (!appDetails) return;
        
        const trimmedApplicationType = (appDetails.application_type || "").trim();
        const id = appDetails._id;

        switch (trimmedApplicationType) {
            case "Voter Card":
                navigate(`/dashboard/application_votercard/${id}`);
                break;
            case "Shop Act License":
                navigate(`/dashboard/User_shopactlicense/${id}`);
                break;
            case "Company GST":
                navigate(`/dashboard/application_companygst/${id}`);
                break;
            case "Company Registration":
                navigate(`/dashboard/User_companyregistration/${id}`);
                break;
            case "Individual GST":
                navigate(`/dashboard/application_individualgst/${id}`);
                break;
            case "Local Food License":
                navigate(`/dashboard/User_localfoodlicense/${id}`);
                break;
            case "State Food License":
                navigate(`/dashboard/User_statefoodlicense/${id}`);
                break;
            case "Central Food License":
                navigate(`/dashboard/User_centralfoodlicense/${id}`);
                break;
            case "New VoterCard":
                navigate(`/dashboard/user_newvoter/${id}`);
                break;
            case "Food Manufacturing License":
                navigate(`/dashboard/User_foodmanufacturinglicense/${id}`);
                break;
            case "Domicile Certificate":
                navigate(`/dashboard/User_domicile/${id}`);
                break;
            case "GST Registration":
                navigate(`/dashboard/User_gstregistration/${id}`);
                break;
            case "Company Pancard":
                navigate(`/dashboard/user_companypancard/${id}`);
                break;
            case "Individual Pancard":
                navigate(`/dashboard/user_individualpancard/${id}`);
                break;
            case "Fresh Passport":
                navigate(`/dashboard/User_freshpassport/${id}`);
                break;
            case "Re-Issue Passport":
                navigate(`/dashboard/User_passportreissue/${id}`);
                break;
            case "Permanent License":
                navigate(`/dashboard/User_permanentlicense/${id}`);
                break;
            case "Renew License":
                navigate(`/dashboard/User_renew/${id}`);
                break;
            case "Learning License":
                navigate(`/dashboard/User_learninglicense/${id}`);
                break;
            case "Gazette Name Change":
                navigate(`/dashboard/User_gazettenamechange/${id}`);
                break;
            case "Gazette DOB Change":
                navigate(`/dashboard/User_gazettedobchange/${id}`);
                break;
            case "Gazette Religion Change":
                navigate(`/dashboard/User_gazettedreligionchange/${id}`);
                break;
            case "Shift Voter ID":
                navigate(`/dashboard/user_shiftvoter/${id}`);
                break;
            case "Udyam Aadhar":
                navigate(`/dashboard/user_udyam/${id}`);
                break;
            case "Income Certificate":
                navigate(`/dashboard/User_incomecertificate/${id}`);
                break;
            case "rationCard":
                navigate(`/dashboard/User_rationcard/${id}`);
                break;
            default:
                navigate(`/dashboard/User_common/${id}`);
        }
        onClose(); // Close the modal after navigation
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
                <h2 className="text-xl font-bold mb-4">
                    Documents for {appDetails.application_type}
                </h2>
                <div className="mb-4">
                    <p className="text-sm text-gray-700 mb-4">{statusMessage}</p>

                    {showDocument && (
                        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
                            <div className="bg-white p-6 rounded-lg shadow-lg">
                                <img
                                    src={showDocument}
                                    alt="Make Easy Documents Document"
                                    className="max-w-full max-h-screen"
                                />
                                <div className="mt-4 flex justify-between">
                                    <button
                                        className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                                        onClick={() => setShowDocument(null)}
                                    >
                                        Close
                                    </button>
                                    <a
                                        href={showDocument}
                                        download
                                        className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
                                    >
                                        Download
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="flex justify-between">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                    >
                        Close
                    </button>
                    <button
                        onClick={handleGoToApplication}
                        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                    >
                        Go to Application
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;