import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { IoArrowBackCircle } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/auth'; // Adjust the path as needed
import axios from 'axios';
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import LoginModal from '../../components/LoginModel'; // Import the modal
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom


const ShopAct_New = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [auth] = useAuth(); // Access auth state from context
  const isLoggedIn = Boolean(auth.token); // Determine if user is logged in based on token
  const [formPrices, setFormPrices] = useState({}); // State for form prices
  const [selectedPrice, setSelectedPrice] = useState(0); // State for selected price

  useEffect(() => {
    if (!isLoggedIn) {
      setIsModalOpen(true); // Show the modal if not logged in
    }
  }, [isLoggedIn]); // Run effect whenever isLoggedIn changes

  const closeModal = () => {
    setIsModalOpen(false); // Function to close modal
  };
  useEffect(() => {
    // Fetch form prices from the backend
    const fetchFormPrices = async () => {
      try {
        const response = await axios.get('/api/formprices/getallformprice');
        const prices = response.data.reduce((acc, formPrice) => {
          acc[formPrice.formType] = formPrice.price;
          return acc;
        }, {});
        setFormPrices(prices);
        setSelectedPrice(prices['shopActLicense']);  // Default price for freshPassport
      } catch (error) {
        console.error("Error fetching form prices:", error);
        showCustomToast('Error fetching form prices.', 'error');
      }
    };

    fetchFormPrices();
  }, []);

  const [isPartnership, setIsPartnership] = useState('No'); // State for partnership selection

  const [formData, setFormData] = useState({
    fullName: '',
    fullNameMarathi: '',
    mobileNumber: '',
    email: '',
    gender: '',
    dateOfBirth: '',
    addressEnglish: '',
    addressMarathi: '',
    noofYearsInResidency: '',
    shopName: '',
    shopNameMarathi: '',
    natureOfBusiness: '',
    natureOfBusinessMarathi: '',
    shopAddress: '',
    shopAddressMarathi: '',
    shopPincode: '',
    isShopRented: '',
    businessStartDate: '',
    noOfMaleEmployess: '',
    noOfFemaleEmployess: '',
    partnerFullName: '',
    partnerNumber: '',
    partnerFullEmail: '',
    partnerFullAddress: '',
    aadharNumber: '',
    passportPhoto: null,
    signature: null,
    aadharProof: null,
    pancard: null,
    shopPhoto: null,
    selfDeclaration: null,
  });


  const [transactionID, setTransactionID] = useState(null);

  const generateTransactionID = () => {
    const timeStamp = Date.now();
    const randomNum = Math.floor(Math.random() * 1000000);
    const merchantPrefix = "T";
    const transactionID = `${merchantPrefix}${timeStamp}${randomNum} `;
    return setTransactionID(transactionID);
  };

  useEffect(()=> {
    generateTransactionID()
  })



  // Handle change for partnership dropdown
  const handlePartnershipChange = (e) => {
    setIsPartnership(e.target.value);
  };

  // Handle input change for partner fields
  const handleInputChangee = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  // Handle file changes
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    // Define validation criteria based on file name
    const isPhotoOrSignature = name === 'passportPhoto' || name === 'signature' || name === 'photo';
    const minSize = isPhotoOrSignature ? 5 * 1024 : 75 * 1024; // 5 KB or 75 KB
    const maxSize = isPhotoOrSignature ? 50 * 1024 : 1 * 1024 * 1024; // 50 KB or 1 MB
    const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const validDocumentTypes = ['application/pdf', 'image/jpeg', 'image/pdf'];

    // Check file validity
    if (file) {
      const { size, type } = file;

      // Check size and type based on the input name
      if (isPhotoOrSignature) {
        // Validate photo and signature files
        if (size < minSize || size > maxSize) {
          showCustomToast('File size for Passport Photo/Signature must be between 5 KB and 50 KB.', 'error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file size is invalid
        }
        if (!validImageTypes.includes(type)) {
          showCustomToast('Passport Photo/Signature must be a JPG, JPEG, or PNG file.', 'error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file type is invalid
        }
      } else {
        // Validate other documents
        if (size < minSize || size > maxSize) {
          showCustomToast('File size for Address Proof/Medical Certificate must be between 75 KB and 1 MB.', 'error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file size is invalid
        }
        if (!validDocumentTypes.includes(type)) {
          showCustomToast('Address Proof/Medical Certificate must be a PDF, JPG, or JPEG file.', 'error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file type is invalid
        }
      }

      // If validation passes, update the formData
      setFormData({ ...formData, [name]: file });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if user is authenticated
    if (!auth.token) {
      showCustomToast('Please login to fill out the form.');
      return;
    }
    // Create FormData object
    const form = new FormData();
    for (const key in formData) {
      if (formData[key] !== null) {
        // Use 'documents.' prefix for file inputs to match backend
        form.append(key.startsWith('aadharProof') ? 'documents.aadharProof' :
          key.startsWith('passportPhoto') ? 'documents.passportPhoto' :
            key.startsWith('signature') ? 'documents.signature' :
              key.startsWith('shopPhoto') ? 'documents.shopPhoto' :
                key.startsWith('selfDeclaration') ? 'documents.selfDeclaration' :
                  key, formData[key]);
      }
    }

    try {
      // Post form data to API
      const response = await axios.post('/api/shopActLicense/createShopActLicense', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${auth.token}` // Use backticks for string interpolation
        }
      });

         // Proceed with the PayU payment logic after form submission
    const payuResponse = await axios.post("/api/payu/hash", {
      fullName: formData.fullName,
      email: formData.email,
      amount: selectedPrice,
      transactionID: transactionID,
      udf1: "detail 1",
      udf2: "detail 2",
      udf3: "",
      udf4: "",
      udf5: ""
    });

    const { hash } = payuResponse.data;

       // Redirect to PayU
       const payuForm = document.createElement("form");
       payuForm.action = "https://secure.payu.in/_payment";
       payuForm.method = "POST";

       const fields = {
        key: 'nC1EmY',
        txnid: transactionID,
        amount: selectedPrice,
        productinfo: "central food license",
        firstname: formData.fullName,
        email: formData.email,
        phone: formData.mobileNumber,
        surl: '/api/payu/success', // Success URL after payment
        furl: "/api/payu/failure",
        hash: hash,
        udf1: "detail 1",
        udf2: "detail 2",
        udf3: "",
        udf4: "",
        udf5: ""
      };


      for (const key in fields) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = fields[key];
        payuForm.appendChild(input);
      }
  
      document.body.appendChild(payuForm);
      payuForm.submit();

    } catch (error) {
      // Notify user of error
      showCustomToast('Error submitting application.', 'error');
    }
  };

  return (
    <div className='bg-slate-100'>
      <Header />
      <div className="page-title py-6 bg-slate-300" data-aos="fade">
        <div className="container mx-auto px-4 lg:px-20 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          <h1 className="text-black text-xl md:text-2xl font-semibold">Shop Act Registration</h1>
          <h1 className="text-lg md:text-xl font-bold underline underline-offset-8 mb-2 lg:mb-0 lg:ml-4 animate-[intenseBlink_1s_ease-in-out_infinite]">
            100% Fees Refundable, if Service is not Completed!
          </h1>
          <nav className="breadcrumbs">
            <ol className="flex space-x-4 text-sm">
              <li><Link to="/" className="text-black hover:underline text-base">Home</Link></li>
              <li className="text-black">/</li>
              <li>
                <Link to="/shop_act" className="flex items-center text-black hover:underline text-base">
                  Go Back
                  <IoArrowBackCircle className="h-5 w-5 mr-2 ml-2" />
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="text-center mt-8">
        <h2 className="text-green-600 font-semibold text-2xl">- Shop Act Registration -</h2>
      </div>

      <form className="max-w-6xl mx-auto bg-white shadow-2xl rounded-md p-6 mt-10 mb-10" onSubmit={handleSubmit}>

        <div className="flex flex-col sm:flex-row sm:justify-between mt-10 pb-6">
          <h2 className="text-green-600 font-semibold text-xl text-center sm:text-left">
            Service Charge : <span className='text-red-600' id='formprice'> ₹ {selectedPrice}</span>
          </h2>
        </div>
        <br />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Personal Info */}
          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Full Name</label>
            <input type="text" name="fullName" className="w-full px-3 py-2 border border-gray-300 rounded" value={formData.fullName} onChange={handleInputChange} required />
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Full Name (Marathi)</label>
            <input type="text" name="fullNameMarathi" className="w-full px-3 py-2 border border-gray-300 rounded" value={formData.fullNameMarathi} onChange={handleInputChange} required />
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Mobile Number</label>
            <input type="tel" name="mobileNumber" className="w-full px-3 py-2 border border-gray-300 rounded"
              value={formData.mobileNumber} onChange={handleInputChange}
              onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} // Only allows digits
              pattern="[0-9]{10}"
              maxLength="10"
              required />
          </div>

          {/* Additional Personal Info */}
          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Email</label>
            <input type="email" name="email" className="w-full px-3 py-2 border border-gray-300 rounded" value={formData.email} onChange={handleInputChange}
              required
              pattern="[a-zA-Z0-9._%+-]+@gmail\.com" // Regex pattern for Gmail addresses
              title="Please enter a valid Gmail address." // Error message for invalid input
              onBlur={(e) => {
                // Validate on input blur
                if (!e.target.value.includes('@')) {
                  e.target.setCustomValidity('You must include "@" in your email.');
                } else {
                  e.target.setCustomValidity(''); // Clear the error
                }
              }}
            />
            {formData.email && !formData.email.includes('@') && (
              <span className="text-red-500 text-xs mt-1">Email must contain "@"</span>
            )}
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Gender</label>
            <select name="gender" className="w-full px-3 py-2 border border-gray-300 rounded" value={formData.gender} onChange={handleInputChange} required>
              <option value="">Select</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Date of Birth</label>
            <input type="date" name="dateOfBirth" className="w-full px-3 py-2 border border-gray-300 rounded" value={formData.dateOfBirth} onChange={handleInputChange} required />
          </div>

          {/* Residency Info */}
          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Address (English)</label>
            <input type="text" name="addressEnglish" className="w-full px-3 py-2 border border-gray-300 rounded" value={formData.addressEnglish} onChange={handleInputChange} required />
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Address (Marathi)</label>
            <input type="text" name="addressMarathi" className="w-full px-3 py-2 border border-gray-300 rounded" value={formData.addressMarathi} onChange={handleInputChange} required />
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">No. of Years in Residency</label>
            <input type="number" name="noofYearsInResidency" className="w-full px-3 py-2 border border-gray-300 rounded" value={formData.noofYearsInResidency} onChange={handleInputChange} required />
          </div>

          {/* Business Info */}
          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Shop Name</label>
            <input type="text" name="shopName" className="w-full px-3 py-2 border border-gray-300 rounded" value={formData.shopName} onChange={handleInputChange} required />
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Shop Name (Marathi)</label>
            <input type="text" name="shopNameMarathi" className="w-full px-3 py-2 border border-gray-300 rounded" value={formData.shopNameMarathi} onChange={handleInputChange} required />
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Nature of Business</label>
            <input type="text" name="natureOfBusiness" className="w-full px-3 py-2 border border-gray-300 rounded" value={formData.natureOfBusiness} onChange={handleInputChange} required />
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Nature of Business (Marathi)</label>
            <input type="text" name="natureOfBusinessMarathi" className="w-full px-3 py-2 border border-gray-300 rounded" value={formData.natureOfBusinessMarathi} onChange={handleInputChange} required />
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Shop Address</label>
            <input type="text" name="shopAddress" className="w-full px-3 py-2 border border-gray-300 rounded" value={formData.shopAddress} onChange={handleInputChange} required />
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Shop Address (Marathi)</label>
            <input type="text" name="shopAddressMarathi" className="w-full px-3 py-2 border border-gray-300 rounded" value={formData.shopAddressMarathi} onChange={handleInputChange} required />
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Shop Pincode</label>
            <input type="number" name="shopPincode" className="w-full px-3 py-2 border border-gray-300 rounded" value={formData.shopPincode} onChange={handleInputChange} required
              pattern="\d{6}" // Require exactly 6 digits
              maxLength="6"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
              }}
            />
          </div>

          {/* Additional Business Info */}
          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Is Shop Rented?</label>
            <select name="isShopRented" className="w-full px-3 py-2 border border-gray-300 rounded" value={formData.isShopRented} onChange={handleInputChange} required>
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Business Start Date</label>
            <input type="date" name="businessStartDate" className="w-full px-3 py-2 border border-gray-300 rounded" value={formData.businessStartDate} onChange={handleInputChange} required />
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Number of Male Employees</label>
            <input type="number" name="noOfMaleEmployess" className="w-full px-3 py-2 border border-gray-300 rounded" value={formData.noOfMaleEmployees} onChange={handleInputChange} required />
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Number of Female Employees</label>
            <input type="number" name="noOfFemaleEmployess" className="w-full px-3 py-2 border border-gray-300 rounded" value={formData.noOfFemaleEmployees} onChange={handleInputChange} required />
          </div>

          {/* Aadhar Info */}
          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Aadhar Number</label>
            <input
              type="text"
              name="aadharNumber"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              value={formData.aadharNumber}
              onChange={(e) => {
                const value = e.target.value;
                // Remove any non-digit characters
                const numericValue = value.replace(/\D/g, '');
                // Update the state if the length is 12 or less
                if (numericValue.length <= 12) {
                  handleInputChange({ target: { name: 'aadharNumber', value: numericValue } });
                }
              }}
              maxLength={12} // Limit input to 12 characters
              required
            />
          </div>
          {/* Partner Info */}
          <div className="form-container mb-4">
            {/* Partnership Dropdown */}
            <div className="form-group">
              <label className="block text-gray-600 font-semibold mb-2">Shop is in Partnership</label>
              <select
                value={isPartnership}
                onChange={handlePartnershipChange}
                className="w-full px-3 py-2 border border-gray-300 rounded"
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>
          </div>
        </div>

        {/* Partner Info - only show if isPartnership is "Yes" */}
        {isPartnership === 'Yes' && (
          <div className="flex flex-wrap -mx-2">
            {/* Partner Full Name */}
            <div className="form-container w-full md:w-1/3 px-2 mb-4">
              <div className="form-group">
                <label className="block text-gray-600 font-semibold mb-2">Partner Full Name</label>
                <input
                  type="text"
                  name="partnerFullName"
                  className="w-full px-3 py-2 border border-gray-300 rounded"
                  value={formData.partnerFullName}
                  onChange={handleInputChangee}
                  required
                />
              </div>
            </div>

            {/* Partner Mobile Number */}
            <div className="form-container w-full md:w-1/3 px-2 mb-4">
              <div className="form-group">
                <label className="block text-gray-600 font-semibold mb-2">Partner Mobile Number</label>
                <input
                  type="tel"
                  name="partnerNumber"
                  className="w-full px-3 py-2 border border-gray-300 rounded"
                  value={formData.partnerNumber}
                  onChange={handleInputChangee}
                  onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ''))} // Only allows digits
                  pattern="[0-9]{10}"
                  maxLength="10"
                  required
                />
              </div>
            </div>

            {/* Partner Email */}
            <div className="form-container w-full md:w-1/3 px-2 mb-4">
              <div className="form-group">
                <label className="block text-gray-600 font-semibold mb-2">Partner Email</label>
                <input
                  type="email"
                  name="partnerFullEmail"
                  className="w-full px-3 py-2 border border-gray-300 rounded"
                  value={formData.partnerFullEmail}
                  onChange={handleInputChangee}
                  required
                  onBlur={(e) => {
                    // Validate on input blur
                    if (!e.target.value.includes('@')) {
                      e.target.setCustomValidity('You must include "@" in your email.');
                    } else {
                      e.target.setCustomValidity(''); // Clear the error
                    }
                  }}
                />
                {formData.email && !formData.email.includes('@') && (
                  <span className="text-red-500 text-xs mt-1">Email must contain "@"</span>
                )}
              </div>
            </div>

            {/* Partner Address */}
            <div className="form-container w-full md:w-1/3 px-2 mb-4">
              <div className="form-group">
                <label className="block text-gray-600 font-semibold mb-2">Partner Address</label>
                <input
                  type="text"
                  name="partnerFullAddress"
                  className="w-full px-3 py-2 border border-gray-300 rounded"
                  value={formData.partnerFullAddress}
                  onChange={handleInputChangee}
                  required
                />
              </div>
            </div>
          </div>
        )}




        <div className="mt-10 text-center">
          <h2 className="text-green-600 font-semibold text-2xl">- Upload Required Documents -</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
          {/* Document Upload Fields */}
          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Passport Photo</label>
            <span className='text-red-600 text-xs'>(Max Size: PNG,JPG,JPEG / 5kb to 50kb)</span>
            <input type="file" name="passportPhoto" className="w-full px-3 py-2 border border-gray-300 rounded" onChange={handleFileChange} required />
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Signature</label>
            <span className='text-red-600 text-xs'>(Max Size: PNG,JPG,JPEG / 5kb to 50kb)</span>
            <input type="file" name="signature" className="w-full px-3 py-2 border border-gray-300 rounded" onChange={handleFileChange} required />
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Aadhar Proof</label>
            <span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
            <input type="file" name="aadharProof" className="w-full px-3 py-2 border border-gray-300 rounded" onChange={handleFileChange} required />
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Pancard</label>
            <span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
            <input type="file" name="pancard" className="w-full px-3 py-2 border border-gray-300 rounded" onChange={handleFileChange} required />
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Shop Photo</label>
            <span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
            <input type="file" name="shopPhoto" className="w-full px-3 py-2 border border-gray-300 rounded" onChange={handleFileChange} required />
          </div>

          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">Self Declaration</label>
            <span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
            <input type="file" name="selfDeclaration" className="w-full px-3 py-2 border border-gray-300 rounded" onChange={handleFileChange} required />
          </div>
        </div>

        <div className="text-center mt-8">
          <button type="submit" className="px-6 py-3 bg-green-600 text-white font-semibold rounded-md shadow hover:bg-green-700 transition duration-200">
            Submit Shop Act Registration
          </button>
        </div>
      </form>

      <Footer />
      {/* Render Login Modal only if not logged in */}
      {!isLoggedIn && isModalOpen && <LoginModal closeModal={closeModal} />}
      <CustomToastContainer />
    </div>
  );
};

export default ShopAct_New;
