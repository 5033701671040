import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { showCustomToast, CustomToastContainer } from "../../components/CustomToast";
import "react-toastify/dist/ReactToastify.css";

const User_common = () => {
  const { id } = useParams();
  const [licenseData, setLicenseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState({});
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        const response = await axios.get(
          `/api/commonForm/getbyidcommon-form/${id}`
        );
        const data = response.data;
        setLicenseData(data);
        setIsEditable(data.Status === "Rejected");
      } catch (err) {
        setError("Error fetching license data",'error');
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLicenseData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFiles((prevFiles) => ({
      ...prevFiles,
      [name]: files[0],
    }));
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    for (const [key, value] of Object.entries(licenseData)) {
      if (value) formData.append(key, value);
    }

    for (const [key, value] of Object.entries(files)) {
      formData.append(`documents.${key}`, value);
    }

    try {
      const response = await axios.put(
        `/api/commonForm/updatecommon-form/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      showCustomToast(response.data.message);
    } catch (error) {
      showCustomToast("Failed to update license.", 'error');
    }
  };

  if (loading) return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
    </div>
  );

  if (error) return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="bg-red-50 text-red-600 px-4 py-3 rounded-lg shadow">
        {error}
      </div>
    </div>
  );

  const InputField = ({ label, name, value, type = "text", disabled = !isEditable }) => {
    if (value === undefined || value === null || value === '') return null;

    return (
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">{label}:</label>
        <input
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          className={`w-full px-4 py-2 rounded-lg border focus:ring-2 focus:ring-blue-500 focus:border-transparent
            ${disabled ? 'bg-gray-50 text-gray-500' : 'bg-white'}
            ${isEditable ? 'hover:border-blue-500 transition-colors' : ''}
          `}
        />
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
          <div className="bg-gradient-to-r from-blue-600 to-blue-700 px-6 py-4">
            <h2 className="text-2xl font-bold text-white">Common Form</h2>
          </div>

          <form onSubmit={handleUpdate} className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <InputField label="Full Name" name="fullName" value={licenseData.fullName} />
              <InputField label="Application Name" name="applicationName" value={licenseData.applicationName} />
              <InputField label="kissan point type" name="kisanpointtype" value={licenseData.kisanpointtype} />
              <InputField label="Email" name="email" value={licenseData.email} type="email" />
              <InputField label="Mobile Number" name="mobileNumber" value={licenseData.mobileNumber} />
              <InputField label="Date of Birth" name="dateOfBirth" value={licenseData.dateOfBirth} />
              <InputField label="Address" name="address" value={licenseData.address} />
              <InputField label="Village" name="village" value={licenseData.village} />
              <InputField label="Tehsil" name="tehsil" value={licenseData.tehsil} />
              <InputField label="District" name="district" value={licenseData.district} />
              <InputField label="State" name="state" value={licenseData.state} />
              <InputField label="Pincode" name="pincode" value={licenseData.pincode} />
              <InputField label="Gat Number" name="gatNumber" value={licenseData.gatNumber} />
              <InputField label="Khata Number" name="khatanumber" value={licenseData.khatanumber} />
              <InputField label="GST Number" name="gstNumber" value={licenseData.gstNumber} />
            </div>

            {isEditable && (
              <div className="mt-8 space-y-4">
                <div className="p-4 border-2 border-dashed border-gray-300 rounded-lg">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Upload Documents</label>
                  <input
                    type="file"
                    name="documentFile"
                    onChange={handleFileChange}
                    className="w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4
                      file:rounded-full file:border-0 file:text-sm file:font-semibold
                      file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                  />
                </div>

                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white px-6 py-3 rounded-lg font-medium
                    shadow-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50
                    transform transition-all duration-150 hover:scale-[1.02]"
                >
                  Update Form
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
      <CustomToastContainer />
    </div>
  );
};

export default User_common;
