import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import "react-toastify/dist/ReactToastify.css";

const User_gazette_name = () => {

    const { id } = useParams();
    const [licenseData, setLicenseData] = useState({
        fullName: "",
        oldName: "",
        newName: "",
        mobileNumber: "",
        email: "",
        address: "",
        gender: "",
        dateOfBirth: "",
        casteType: "",
        reasonForChange: "",
        application_type: "",
        formPrice: "",
        submitNote: "",
        completedNote: "",
        Status: "In-Progress",
        rejectedNote: "",
    });

    const [documents, setDocuments] = useState({
        aadharCard: null,
        photo: null,
        signature: null,
        schoolLC: null,
        nameChangeAffidavit: null,
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const statusEnum = ["In-Progress", "Submitted", "Rejected", "Completed"];
    const [showDocument, setShowDocument] = useState(null);
    
    useEffect(() => {
        const fetchLicenseData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(
                    `/api/nameChangeGazette/getGazetteNameChange/${id}`
                );
                const data = response.data;

                setLicenseData({
                    fullName: data.fullName || "",
                    oldName: data.oldName || "",
                    newName: data.newName || "",
                    mobileNumber: data.mobileNumber || "",
                    email: data.email || "",
                    address: data.address || "",
                    gender: data.gender || "",
                    dateOfBirth: data.dateOfBirth ? new Date(data.dateOfBirth).toISOString().split("T")[0] : "",
                    casteType: data.casteType || "",
                    reasonForChange: data.reasonForChange || "",
                    application_type: data.application_type || "",
                    formPrice: data.formPrice || "",
                    submitNote: data.submitNote || "",
                    completedNote: data.completedNote || "",
                    Status: data.Status || "In-Progress",
                    rejectedNote: data.rejectedNote || "",
                    createdAt: data.createdAt || "",
                    updatedAt: data.updatedAt || "",
                    documents: data.documents || {}, // Assign documents properly here

                });
            } catch (err) {
                setError("Error fetching license data",'error');
            } finally {
                setLoading(false);
            }
        };
        fetchLicenseData();
    }, [id]);

    const handleUpdate = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        for (const key in licenseData) {
            formData.append(key, licenseData[key]);
        }
        // Append document data
        for (const key in documents) {
            if (documents[key]) {
                formData.append(`documents.${key}`, documents[key]); // Match the structure expected by the backend
            }
        }
        try {
            const response = await axios.put(
                `/api/nameChangeGazette/updateGazetteNameChange/${id}`,
                formData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );
            showCustomToast(response.data.message);
        } catch (error) {
            showCustomToast("Failed to update license.",'error');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLicenseData({ ...licenseData, [name]: value });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (files && files[0]) {
            setDocuments({ ...documents, [name]: files[0] });
        }
    };
    const renderImage = (imageData) => {
        if (imageData && imageData.contentType && imageData.data && imageData.data.data) {
            const byteArray = new Uint8Array(imageData.data.data);
            const base64String = btoa(String.fromCharCode(...byteArray));
            return `data:${imageData.contentType};base64,${base64String}`;
        }
        return null;
    };

    const DocumentPreviewModal = ({ document, onClose }) => {
        if (!document) return null;

        return (
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full">
                    <img
                        src={document}
                        alt=" Make Easy Documents Document"
                        className="max-w-full max-h-[80vh] mx-auto"
                    />
                    <div className="mt-4 flex justify-between">
                        <button
                            className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <a
                            href={document}
                            download
                            className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
                        >
                            Download
                        </a>
                    </div>
                </div>
            </div>
        );
    };

    const DownloadableDocuments = () => {
        console.log("License Data Documents:", licenseData.documents); // Debug log
        if (!licenseData.documents) return null; // Ensure documents is defined

        const { receipt, finisheddoc } = licenseData.documents;

        const status = licenseData.Status;

        return (
            <div className="col-span-3 bg-white shadow-md p-6 rounded-lg mb-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">
                    Downloadable Documents
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {status === "Submitted" && receipt && (
                        <div className="border p-4 rounded-lg text-center bg-gray-50">
                            <h3 className="font-semibold mb-2">Receipt</h3>
                            <button
                                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                                onClick={() => setShowDocument(renderImage(receipt))}
                            >
                                View Receipt
                            </button>
                        </div>
                    )}
                    {status === "Completed" && finisheddoc && (
                        <div className="border p-4 rounded-lg text-center bg-gray-50">
                            <h3 className="font-semibold mb-2">Finished Document</h3>
                            <button
                                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                                onClick={() => setShowDocument(renderImage(finisheddoc))}
                            >
                                View Document
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    
    if (loading) return <div className="text-center mt-10">Loading...</div>;
    if (error) return <div className="text-center mt-10 text-red-500">{error}</div>;

    const inputClass = "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm";
    const labelClass = "block text-sm font-medium text-gray-700";

    return (
        <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
            <div className="max-w-5xl mx-auto">
                <div className="bg-white shadow-xl rounded-lg overflow-hidden">
                    <div className="px-4 py-5 sm:p-6">
                        <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">Name Change Gazette</h1>
                        <DownloadableDocuments />
                        <form onSubmit={handleUpdate} className="space-y-6">
                            {/* Row for Full Name, Old Name, New Name */}
                            <div className="flex space-x-4">
                                <div className="flex-1">
                                    <label className={labelClass} htmlFor="fullName">Full Name</label>
                                    <input
                                        id="fullName"
                                        name="fullName"
                                        type="text"
                                        className={`border border-gray-300 rounded-md p-2 ${inputClass}`}
                                        value={licenseData.fullName}
                                        onChange={handleInputChange}
                                        required
                                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                <div className="flex-1">
                                    <label className={labelClass} htmlFor="oldName">Old Name</label>
                                    <input
                                        id="oldName"
                                        name="oldName"
                                        type="text"
                                        className={`border border-gray-300 rounded-md p-2 ${inputClass}`}
                                        value={licenseData.oldName}
                                        onChange={handleInputChange}
                                        required
                                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"


                                    />
                                </div>

                                <div className="flex-1">
                                    <label className={labelClass} htmlFor="newName">New Name</label>
                                    <input
                                        id="newName"
                                        name="newName"
                                        type="text"
                                        className={`border border-gray-300 rounded-md p-2 ${inputClass}`}
                                        value={licenseData.newName}
                                        onChange={handleInputChange}
                                        required
                                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>
                            </div>

                            {/* Row for Mobile Number, Email, Address */}
                            <div className="flex space-x-4">
                                <div className="flex-1">
                                    <label className={labelClass} htmlFor="mobileNumber">Mobile Number</label>
                                    <input
                                        id="mobileNumber"
                                        name="mobileNumber"
                                        type="text"
                                        className={`border border-gray-300 rounded-md p-2 ${inputClass}`}
                                        value={licenseData.mobileNumber}
                                        onChange={handleInputChange}
                                        required
                                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                <div className="flex-1">
                                    <label className={labelClass} htmlFor="email">Email</label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        className={`border border-gray-300 rounded-md p-2 ${inputClass}`}
                                        value={licenseData.email}
                                        onChange={handleInputChange}
                                        required
                                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                <div className="flex-1">
                                    <label className={labelClass} htmlFor="address">Address</label>
                                    <input
                                        id="address"
                                        name="address"
                                        type="text"
                                        className={`border border-gray-300 rounded-md p-2 ${inputClass}`}
                                        value={licenseData.address}
                                        onChange={handleInputChange}
                                        required
                                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>
                            </div>

                            {/* Row for Gender, Date of Birth, Caste Type */}
                            <div className="flex space-x-4">
                                <div className="flex-1">
                                    <label className={labelClass} htmlFor="gender">Gender</label>
                                    <select
                                        id="gender"
                                        name="gender"
                                        className={`border border-gray-300 rounded-md p-2 ${inputClass}`}
                                        value={licenseData.gender}
                                        onChange={handleInputChange}
                                        required
                                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    >
                                        <option value="" disabled>Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>

                                <div className="flex-1">
                                    <label className={labelClass} htmlFor="dateOfBirth">Date of Birth</label>
                                    <input
                                        id="dateOfBirth"
                                        name="dateOfBirth"
                                        type="date"
                                        className={`border border-gray-300 rounded-md p-2 ${inputClass}`}
                                        value={licenseData.dateOfBirth}
                                        onChange={handleInputChange}
                                        required
                                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                <div className="flex-1">
                                    <label className={labelClass} htmlFor="casteType">Caste Type</label>
                                    <input
                                        id="casteType"
                                        name="casteType"
                                        type="text"
                                        className={`border border-gray-300 rounded-md p-2 ${inputClass}`}
                                        value={licenseData.casteType}
                                        onChange={handleInputChange}
                                        required
                                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>
                            </div>

                            {/* Row for Reason for Change, Application Type, Status */}
                            <div className="flex space-x-4">
                                <div className="flex-1">
                                    <label className={labelClass} htmlFor="reasonForChange">Reason for Change</label>
                                    <input
                                        id="reasonForChange"
                                        name="reasonForChange"
                                        type="text"
                                        className={`border border-gray-300 rounded-md p-2 ${inputClass}`}
                                        value={licenseData.reasonForChange}
                                        onChange={handleInputChange}
                                        required
                                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>

                                <div className="flex-1">
                                    <label className={labelClass} htmlFor="application_type">Application Type</label>
                                    <input
                                        id="application_type"
                                        name="application_type"
                                        type="text"
                                        className={`border border-gray-300 rounded-md p-2 ${inputClass}`}
                                        value={licenseData.application_type}
                                        onChange={handleInputChange}
                                        required
                                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                    />
                                </div>


                            </div>




                            {/* Document Uploads */}
                            <div className="space-y-4">
                                <h2 className="text-green-600 font-semibold text-center text-2xl">
                                    - Documents Upload -
                                </h2>
                                <div className="flex space-x-4">
                                    <div className="flex-1 border border-gray-300 rounded-md p-4">
                                        <label className={labelClass} htmlFor="aadharCard">Aadhar Card</label>
                                        <input
                                            id="aadharCard"
                                            name="aadharCard"
                                            type="file"
                                            className={inputClass}
                                            accept="image/*"
                                            onChange={handleFileChange}
                                            disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                        />
                                    </div>

                                    <div className="flex-1 border border-gray-300 rounded-md p-4">
                                        <label className={labelClass} htmlFor="photo">Photo</label>
                                        <input
                                            id="photo"
                                            name="photo"
                                            type="file"
                                            className={inputClass}
                                            accept="image/*"
                                            onChange={handleFileChange}
                                            disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                        />
                                    </div>

                                    <div className="flex-1 border border-gray-300 rounded-md p-4">
                                        <label className={labelClass} htmlFor="signature">Signature</label>
                                        <input
                                            id="signature"
                                            name="signature"
                                            type="file"
                                            className={inputClass}
                                            accept="image/*"
                                            onChange={handleFileChange}
                                            disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                        />
                                    </div>
                                </div>

                                <div className="flex space-x-4">
                                    <div className="flex-1 border border-gray-300 rounded-md p-4">
                                        <label className={labelClass} htmlFor="schoolLC">School Leaving Certificate</label>
                                        <input
                                            id="schoolLC"
                                            name="schoolLC"
                                            type="file"
                                            className={inputClass}
                                            accept="image/*"
                                            onChange={handleFileChange}
                                            disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                        />
                                    </div>

                                    <div className="flex-1 border border-gray-300 rounded-md p-4">
                                        <label className={labelClass} htmlFor="nameChangeAffidavit">Name Change Affidavit</label>
                                        <input
                                            id="nameChangeAffidavit"
                                            name="nameChangeAffidavit"
                                            type="file"
                                            className={inputClass}
                                            accept="image/*"
                                            onChange={handleFileChange}
                                            disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Application History */}
                            <div className="bg-green-50 shadow-md p-6 rounded-lg w-full lg:w-full space-y-4">
                                <h2 className="text-xl text-center font-bold mb-4 text-gray-700 border-b-2 border-gray-400 pb-2">
                                    Application History
                                </h2>

                                <div className="flex justify-between text-gray-800">
                                    <strong className="text-gray-600 flex-1">Created At (Date & Time):</strong>
                                    <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                                        {new Date(licenseData.createdAt).toLocaleString('en-IN', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: true,
                                        })}
                                    </span>
                                </div>

                                <div className="flex justify-between text-gray-800">
                                    <strong className="text-gray-600 flex-1">Updated At (Date & Time):</strong>
                                    <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                                        {licenseData.updatedAt
                                            ? new Date(licenseData.updatedAt).toLocaleString('en-IN', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                                hour12: true,
                                            })
                                            : 'N/A'}
                                    </span>
                                </div>

                                <div className="flex justify-between text-gray-800">
                                    <strong className="text-gray-600 flex-1">Current Status:</strong>
                                    <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                                        {licenseData.Status ? licenseData.Status : 'N/A'}
                                    </span>
                                </div>

                                {/* Rejected Note */}
                                {licenseData.Status === "Rejected" && (
                                    <div className="relative">
                                        <label className={labelClass} htmlFor="rejectedNote">
                                            Rejected Note
                                        </label>
                                        <textarea
                                            disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                                            id="rejectedNote"
                                            name="rejectedNote"
                                            className={`border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                                            value={licenseData.rejectedNote}
                                            onChange={handleInputChange}

                                        />
                                    </div>
                                )}
                            </div>

                            {/* Submit Button */}
                            <div>
                                <button
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700"
                                >
                                    Update License
                                </button>
                            </div>
                        </form>
                        <DocumentPreviewModal
                            document={showDocument}
                            onClose={() => setShowDocument(null)}
                        />

                    </div>
                </div>
            </div>
            <CustomToastContainer />
        </div>
    );
};

export default User_gazette_name;
