import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import "react-toastify/dist/ReactToastify.css";

const User_Udyam = () => {
  const { id } = useParams();
  const [licenseData, setLicenseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState({});
  const [showDocument, setShowDocument] = useState(null);
  const statusEnum = ["In-Progress", "Submitted", "Rejected", "Completed"];

  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        const response = await axios.get(
          `/api/udyamAadhar/getbyidudyam-aadhar/${id}`
        );
        console.log(response);
        setLicenseData(response.data);
      } catch (err) {
        setError("Error fetching license data", 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseData();
  }, [id]);

  const handleUpdate = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    // Append all fields from licenseData to formData
    formData.append("ownerFullName", licenseData.ownerFullName);
    formData.append("PanCardNumber", licenseData.PanCardNumber);
    formData.append("AadharCardNumber", licenseData.AadharCardNumber);
    formData.append("casteCategory", licenseData.casteCategory);
    formData.append("gender", licenseData.gender);
    formData.append("businessName", licenseData.businessName);
    formData.append("mobileNumber", licenseData.mobileNumber);
    formData.append("email", licenseData.email);
    formData.append("businessStartDate", licenseData.businessStartDate);
    formData.append("numberOfEmployees", licenseData.numberOfEmployees);
    formData.append("natureOfBusiness", licenseData.natureOfBusiness);
    formData.append("bussinessInvestment", licenseData.bussinessInvestment);
    formData.append("bankName", licenseData.bankName);
    formData.append("accountNumber", licenseData.accountNumber);
    formData.append("IFSC", licenseData.IFSC);
    formData.append("rejectedNote", licenseData.rejectedNote);
    formData.append("submitNote", licenseData.submitNote);
    formData.append("completedNote", licenseData.completedNote);
    formData.append("rejectedAt", licenseData.rejectedAt);
    formData.append("submitedAt", licenseData.submitedAt);
    formData.append("CompletedAt", licenseData.CompletedAt);
    formData.append("Status", licenseData.Status);

    // Append files to formData
    for (const [key, value] of Object.entries(files)) {
      formData.append(`documents.${key}`, value);
    }

    try {
      const response = await axios.put(
        `/api/udyamAadhar/updateudyam-aadhar/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      showCustomToast(response.data.message);
    } catch (error) {
      showCustomToast("Failed to update license.", 'error');
    }
  };

  const renderImage = (imageData) => {
    if (
      imageData &&
      imageData.contentType &&
      imageData.data &&
      imageData.data.data
    ) {
      const byteArray = new Uint8Array(imageData.data.data);
      const base64String = btoa(String.fromCharCode(...byteArray));
      return `data:${imageData.contentType};base64,${base64String}`;
    }
    return null;
  };
  const DocumentPreviewModal = ({ document, onClose }) => {
    if (!document) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full">
          <img
            src={document}
            alt=" Make Easy Documents Document"
            className="max-w-full max-h-[80vh] mx-auto"
          />
          <div className="mt-4 flex justify-between">
            <button
              className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
              onClick={onClose}
            >
              Close
            </button>
            <a
              href={document}
              download
              className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
            >
              Download
            </a>
          </div>
        </div>
      </div>
    );
  };

  const DownloadableDocuments = () => {
    console.log("License Data Documents:", licenseData.documents); // Debug log
    if (!licenseData.documents) return null; // Ensure documents is defined

    const { receipt, finisheddoc } = licenseData.documents;

    const status = licenseData.Status;

    return (
      <div className="col-span-3 bg-white shadow-md p-6 rounded-lg mb-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Downloadable Documents
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {status === "Submitted" && receipt && (
            <div className="border p-4 rounded-lg text-center bg-gray-50">
              <h3 className="font-semibold mb-2">Receipt</h3>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                onClick={() => setShowDocument(renderImage(receipt))}
              >
                View Receipt
              </button>
            </div>
          )}
          {status === "Completed" && finisheddoc && (
            <div className="border p-4 rounded-lg text-center bg-gray-50">
              <h3 className="font-semibold mb-2">Finished Document</h3>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                onClick={() => setShowDocument(renderImage(finisheddoc))}
              >
                View Document
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };
  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error)
    return <div className="text-center mt-10 text-red-500">{error}</div>;
  if (!licenseData) return <div>No data found</div>;

  const labelClass = "block text-sm mb-2 font-medium text-gray-700";
  const inputClass = "border border-gray-300 rounded-md p-2 w-full";

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
              Udyam Registration
            </h1>
            <DownloadableDocuments />
            <form onSubmit={handleUpdate} className="space-y-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                {/* Personal Information Section */}
                <div className="col-span-3">

                  <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
                    <div>
                      <label htmlFor="ownerFullName" className={labelClass}>
                        Owner Full Name
                      </label>
                      <input
                        type="text"
                        id="ownerFullName"
                        className={inputClass}
                        value={licenseData.ownerFullName}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            ownerFullName: e.target.value,
                          })
                        }
                        placeholder="Enter full name"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                      />
                    </div>



                    <div>
                      <label htmlFor="PanCardNumber" className={labelClass}>
                        PAN Card Number
                      </label>
                      <input
                        type="text"
                        id="PanCardNumber"
                        className={inputClass}
                        value={licenseData.PanCardNumber}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            PanCardNumber: e.target.value,
                          })
                        }
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                        placeholder="Enter PAN number"
                        required
                      />
                    </div>

                    <div>
                      <label htmlFor="AadharCardNumber" className={labelClass}>
                        Aadhar Card Number
                      </label>
                      <input
                        type="text"
                        id="AadharCardNumber"
                        className={inputClass}
                        value={licenseData.AadharCardNumber}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            AadharCardNumber: e.target.value,
                          })
                        }
                        placeholder="Enter Aadhar number"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                      />
                    </div>
                    <div>
                      <label htmlFor="gender" className={labelClass}>
                        Gender
                      </label>
                      <select
                        id="gender"
                        className={inputClass}
                        value={licenseData.gender}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            gender: e.target.value,
                          })
                        }
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <div>
                      <label htmlFor="email" className={labelClass}>
                        Email Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        className={inputClass}
                        value={licenseData.email}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            email: e.target.value,
                          })
                        }
                        placeholder="Enter email"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                      />
                    </div>
                    <div>
                      <label htmlFor="mobileNumber" className={labelClass}>
                        Mobile Number
                      </label>
                      <input
                        type="text"
                        id="mobileNumber"
                        className={inputClass}
                        value={licenseData.mobileNumber}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            mobileNumber: e.target.value,
                          })
                        }
                        placeholder="Enter mobile number"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                      />
                    </div>
                    <div>
                      <label htmlFor="casteCategory" className={labelClass}>
                        Caste Category
                      </label>
                      <input
                        type="text"
                        id="casteCategory"
                        className={inputClass}
                        value={licenseData.casteCategory}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            casteCategory: e.target.value,
                          })
                        }
                        placeholder="Enter caste Category"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                      />
                    </div>

                    <div>
                      <label htmlFor="businessName" className={labelClass}>
                      business Name
                      </label>
                      <input
                        type="text"
                        id="businessName"
                        className={inputClass}
                        value={licenseData.businessName}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            businessName: e.target.value,
                          })
                        }
                        placeholder="Enter business Name"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                      />
                    </div>
                    <div>
                      <label htmlFor="businessStartDate" className={labelClass}>
                        business Start Date
                      </label>
                      <input
                        type="text"
                        id="businessStartDate"
                        className={inputClass}
                        value={licenseData.businessStartDate}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            businessStartDate: e.target.value,
                          })
                        }
                        placeholder="Enter business Start Date"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                      />
                    </div>

                    <div>
                      <label htmlFor="numberOfEmployees" className={labelClass}>
                        number Of Employees
                      </label>
                      <input
                        type="text"
                        id="numberOfEmployees"
                        className={inputClass}
                        value={licenseData.numberOfEmployees}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            numberOfEmployees: e.target.value,
                          })
                        }
                        placeholder="Enter number Of Employees"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                      />
                    </div>
                    <div>
                      <label htmlFor="natureOfBusiness" className={labelClass}>
                        nature Of Business
                      </label>
                      <input
                        type="text"
                        id="natureOfBusiness"
                        className={inputClass}
                        value={licenseData.natureOfBusiness}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            natureOfBusiness: e.target.value,
                          })
                        }
                        placeholder="Enter nature Of Business"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                      />
                    </div>
                    <div>
                      <label htmlFor="bussinessInvestment" className={labelClass}>
                        bussiness Investment
                      </label>
                      <input
                        type="text"
                        id="bussinessInvestment"
                        className={inputClass}
                        value={licenseData.bussinessInvestment}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            bussinessInvestment: e.target.value,
                          })
                        }
                        placeholder="Enter bussiness Investment"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                      />
                    </div>

                  </div>
                </div>



                {/* Bank Details Section */}
                <div className="col-span-2">
                  <h2 className="text-xl font-semibold text-gray-900 mb-4">
                    Bank Details
                  </h2>
                  <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
                    <div>
                      <label htmlFor="bankName" className={labelClass}>
                        Bank Name
                      </label>
                      <input
                        type="text"
                        id="bankName"
                        className={inputClass}
                        value={licenseData.bankName}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            bankName: e.target.value,
                          })
                        }
                        placeholder="Enter bank name"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                      />
                    </div>

                    <div>
                      <label htmlFor="accountNumber" className={labelClass}>
                        Account Number
                      </label>
                      <input
                        type="text"
                        id="accountNumber"
                        className={inputClass}
                        value={licenseData.accountNumber}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            accountNumber: e.target.value,
                          })
                        }
                        placeholder="Enter account number"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                      />
                    </div>

                    <div>
                      <label htmlFor="IFSC" className={labelClass}>
                        IFSC Code
                      </label>
                      <input
                        type="text"
                        id="IFSC"
                        className={inputClass}
                        value={licenseData.IFSC}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            IFSC: e.target.value,
                          })
                        }
                        placeholder="Enter IFSC code"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                      />
                    </div>




                  </div>
                </div>

                {/* Document Upload Section */}
                <div className="col-span-2">
                  <h2 className="text-xl font-semibold text-gray-900 mb-4">
                    Document Upload
                  </h2>
                  <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                    <div>
                      <label htmlFor="aadharCard" className={labelClass}>
                        Aadhar Card
                      </label>
                      <input
                        type="file"
                        id="aadharCard"
                        className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
                        onChange={(e) =>
                          setFiles({ ...files, aadharCard: e.target.files[0] })
                        }
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                      />
                    </div>

                    <div>
                      <label htmlFor="panCard" className={labelClass}>
                        PAN Card
                      </label>
                      <input
                        type="file"
                        id="panCard"
                        className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
                        onChange={(e) =>
                          setFiles({ ...files, panCard: e.target.files[0] })
                        }
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Application History */}
              <div className="bg-green-50 shadow-md p-6 rounded-lg w-full lg:w-full space-y-4">
                <h2 className="text-xl text-center font-bold mb-4 text-gray-700 border-b-2 border-gray-400 pb-2">
                  Application History
                </h2>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">Created At (Date & Time):</strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {new Date(licenseData.createdAt).toLocaleString('en-IN', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                      hour12: true,
                    })}
                  </span>
                </div>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">Updated At (Date & Time):</strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {licenseData.updatedAt
                      ? new Date(licenseData.updatedAt).toLocaleString('en-IN', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: true,
                      })
                      : 'N/A'}
                  </span>
                </div>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">Current Status:</strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {licenseData.Status ? licenseData.Status : 'N/A'}
                  </span>
                </div>

                {licenseData.Status === "Rejected" && (
                  <div>
                    <label htmlFor="rejectedNote" className={labelClass}>
                      Rejected Note
                    </label>
                    <input
                      disabled
                      type="text"
                      id="rejectedNote"
                      className={inputClass}
                      value={licenseData.rejectedNote}
                      required
                    />
                  </div>
                )}
              </div>

              <div className="mt-6">
                <button
                  type="submit"
                  className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Update Registration
                </button>
              </div>
            </form>
            <DocumentPreviewModal
              document={showDocument}
              onClose={() => setShowDocument(null)}
            />
          </div>
        </div>
      </div>
      <CustomToastContainer />
    </div>
  );
};

export default User_Udyam;
