import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom';
import { IoArrowBackCircle } from 'react-icons/io5';
import { useAuth } from '../../context/auth';
import axios from 'axios';
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for Toastify
import LoginModal from '../../components/LoginModel'; // Import the modal
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom


const Votter_shift = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [auth] = useAuth(); // Access auth state from context
  const isLoggedIn = Boolean(auth.token); // Determine if user is logged in based on token
  const [formPrices, setFormPrices] = useState({}); // State for form prices
  const [selectedPrice, setSelectedPrice] = useState(0); // State for selected price
  useEffect(() => {
    // Show the modal if the user is not logged in
    if (!isLoggedIn) {
      setIsModalOpen(true); // Show the modal if not logged in
    }
  }, [isLoggedIn]); // Run effect whenever isLoggedIn changes

  const closeModal = () => {
    setIsModalOpen(false); // Function to close modal
  };

  useEffect(() => {
    // Fetch form prices from the backend
    const fetchFormPrices = async () => {
      try {
        const response = await axios.get('/api/formprices/getallformprice');
        const prices = response.data.reduce((acc, formPrice) => {
          acc[formPrice.formType] = formPrice.price;
          return acc;
        }, {});
        setFormPrices(prices);
        setSelectedPrice(prices['shiftVoterID'] || 0);  // Default price for foodLicenseYear1
      } catch (error) {
        console.error("Error fetching form prices:", error);
        showCustomToast('Error fetching form prices.','error');
      }
    };

    fetchFormPrices();
  }, []);

  const [formData, setFormData] = useState({
    fullName: "",
    gender: "",
    dateOfBirth: "",
    mobileNumber: "",
    email: "",
    placeOfBirth: "",
    birthState: "",
    birthDistrict: "",
    relation: "",
    relationName: "",
    relationAddress: "",
    fullAddress: "",
    tehsil: "",
    relationPincode: "",
    livingAddressSince: "",
    oldVoterIDNumber: "",
    previousConstituencyState: "",
    previousConstituency: "",
    aadharCard: null,
    panCard: null,
    passport: null,
    photo: null,
    signature: null,
  });

  const [transactionID, setTransactionID] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
  
    // Define validation criteria based on file name
    const isPhotoOrSignature = name === 'passportPhoto' || name === 'signature' || name === 'photo';
    const minSize = isPhotoOrSignature ? 5 * 1024 : 75 * 1024; // 5 KB or 75 KB
    const maxSize = isPhotoOrSignature ? 50 * 1024 : 1 * 1024 * 1024; // 50 KB or 1 MB
    const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const validDocumentTypes = ['application/pdf', 'image/jpeg', 'image/pdf'];
  
    // Check file validity
    if (file) {
      const { size, type } = file;
  
      // Check size and type based on the input name
      if (isPhotoOrSignature) {
        // Validate photo and signature files
        if (size < minSize || size > maxSize) {
          showCustomToast('Document must be between 5 KB and 50 KB.','error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file size is invalid
        }
        if (!validImageTypes.includes(type)) {
          showCustomToast('Document must be a JPG, JPEG, or PNG file.' ,'error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file type is invalid
        }
      } else {
        // Validate other documents
        if (size < minSize || size > maxSize) {
          showCustomToast('Document must be between 75 KB and 1 MB.' ,'error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file size is invalid
        }
        if (!validDocumentTypes.includes(type)) {
          showCustomToast('Document must be a PDF, JPG, or JPEG file.' ,'error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file type is invalid
        }
      }
      
      // If validation passes, update the formData
      setFormData({ ...formData, [name]: file });
    }
  };

  const generateTransactionID = () => {
    const timeStamp = Date.now();
    const randomNum = Math.floor(Math.random() * 1000000);
    const merchantPrefix = "T";
    const transactionID = `${merchantPrefix}${timeStamp}${randomNum} `;
    return setTransactionID(transactionID);
  };


  useEffect(()=> {
    generateTransactionID()
  })

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if user is authenticated
    if (!auth.token) {
      showCustomToast('Please login to submit the form.','error');
      return;
    }

    // Create FormData object
    const form = new FormData();
    for (const key in formData) {
      if (formData[key] !== null) {
        form.append(
          key.startsWith('aadharCard') ? 'documents.aadharCard' :
            key.startsWith('photo') ? 'documents.photo' :
              key.startsWith('signature') ? 'documents.signature' :
                key.startsWith('panCard') ? 'documents.panCard' :
                  key.startsWith('passport') ? 'documents.passport' :
                    key,
          formData[key]
        );
      }
    }

    try {
      // Post form data to API
      const response = await axios.post(
        '/api/shiftVoterID/createShiftVoterID',
        form,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${auth.token}`
          }
        }
      );

       // Proceed with the PayU payment logic after form submission
    const payuResponse = await axios.post("/api/payu/hash", {
      fullName: formData.fullName,
      email: formData.email,
      amount: selectedPrice,
      transactionID: transactionID,
      udf1: "detail 1",
      udf2: "detail 2",
      udf3: "",
      udf4: "",
      udf5: ""
    });

    const { hash } = payuResponse.data;

    // Redirect to PayU
    const payuForm = document.createElement("form");
    payuForm.action = "https://secure.payu.in/_payment";
    payuForm.method = "POST";

    const fields = {
      key: 'nC1EmY',
      txnid: transactionID,
      amount: selectedPrice,
      productinfo: "central food license",
      firstname: formData.fullName,
      email: formData.email,
      phone: formData.mobileNumber,
      surl: '/api/payu/success', // Success URL after payment
      furl: "/api/payu/failure",
      hash: hash,
      udf1: "detail 1",
      udf2: "detail 2",
      udf3: "",
      udf4: "",
      udf5: ""
    };

    for (const key in fields) {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = fields[key];
      payuForm.appendChild(input);
    }

    document.body.appendChild(payuForm);
    payuForm.submit();



    } catch (error) {
      // Notify user of error
      showCustomToast('Error submitting Voter ID application.','error');
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };


  return (
    <div>
      <Header />
      <div className="page-title py-6 bg-slate-300" data-aos="fade">
        <div className="container mx-auto px-4 lg:px-20 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          <h1 className="text-black text-xl md:text-2xl font-semibold">
            Shift Voter ID </h1>
          <h1 className="text-lg md:text-xl font-bold underline underline-offset-8 mb-2 lg:mb-0 lg:ml-4 animate-pulse">
            100% Fees Refundable, if Service is not Completed!
          </h1>
          <nav className="breadcrumbs">
            <ol className="flex space-x-4 text-sm">
              <li><Link to={'/'} className="text-black hover:underline hover:text-black text-base">Home</Link></li>
              <li className="text-black">/</li>
              <li>
                <Link to={'/votter_id'} className="flex items-center text-black hover:underline hover:text-black text-base">
                  Go Back
                  <IoArrowBackCircle className="h-5 w-5 mr-2 ml-2" />
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Applicant Details Section */}
      <div className="mt-10 text-center pb-6">
        <h2 className="text-green-600 font-semibold text-2xl">- Shift Voter ID -</h2>
      </div>
      <form className="max-w-6xl mx-auto bg-white shadow-2xl rounded-md p-6 mt-10 mb-10" onSubmit={handleSubmit}>
        <div className="flex flex-col sm:flex-row sm:justify-between mt-10 pb-6">
          <h2 className="text-green-600 font-semibold text-xl text-center sm:text-left">
            Service Charge : <span className='text-red-600' id='formprice'> ₹ {selectedPrice}</span>
          </h2>
        </div>


        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="form-group">
            <label htmlFor="fullName" className="block text-gray-600 font-semibold mb-2">Full Name</label>
            <input
              type="text"
              name="fullName"
              id="fullName"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.fullName}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="gender" className="block text-gray-600 font-semibold mb-2">Gender</label>
            <select
              name="gender"
              id="gender"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.gender}
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="dateOfBirth" className="block text-gray-600 font-semibold mb-2">Date of Birth</label>
            <input
              type="date"
              name="dateOfBirth"
              id="dateOfBirth"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.dateOfBirth}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="mobileNumber" className="block text-gray-600 font-semibold mb-2">Mobile Number</label>
            <input
              type="text"
              name="mobileNumber"
              id="mobileNumber"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.mobileNumber}
              onChange={handleInputChange}
              onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} // Only allows digits
              pattern="[0-9]{10}"
              maxLength="10"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email" className="block text-gray-600 font-semibold mb-2">Email Id</label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
                onChange={handleInputChange}
                placeholder="example@gmail.com" // Placeholder for guidance
                className={`w-full border ${formData.email && !formData.email.includes('@') ? 'border-red-500' : 'border-gray-300'} rounded px-3 py-2 focus:outline-none`}
                required
                pattern="[a-zA-Z0-9._%+-]+@gmail\.com" // Regex pattern for Gmail addresses
                title="Please enter a valid Gmail address." // Error message for invalid input
                onBlur={(e) => {
                  // Validate on input blur
                  if (!e.target.value.includes('@')) {
                    e.target.setCustomValidity('You must include "@" in your email.');
                  } else {
                    e.target.setCustomValidity(''); // Clear the error
                  }
                }}
              />
              {formData.email && !formData.email.includes('@') && (
                <span className="text-red-500 text-xs mt-1">Email must contain "@"</span>
)}
          </div>

          <div className="form-group">
            <label htmlFor="placeOfBirth" className="block text-gray-600 font-semibold mb-2">Place Of Birth</label>
            <input
              type="text"
              name="placeOfBirth"
              id="placeOfBirth"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.placeOfBirth}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="birthState" className="block text-gray-600 font-semibold mb-2">Birth State</label>
            <input
              type="text"
              name="birthState"
              id="birthState"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.birthState}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="birthDistrict" className="block text-gray-600 font-semibold mb-2">Birth District</label>
            <input
              type="text"
              name="birthDistrict"
              id="birthDistrict"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.birthDistrict}
              onChange={handleInputChange}
              required
            />
          </div>


          <div className="form-group">
            <label htmlFor="relation" className="block text-gray-600 font-semibold mb-2">Relation</label>
            <select
              name="relation"
              id="relation"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.relation}
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>Select Relation</option>
              <option value="Father">Father</option>
              <option value="Mother">Mother</option>
              <option value="Husband">Husband</option>
              <option value="Wife">Wife</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="relationName" className="block text-gray-600 font-semibold mb-2">Relation Name</label>
            <input
              type="text"
              name="relationName"
              id="relationName"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.relationName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="relationAddress" className="block text-gray-600 font-semibold mb-2">Relation Name</label>
            <input
              type="text"
              name="relationAddress"
              id="relationAddress"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.relationAddress}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="fullAddress" className="block text-gray-600 font-semibold mb-2">Full Address</label>
            <input
              type="text"
              name="fullAddress"
              id="fullAddress"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.fullAddress}
              onChange={handleInputChange}
              required
            />
          </div>


          <div className="form-group">
            <label htmlFor="relationPincode" className="block text-gray-600 font-semibold mb-2">Pincode</label>
            <input
              type="text"
              name="relationPincode"
              id="relationPincode"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.relationPincode}
              onChange={handleInputChange}
              pattern="\d{6}" // Require exactly 6 digits
maxLength="6"
onInput={(e) => {
e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
}}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="livingAddressSince" className="block text-gray-600 font-semibold mb-2">Living At Current Address Since</label>
            <input
              type="text"
              name="livingAddressSince"
              id="livingAddressSince"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.livingAddressSince}
              onChange={handleInputChange}
              required
            />
          </div>


          <div className="form-group">
            <label htmlFor="oldVoterIDNumber" className="block text-gray-600 font-semibold mb-2">Old Voter ID Number</label>
            <input
              type="text"
              name="oldVoterIDNumber"
              id="oldVoterIDNumber"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.oldVoterIDNumber}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="previousConstituencyState" className="block text-gray-600 font-semibold mb-2">Previous Constituency State</label>
            <input
              type="text"
              name="previousConstituencyState"
              id="previousConstituencyState"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.previousConstituencyState}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="previousConstituency" className="block text-gray-600 font-semibold mb-2">Previous Constituency</label>
            <input
              type="text"
              name="previousConstituency"
              id="previousConstituency"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.previousConstituency}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        {/* File Upload Section */}
        <div className="mt-10">
          <h2 className="text-green-600 font-semibold text-2xl text-center mb-6">Upload Documents</h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="form-group">
              <label htmlFor="aadharCard" className="block text-gray-600 font-semibold mb-2">Aadhar Card 
              <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
              </label>
              <input
                type="file"
                name="aadharCard"
                id="aadharCard"
                className="w-full px-3 py-2 border border-gray-300 rounded"
                onChange={handleFileChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="panCard" className="block text-gray-600 font-semibold mb-2">PAN Card 
              <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
              </label>
              <input
                type="file"
                name="panCard"
                id="panCard"
                className="w-full px-3 py-2 border border-gray-300 rounded"
                onChange={handleFileChange}
                required
              />
            </div>


            <div className="form-group">
              <label htmlFor="photo" className="block text-gray-600 font-semibold mb-2">Photo 
              <br /><span className='text-red-600 text-xs'>(Max Size: PNG,JPG,JPEG / 5kb to 50kb)</span>
              </label>
              <input
                type="file"
                name="photo"
                id="photo"
                className="w-full px-3 py-2 border border-gray-300 rounded"
                onChange={handleFileChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="signature" className="block text-gray-600 font-semibold mb-2">Signature 
              <br /><span className='text-red-600 text-xs'>(Max Size: PNG,JPG,JPEG / 5kb to 50kb)</span>
              </label>
              <input
                type="file"
                name="signature"
                id="signature"
                className="w-full px-3 py-2 border border-gray-300 rounded"
                onChange={handleFileChange}
                required
              />
            </div>
          </div>
        </div>

        <div className="text-center mt-10">
          <button
            type="submit"
            className="bg-green-600 text-white px-8 py-2 rounded hover:bg-green-700 focus:outline-none focus:bg-green-700"
          >
            Submit
          </button>
        </div>
      </form>
      {/* Render Login Modal only if not logged in */}
      {!isLoggedIn && isModalOpen && <LoginModal closeModal={closeModal} />}
      <CustomToastContainer />
      <Footer />
    </div>
  )
}

export default Votter_shift