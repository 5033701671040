import React, { useEffect } from 'react';
import { useAuth } from '../../context/auth'; // Adjust the import path based on your project structure
import axios from 'axios';
import logo from "../../assets/logo.png";

const Uprofile = () => {
  const [auth] = useAuth();
  const [profileData, setProfileData] = React.useState(null); // State to hold profile data

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get('/api/auth/profile', {
          headers: {
            Authorization: `Bearer ${auth.token}`, // Use token from context
          },
        });
        setProfileData(response.data.user);
      } catch (error) {
        console.error("Error fetching profile data", error);
      }
    };

    if (auth.token) {
      fetchUserProfile();
    }
  }, [auth.token]); // Dependency on token

  return (
    <div className="flex pt-14 flex-col items-center justify-center px-4">
      <div className="w-full max-w-xl bg-white rounded-lg shadow-md overflow-hidden">
        {/* Header */}
        <div className="bg-gradient-to-r from-blue-200 to-blue-500 p-6 text-white flex justify-center items-center">
          <div className="w-auto h-24 overflow-hidden flex justify-center items-center">
            <img
              src={logo}
              alt=" Make Easy Documents Make Easy Documents Logo"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
        {/* User Info */}
        <div className="justify-center rounded-lg p-1 m-2 sm:m-4 grid grid-cols-1">
          <div className="bg-gray-50 p-4 rounded-lg shadow-inner ">
            <h2 className="font-semibold text-blue-600 text-lg border-b pb-2 text-center">User Of Make Easy Documents</h2>

            {/* Responsive Text for Name */}
            <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-blue-900 pt-2">
              Name : {profileData ? profileData.firstname + ' ' + profileData.lastname : 'Loading...'}
            </h1>
            
            {/* Responsive Text for Email */}
            <p className="text-base sm:text-lg md:text-xl mt-4 text-gray-600">
              <strong>Gmail</strong> : {profileData ? profileData.email : 'Loading...'}
            </p>

            <ul className="mt-4 space-y-3 text-gray-600">
  <li>
    <strong>Website</strong> :{" "}
    <a 
      href="https://makeeasydocuments.com/" 
      target="_blank" 
      rel="noopener noreferrer" 
      className="text-blue-500 no-underline hover:underline"
    >
      www.makeeasydocuments.com
    </a>
  </li>
</ul>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Uprofile;
