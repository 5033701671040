import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaTrash } from 'react-icons/fa';
import axios from 'axios';
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import 'react-toastify/dist/ReactToastify.css';

const Add_reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [name, setName] = useState('');
  const [stars, setStars] = useState('');
  const [description, setDescription] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewsPerPage] = useState(5); // Set number of reviews per page

  // Fetch all reviews when component mounts
  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      const response = await axios.get('/api/review/getAllReviews');
      setReviews(response.data.reviews);
      showCustomToast('Reviews loaded successfully!');
    } catch (error) {
      console.error('Error fetching reviews:', error);
      showCustomToast('Failed to load reviews!');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/review/createReview', {
        name,
        description,
        stars,
      });
      setReviews([...reviews, response.data.newReview]);
      showCustomToast('Review added successfully!');

      // Clear the form inputs
      setName('');
      setStars('');
      setDescription('');
    } catch (error) {
      console.error('Error adding review:', error);
      showCustomToast('Failed to add review!','error');
    }
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setIsModalOpen(true);
  };

  const confirmDeletion = async () => {
    try {
      await axios.delete(`/api/review/deleteReview/${deleteId}`);
      setReviews(reviews.filter((review) => review._id !== deleteId));
      showCustomToast('Review deleted successfully!');
    } catch (error) {
      console.error('Error deleting review:', error);
      showCustomToast('Failed to delete review!','error');
    } finally {
      setIsModalOpen(false);
      setDeleteId(null); // Reset deleteId after deletion
    }
  };

  // Get current reviews for the current page
  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(reviews.length / reviewsPerPage);

  // Handle next and previous page
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="flex1">
      <CustomToastContainer />
      <h2 className="mt-3 mx-3 mb-3 font-bold text-3xl border border-black text-blue-800 text-center bg-gray-300 p-2 rounded-lg shadow-sm transition-transform duration-300">
      Add Reviews
      </h2>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: 'auto', opacity: 1 }}
        exit={{ height: 0, opacity: 0 }}
        className="overflow-x-auto w-full"
      >

        <section className="max-w-4xl p-6 mb-12 mt-10 mx-4 md:mx-auto border border-black bg-blue-100 rounded-md shadow-xl">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
              <div>
                <label className=" font-semibold text-xl " htmlFor="name">
                  Name <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  id="name"
                  type="text"
                  placeholder="Enter Name..."
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-black rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div>
                <label className=" font-semibold text-xl" htmlFor="star">
                  Star Between 1 To 5 <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  id="star"
                  type="number"
                  min="1"
                  max="5"
                  placeholder="Enter Star Between 1 to 5..."
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-black rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  value={stars}
                  onChange={(e) => setStars(e.target.value)}
                  required
                />
              </div>
            </div>
            <br />
            <div>
              <label className=" font-semibold text-xl " htmlFor="description">
                Description<span className="text-red-500 ml-1">*</span>
              </label>
              <textarea
                id="description"
                placeholder="Enter Review Description..."
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-black rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                rows="2"
              ></textarea>
            </div>
            <div className="flex items-center gap-4">
              <div className="flex justify-end mt-6">
                <button className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-700 focus:outline-none focus:bg-gray-600">
                  Upload
                </button>
              </div>
            </div>
          </form>
        </section>

      </motion.div>

      <div className="mx-4 mb-20">
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          className="overflow-x-auto w-full max-w-[21rem] sm:max-w-full"
        >
          <table className="w-full min-w-[700px] mx-auto text-sm md:text-md lg:text-lg bg-white shadow-md rounded-lg border-collapse">
            <thead>
              <tr className="bg-gray-300">
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">Sr no</th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">Name</th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">Stars</th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">Description</th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">Date</th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentReviews.map((review, index) => (
                <tr key={review._id} className="bg-white">
                  <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{index + 1 + (currentPage - 1) * reviewsPerPage}</td>
                  <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{review.name}</td>
                  <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{review.stars}</td>
                  <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{review.description}</td>
                  <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{new Date(review.createdAt).toLocaleDateString()}</td>
                  <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">
                    <button
                      onClick={() => handleDelete(review._id)}
                      className="bg-red-400 text-white px-2 py-1 rounded-lg hover:bg-red-500"
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </motion.div>

        {/* Pagination Controls */}
        <div className="flex justify-center mt-4">
          <button
            onClick={handlePrev}
            disabled={currentPage === 1}
            className={`mx-1 px-3 py-1 rounded-lg ${currentPage === 1 ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'}`}
          >
            Previous
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => paginate(index + 1)}
              className={`mx-1 px-3 py-1 rounded-lg ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                }`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            className={`mx-1 px-3 py-1 rounded-lg ${currentPage === totalPages ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'}`}
          >
            Next
          </button>
        </div>

      </div>

      {/* Confirmation Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-md mx-2 sm:mx-0"> {/* Add mx-2 for mobile, remove for larger screens */}
            <h3 className="text-lg font-semibold">Are you sure you want to delete this review?</h3>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 mr-2 text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={confirmDeletion}
                className="px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>

      )}
    </div>
  );
};

export default Add_reviews;
