import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  showCustomToast,
  CustomToastContainer,
} from "../../components/CustomToast";
import "react-toastify/dist/ReactToastify.css";

const User_renew = () => {
  const { id } = useParams();
  const [licenseData, setLicenseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState({});
  const [showDocument, setShowDocument] = useState(null);
  const statusEnum = ["In-Progress", "Submitted", "Rejected", "Completed"];

  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        const response = await axios.get(
          `/api/renewalLicense/getRenewalDL/${id}`
        );
        console.log(response);
        setLicenseData(response.data);
      } catch (err) {
        setError("Error fetching license data",'error');
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseData();
  }, [id]);

  const handleUpdate = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    // Append all fields from licenseData to formData
    formData.append("fullName", licenseData.fullName);
    formData.append("state", licenseData.state);
    formData.append("rto", licenseData.rto);
    formData.append(
      "permanentLicenseNumber",
      licenseData.permanentLicenseNumber
    );
    formData.append("vehicleType", licenseData.vehicleType);
    formData.append(
      "fatherOrHusbandFullName",
      licenseData.fatherOrHusbandFullName
    );
    formData.append("gender", licenseData.gender);
    formData.append("dateOfBirth", licenseData.dateOfBirth);
    formData.append("qualification", licenseData.qualification);
    formData.append("identificationMarks", licenseData.identificationMarks);
    formData.append("bloodGroup", licenseData.bloodGroup);
    formData.append("emergencyContact", licenseData.emergencyContact);
    formData.append("email", licenseData.email);
    formData.append("address", licenseData.address);
    formData.append("district", licenseData.district);
    formData.append("taluka", licenseData.taluka);
    formData.append("rejectedNote", licenseData.rejectedNote);
    formData.append("submitNote", licenseData.submitNote);
    formData.append("completedNote", licenseData.completedNote);
    formData.append("rejectedAt", licenseData.rejectedAt);
    formData.append("submitedAt", licenseData.submitedAt);
    formData.append("CompletedAt", licenseData.CompletedAt);
    formData.append("Status", licenseData.Status);

    // Append files to formData
    for (const [key, value] of Object.entries(files)) {
      formData.append(`documents.${key}`, value);
    }

    try {
      const response = await axios.put(
        `/api/renewalLicense/updaterenewallicense/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      showCustomToast(response.data.message);
    } catch (error) {
      showCustomToast("Failed to update license.",'error');
    }
  };

  const renderImage = (imageData) => {
    if (
      imageData &&
      imageData.contentType &&
      imageData.data &&
      imageData.data.data
    ) {
      const byteArray = new Uint8Array(imageData.data.data);
      const base64String = btoa(String.fromCharCode(...byteArray));
      return `data:${imageData.contentType};base64,${base64String}`;
    }
    return null;
  };

  const DocumentPreviewModal = ({ document, onClose }) => {
    if (!document) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full">
          <img
            src={document}
            alt="Document"
            className="max-w-full max-h-[80vh] mx-auto"
          />
          <div className="mt-4 flex justify-between">
            <button
              className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
              onClick={onClose}
            >
              Close
            </button>
            <a
              href={document}
              download
              className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
            >
              Download
            </a>
          </div>
        </div>
      </div>
    );
  };

  const DownloadableDocuments = () => {
    console.log("License Data Documents:", licenseData.documents); // Debug log
    if (!licenseData.documents) return null; // Ensure documents is defined

    const { receipt, finisheddoc } = licenseData.documents;

    const status = licenseData.Status;

    return (
      <div className="col-span-3 bg-white shadow-md p-6 rounded-lg mb-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Downloadable Documents
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {status === "Submitted" && receipt && (
            <div className="border p-4 rounded-lg text-center bg-gray-50">
              <h3 className="font-semibold mb-2">Receipt</h3>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                onClick={() => setShowDocument(renderImage(receipt))}
              >
                View Receipt
              </button>
            </div>
          )}
          {status === "Completed" && finisheddoc && (
            <div className="border p-4 rounded-lg text-center bg-gray-50">
              <h3 className="font-semibold mb-2">Finished Document</h3>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                onClick={() => setShowDocument(renderImage(finisheddoc))}
              >
                View Document
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error)
    return <div className="text-center mt-10 text-red-500">{error}</div>;
  if (!licenseData) return <div>No data found</div>;

  const labelClass = "block text-sm mb-2 font-medium text-gray-700";
  const inputClass = "border border-gray-300 rounded-md p-2 w-full";

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
              Renew Driving License
            </h1>
            <DownloadableDocuments />

            <form onSubmit={handleUpdate} className="space-y-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
                {/* Personal Information Section */}
                <div className="col-span-3">
                  <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
                    <div>
                      <label htmlFor="fullName" className={labelClass}>
                        Full Name
                      </label>
                      <input
                        type="text"
                        id="fullName"
                        className={inputClass}
                        value={licenseData.fullName}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            fullName: e.target.value,
                          })
                        }
                        placeholder="Enter Full Name"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                      />
                    </div>
                    <div>
                      <label htmlFor="state" className={labelClass}>
                        State
                      </label>
                      <input
                        type="text"
                        id="state"
                        className={inputClass}
                        value={licenseData.state}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            state: e.target.value,
                          })
                        }
                        placeholder="Enter State"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                      />
                    </div>
                    <div>
                      <label htmlFor="rto" className={labelClass}>
                        Rto
                      </label>
                      <input
                        type="text"
                        id="rto"
                        className={inputClass}
                        value={licenseData.rto}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            rto: e.target.value,
                          })
                        }
                        placeholder="Enter Rto"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="permanentLicenseNumber"
                        className={labelClass}
                      >
                        Permanent License Number
                      </label>
                      <input
                        type="text"
                        id="permanentLicenseNumber"
                        className={inputClass}
                        value={licenseData.permanentLicenseNumber}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            permanentLicenseNumber: e.target.value,
                          })
                        }
                        placeholder="Enter full Permanent License Number"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                      />
                    </div>
                    <div>
                      <label htmlFor="vehicleType" className={labelClass}>
                        Vehicle Type
                      </label>
                      <select
                        id="vehicleType"
                        className={inputClass}
                        value={licenseData.vehicleType}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            vehicleType: e.target.value,
                          })
                        }
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                      >
                        <option value="" disabled>
                          Select Vehicle Type
                        </option>
                        <option value="motorcycle">Motor Cycle</option>
                        <option value="twowheeler">Two Wheeler</option>
                        <option value="fourwheeler">Four Wheeler</option>
                        <option value="fourwheelerTR">Four Wheeler TR</option>
                        <option value="fourPlusTwo">Four + Two Wheeler</option>
                      </select>
                    </div>

                    <div>
                      <label htmlFor="fatherOrHusbandFullName" className={labelClass}>
                        Father Name
                      </label>
                      <input
                        type="text"
                        id="fatherOrHusbandFullName"
                        className={inputClass}
                        value={licenseData.fatherOrHusbandFullName}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            fatherOrHusbandFullName: e.target.value,
                          })
                        }
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label htmlFor="gender" className={labelClass}>
                        Gender
                      </label>
                      <select
                        id="gender"
                        className={inputClass}
                        value={licenseData.gender}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            gender: e.target.value,
                          })
                        }
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <div>
                      <label htmlFor="dateOfBirth" className={labelClass}>
                        Date of Birth
                      </label>
                      <input
                        type="date"
                        id="dateOfBirth"
                        className={inputClass}
                        value={licenseData.dateOfBirth}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            dateOfBirth: e.target.value,
                          })
                        }
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                      />
                    </div>
                    <div>
                      <label htmlFor="qualification" className={labelClass}>
                        Qualification
                      </label>
                      <input
                        type="text"
                        id="qualification"
                        className={inputClass}
                        value={licenseData.qualification}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            qualification: e.target.value,
                          })
                        }
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                      />
                    </div>

                    
                    <div>
                      <label htmlFor="bloodGroup" className={labelClass}>
                        Blood Group
                      </label>
                      <input
                        type="text"
                        id="bloodGroup"
                        className={inputClass}
                        value={licenseData.bloodGroup}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            bloodGroup: e.target.value,
                          })
                        }
                        placeholder="Enter blood Group"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label htmlFor="emergencyNumber" className={labelClass}>
                        Emergency Number
                      </label>
                      <input
                        type="text"
                        id="emergencyNumber"
                        className={inputClass}
                        value={licenseData.birthState}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            birthState: e.target.value,
                          })
                        }
                        placeholder="Enter Emergency Number"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label htmlFor="email" className={labelClass}>
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        className={inputClass}
                        value={licenseData.email}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            email: e.target.value,
                          })
                        }
                        placeholder="Enter email"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label htmlFor="address" className={labelClass}>
                        Address
                      </label>
                      <input
                        type="text"
                        id="address"
                        className={inputClass}
                        value={licenseData.address}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            address: e.target.value,
                          })
                        }
                        placeholder="Enter address "
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label htmlFor="district" className={labelClass}>
                        district
                      </label>
                      <input
                        type="text"
                        id="district"
                        className={inputClass}
                        value={licenseData.district}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            district: e.target.value,
                          })
                        }
                        placeholder="Enter district"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label htmlFor="taluka" className={labelClass}>
                        taluka
                      </label>
                      <input
                        type="text"
                        id="taluka"
                        className={inputClass}
                        value={licenseData.taluka}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            taluka: e.target.value,
                          })
                        }
                        placeholder="Enter taluka "
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                      />
                    </div>
                  </div>
                </div>

               
                {/* Document Upload Section */}
                <div className="col-span-3">
                  <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                    {["aadhaarCard", "voterID", "addressProof", "photo"].map(
                      (doc) => (
                        <div key={doc}>
                          <label htmlFor={doc} className={labelClass}>
                            {doc.replace(/([A-Z])/g, " $1").toUpperCase()}
                          </label>
                          <input
                            type="file"
                            id={doc}
                            className={inputClass}
                            onChange={(e) =>
                              setFiles({ ...files, [doc]: e.target.files[0] })
                            }
                            accept=".jpg,.jpeg,.png,.pdf"
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>

                {/* Status Section */}
                {/* Application History */}
                <div className="col-span-3 bg-green-50 shadow-md p-6 rounded-lg w-full lg:w-full space-y-4">
                  <h2 className="text-xl text-center font-bold mb-4 text-gray-700 border-b-2 border-gray-400 pb-2">
                    Application History
                  </h2>

                  <div className="flex justify-between text-gray-800">
                    <strong className="text-gray-600 flex-1">
                      Created At (Date & Time):
                    </strong>
                    <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                      {new Date(licenseData.createdAt).toLocaleString("en-IN", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true,
                      })}
                    </span>
                  </div>

                  <div className="flex justify-between text-gray-800">
                    <strong className="text-gray-600 flex-1">
                      Updated At (Date & Time):
                    </strong>
                    <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                      {licenseData.updatedAt
                        ? new Date(licenseData.updatedAt).toLocaleString(
                            "en-IN",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: true,
                            }
                          )
                        : "N/A"}
                    </span>
                  </div>

                  <div>
                    <label htmlFor="Status" className={labelClass}>
                      Status{" "}
                    </label>
                    <input
                      disabled
                      type="text"
                      id="Status"
                      className={inputClass}
                      value={licenseData.Status}
                      onChange={(e) =>
                        setLicenseData({
                          ...licenseData,
                          Status: e.target.value,
                        })
                      }
                      placeholder="Status"
                      required
                    />
                  </div>

                  {/* Rejected Note */}
                  {licenseData.Status === "Rejected" && (
                    <div>
                      <label htmlFor="rejectedNote" className={labelClass}>
                        Rejected Note
                      </label>
                      <input
                        disabled
                        type="text"
                        id="rejectedNote"
                        className={inputClass}
                        value={licenseData.rejectedNote}
                        required
                      />
                    </div>
                  )}
                </div>
                {/* Update Button */}
                <div className="col-span-3">
                  <button
                    type="submit"
                    className="w-full rounded-md bg-green-600 py-2 px-4 text-white font-semibold shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>

            <DocumentPreviewModal
              document={showDocument}
              onClose={() => setShowDocument(null)}
            />

            <CustomToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default User_renew;
