import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import Common_form from '../../components/Common_form';
import mahsul from '../../assets/services/legal/m.jpg';
import { AiFillHome, AiOutlineAppstore } from 'react-icons/ai';
import LoginModal from '../../components/LoginModel'; // Import the modal
import { useAuth } from '../../context/auth'; // Import the useAuth hook

const Mahsule = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [auth] = useAuth(); // Access auth state from context
  const isLoggedIn = Boolean(auth.token); // Determine if user is logged in based on token

  useEffect(() => {
    // Show the modal if the user is not logged in
    if (!isLoggedIn) {
      setIsModalOpen(true); // Show the modal if not logged in
    }
  }, [isLoggedIn]); // Run effect whenever isLoggedIn changes

  const closeModal = () => {
    setIsModalOpen(false); // Function to close modal
  };

  return (
    <div className='bg-slate-100'>
      <Header />
      {/* Page Title */}
      <div className="page-title py-6 bg-slate-300" data-aos="fade">
        <div className="container mx-auto px-4 lg:px-20 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          {/* Title */}
          <h1 className="text-black text-xl md:text-2xl font-semibold">Mahsul Legal Consultancy</h1>

          {/* Refund message */}
          <style jsx>{`
            @keyframes intenseBlink {
              0%, 100% { opacity: 1; color: #f20000; }
              20% { opacity: 1; color: #000000; }
            }
          `}</style>
          <h1
            className="text-lg md:text-xl font-bold underline underline-offset-8 
            mb-2 lg:mb-0 lg:ml-4 
            animate-[intenseBlink_1s_ease-in-out_infinite]"
          >
            100% Fees Refundable, if Service is not Completed!
          </h1>

          {/* Breadcrumbs */}
          <nav className="breadcrumbs mt-2 lg:mt-0">
            <ol className="flex space-x-2 lg:space-x-4 text-sm items-center">
              <li className="flex items-center">
                <AiFillHome className="mr-1 text-base hover:underline" />
                <Link to={'/'} className="text-black hover:underline text-base">Home</Link>
              </li>
              <li className="text-black">/</li>
              <li className="flex items-center">
                <AiOutlineAppstore className="mr-1 text-base" />
                <Link to={'/all_service'} className="text-black hover:underline text-base">All Services</Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container mx-auto px-4 lg:px-16 py-8">
        <div className="text-center mb-8">
          <h2 className="lg:text-2xl font-bold border-b-4 border-red-600 inline-block pb-2">
            Mahsul Legal Consultancy
          </h2>
          <h3 className='pt-4 text-base font-semibold'>" Mahsul Legal Consultancy : आपके संघर्ष को हमारी देखभाल "</h3>
        </div>
        <hr className="h-px my-9 bg-gray-700 border-none dark:bg-gray-700" />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Left side - Form */}
          <Common_form applicationName="Mahsul Legal" />

          {/* Right side - Content */}
          <div>
            {/* Image section with hover effect */}
            <div className="flex-1 flex justify-center items-center">
              <img
                src={mahsul} // Replace with the correct path for the driving license image
                alt=" Make Easy Documents Mahsul License"
                className="w-full h-auto max-w-lg lg:max-w-xl xl:max-w-2xl object-contain transform transition-transform duration-500 hover:scale-95 hover:rotate-0 shadow-lg hover:shadow-2xl rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Render Login Modal only if not logged in */}
      {!isLoggedIn && isModalOpen && <LoginModal closeModal={closeModal} />}

      <Footer />
    </div>
  );
};

export default Mahsule;
