import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import { AiFillHome, AiOutlineAppstore } from 'react-icons/ai'
import LoginModal from '../../components/LoginModel'; // Import the modal
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../context/auth';
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';

const Tredmark = () => {
  const initialFormData = {
    applicationName: 'Trademark',
    fullName: '',
    companyName: '',
    email: '',
    address: '',
    pincode: '',
    mobileNumber: '',
    dateOfBirth: '',
    state: '',
    aadharCard: null,
    pancard: null,
    electricBill: null,
    photo: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formPrices, setFormPrices] = useState({});
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [auth] = useAuth();
  const isLoggedIn = Boolean(auth.token);

  useEffect(() => {
    const fetchFormPrices = async () => {
      try {
        const response = await axios.get('/api/formprices/getallformprice');
        const prices = response.data.reduce((acc, formPrice) => {
          acc[formPrice.formType] = formPrice.price;
          return acc;
        }, {});
        setFormPrices(prices);
        setSelectedPrice(prices['trademark'] || 0);
      } catch (error) {
        console.error("Error fetching form prices:", error);
        showCustomToast('Error fetching form prices.', 'error');
      }
    };

    fetchFormPrices();
  }, []);

  useEffect(() => {
    if (!isLoggedIn) setIsModalOpen(true);
  }, [isLoggedIn]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isLoggedIn) {
      showCustomToast('Please log in to fill out the form.', 'error');
      return;
    }

    const form = new FormData();
    for (const key in formData) {
      if (formData[key] !== null) {
        form.append(
          key.startsWith('aadharCard') ? 'documents.aadharCard' :
            key.startsWith('panCard') ? 'documents.panCard' :
              key.startsWith('photo') ? 'documents.photo' :
                key.startsWith('electricBill') ? 'documents.electricBill' :
                  key, formData[key]
        );
      }
    }

    try {
      const config = { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${auth.token}` } };
      const formResponse = await axios.post('/api/trademark/createtrademark', form, config);

      const transactionID = formResponse.data.transactionID; // Assuming response has a transactionID
      const payuResponse = await axios.post('/api/payu/hash', {
        fullName: formData.fullName,
        email: formData.email,
        amount: selectedPrice,
        transactionID,
        udf1: 'detail 1',
        udf2: 'detail 2',
        udf3: '',
        udf4: '',
        udf5: '',
      });

      const { hash } = payuResponse.data;

      const payuForm = document.createElement('form');
      payuForm.action = 'https://secure.payu.in/_payment';
      payuForm.method = 'POST';

      const fields = {
        key: 'nC1EmY',
        txnid: transactionID,
        amount: selectedPrice,
        productinfo: 'central food license',
        firstname: formData.fullName,
        email: formData.email,
        phone: formData.mobileNumber,
        surl: '/api/payu/success',
        furl: '/api/payu/failure',
        hash: hash,
        udf1: 'detail 1',
        udf2: 'detail 2',
        udf3: '',
        udf4: '',
        udf5: '',
      };

      for (const key in fields) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = fields[key];
        payuForm.appendChild(input);
      }

      document.body.appendChild(payuForm);
      payuForm.submit();
    } catch (error) {
      console.error("Error submitting the form or initiating payment:", error);
      showCustomToast("Error submitting the form or initiating payment.", "error");
    }
  };

  const closeModal = () => setIsModalOpen(false);
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    }
  };

  return (
    <div>
      <Header />
      {/* Page Title */}
      <div className="page-title py-6 bg-slate-300" data-aos="fade">
        <div className="container mx-auto px-4 lg:px-20 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          {/* Title */}
          <h1 className=" text-black text-xl md:text-2xl font-semibold">Trademark Registration</h1>

          {/* Refund message */}
          <style jsx>{`
      @keyframes intenseBlink {
        0%, 100% { opacity: 1; color: #f20000; }
        20% { opacity: 1; color: #000000; }
      }
    `}</style>
          <h1
            className="
        text-lg md:text-xl font-bold underline underline-offset-8 
        mb-2 lg:mb-0 lg:ml-4 
        animate-[intenseBlink_1s_ease-in-out_infinite]
      "
          >
            100% Fees Refundable, if Service is not Completed!
          </h1>

          {/* Breadcrumbs */}
          <nav className="breadcrumbs mt-2 lg:mt-0">
            <ol className="flex space-x-2 lg:space-x-4 text-sm items-center">
              <li className="flex items-center">
                <AiFillHome className="mr-1 text-base hover:underline" />
                <Link to={'/'} className="text-black hover:underline text-base">Home</Link>
              </li>
              <li className="text-black">/</li>
              <li className="flex items-center">
                <AiOutlineAppstore className="mr-1 text-base" />
                <Link to={'/all_service'} className="text-black hover:underline text-base">All Services</Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container mx-auto px-4 lg:px-16 py-8">
        <div className="text-center mb-8">
          <h2 className="text-2xl lg:text-2xl font-bold border-b-4 border-red-600 inline-block pb-2">
            Trademark Registration
          </h2>
          <h3 className='pt-4 text-base font-semibold'>Our Expert consultation will help you to get the Trademark registration online seamlessly from the government.
          </h3>
          <p className='pt-4'>Trademark Registration gives the owner an exclusive right to protect the Logo, Symbol, and Words as the identity of the Brand.
            To Protect your brand with our trademark registration services.</p>
        </div>
        <hr class="h-px my-9 bg-gray-700 border-none dark:bg-gray-700" />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Left side - Form */}

          <div className="bg-white p-10 rounded-lg shadow-xl">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col sm:flex-row sm:justify-between mt-10 pb-6">
                <h2 className="text-green-600 font-semibold text-xl text-center sm:text-left">
                  Service Charge : <span className='text-red-600' id='formprice'> ₹ {selectedPrice}</span>
                </h2>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label className="block text-sm font-semibold mb-2">Enter Full Name</label>
                  <input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    placeholder="---"
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-semibold mb-2">Enter Company Name</label>
                  <input
                    type="text"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleInputChange}
                    placeholder="---"
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-semibold mb-2">Your Email</label>
                  <input
                    type="email"
                    name="email"

                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="example@gmail.com" // Placeholder for guidance
                    className={`w-full border ${formData.email && !formData.email.includes('@') ? 'border-red-500' : 'border-gray-300'} rounded px-3 py-2 focus:outline-none`}
                    required
                    pattern="[a-zA-Z0-9._%+-]+@gmail\.com" // Regex pattern for Gmail addresses
                    title="Please enter a valid Gmail address." // Error message for invalid input
                    onBlur={(e) => {
                      // Validate on input blur
                      if (!e.target.value.includes('@')) {
                        e.target.setCustomValidity('You must include "@" in your email.');
                      } else {
                        e.target.setCustomValidity(''); // Clear the error
                      }
                    }}
                  />
                  {formData.email && !formData.email.includes('@') && (
                    <span className="text-red-500 text-xs mt-1">Email must contain "@"</span>
                  )}
                </div>
                <div>
                  <label className="block text-sm font-semibold mb-2">Address</label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    placeholder="---"
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none"
                    required
                  />
                </div>

              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">


                <div>
                  <label className="block text-sm font-semibold mb-2">Pincode</label>
                  <input
                    type="text"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleInputChange}
                    placeholder="---"
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none"
                    pattern="\d{6}" // Require exactly 6 digits
                    maxLength="6"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
                    }}
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-semibold mb-2">Mobile Number</label>
                  <input
                    type="text"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleInputChange}
                    placeholder="---"
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none"
                    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} // Only allows digits
                    pattern="[0-9]{10}"
                    maxLength="10"
                    required
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">

                <div>
                  <label className="block text-sm font-semibold mb-2">Date of Birth</label>
                  <input
                    type="date"
                    name="dateOfBirth"
                    value={formData.dateOfBirth}
                    onChange={handleInputChange}
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none"
                    required
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">

                <div className="form-group">
                  <label htmlFor="aadharCard" className="block text-gray-600 font-semibold mb-2">Aadhar Card
                    <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
                  </label>
                  <input
                    type="file"
                    name="aadharCard"
                    id="aadharCard"
                    className="w-full px-3 py-2 border border-gray-300 rounded"
                    onChange={handleFileChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="panCard" className="block text-gray-600 font-semibold mb-2">PAN Card
                    <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
                  </label>
                  <input
                    type="file"
                    name="panCard"
                    id="panCard"
                    className="w-full px-3 py-2 border border-gray-300 rounded"
                    onChange={handleFileChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="electricBill" className="block text-gray-600 font-semibold mb-2">Electric Bill
                    <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
                  </label>
                  <input
                    type="file"
                    name="electricBill"
                    id="electricBill"
                    className="w-full px-3 py-2 border border-gray-300 rounded"
                    onChange={handleFileChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="photo" className="block text-gray-600 font-semibold mb-2">Photo
                    <br /><span className='text-red-600 text-xs'>(Max Size: PNG,JPG,JPEG / 5kb to 50kb)</span>
                  </label>
                  <input
                    type="file"
                    name="photo"
                    id="photo"
                    className="w-full px-3 py-2 border border-gray-300 rounded"
                    onChange={handleFileChange}
                    required
                  />
                </div>
              </div>

              <button
                type="submit"
                className="w-full bg-green-600 font-semibold text-white py-3 mt-5 rounded-md hover:bg-green-700 transition-all duration-200 tracking-wide"
              >
                SUBMIT
              </button>
            </form>
          </div>

          {/* Right side - Content */}
          <div className='bg-slate-200 p-3'>
            <h1 className="text-2xl font-bold mb-4">
              Register Your Trademark & Protect Your Brand Name From Intruders with <span className='text-green-600'>Make Easy</span> <span className='text-orange-600'>Documents</span>
            </h1>
            <p className="mb-4">
              A trademark is any mark, logo, name, symbol, letter, figure, or word used by any individual or firm to uniquely identify its goods or services from those made or sold by others. As a result, clients must be able to differentiate one's goods or services from those of others. The requirements of the Trade Marks Act 1999 regulate trademark registration. Trademark registration is vital because the government legally authorises it to grant the owner exclusive rights to the brand, sale, manufacture, and use of products and services.
            </p>
            <h2 className="text-xl font-semibold mb-2"> What is the difference between ™ and ®?</h2>
            <p>
              When there is a TM on the brand name or logo, it indicates that the trademark application has been filed and the registration is under process. An applicant can use the TM beside his brand name or logo once the application is filed. He can use it until the registration is done. After completion of registration, the TM gets replaced by ® which means the trademark registration is completed and it is valid for 10 years. </p>
          </div>
        </div>
      </div>
      {/* Render Login Modal only if not logged in */}
      {!isLoggedIn && isModalOpen && <LoginModal closeModal={closeModal} />}
      <CustomToastContainer />
      <Footer />
    </div>
  )
}

export default Tredmark
