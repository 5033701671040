import React, { useState } from 'react';
import { motion } from 'framer-motion';

const TransactionHistory = () => {
    const sampleData = [
        { id: 1, name: 'Ramesh Patil', applicationName: 'Pan Card', transactionId: 'TXN123456', amount: '₹1500', date: '19/09/2022', time: '11:00 to 13:00' },
        { id: 2, name: 'Suresh Yadav', applicationName: 'Domicile', transactionId: 'TXN123457', amount: '₹2000', date: '20/09/2022', time: '10:00 to 12:00' },
        { id: 3, name: 'Mahesh Singh', applicationName: 'Votter ID', transactionId: 'TXN123458', amount: '₹1700', date: '21/09/2022', time: '12:00 to 14:00' },
        { id: 4, name: 'Nitesh Joshi', applicationName: 'Shop Act', transactionId: 'TXN123459', amount: '₹2200', date: '22/09/2022', time: '09:00 to 11:00' },
        { id: 5, name: 'Sandeep Shah', applicationName: 'Driving License', transactionId: 'TXN123460', amount: '₹2500', date: '23/09/2022', time: '08:00 to 10:00' },
        { id: 6, name: 'Prakash Patil', applicationName: 'Income Certificate', transactionId: 'TXN123461', amount: '₹1800', date: '24/09/2022', time: '07:00 to 09:00' },
        { id: 7, name: 'Mohit Rathod', applicationName: 'GST registration', transactionId: 'TXN123462', amount: '₹1300', date: '25/09/2022', time: '06:00 to 08:00' },
        { id: 8, name: 'Rahul Mishra', applicationName: 'Kisan Point 7/12 ', transactionId: 'TXN123463', amount: '₹2600', date: '26/09/2022', time: '05:00 to 07:00' },
        { id: 9, name: 'Vikas Nayak', applicationName: 'Legal Advice', transactionId: 'TXN123464', amount: '₹2100', date: '27/09/2022', time: '04:00 to 06:00' },
        { id: 10, name: 'Rohit Deshmukh', applicationName: 'Food License', transactionId: 'TXN123465', amount: '₹3000', date: '28/09/2022', time: '03:00 to 05:00' }
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sampleData.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(sampleData.length / itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="flex-1 overflow-auto relative z-10">
            <div className="mx-5">
                <h2 className="mt-3 mb-3 font-semibold text-3xl text-blue-800 text-center bg-slate-300 p-2 rounded-lg shadow-sm transition-transform duration-300">
                    Transaction History
                </h2>
                <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    className="overflow-x-auto w-full max-w-[20rem] sm:max-w-full"
                >
                    <table className="w-full min-w-[700px] mx-auto text-sm md:text-md lg:text-lg bg-white shadow-md rounded-lg border-collapse">
                        <thead>
                            <tr className="bg-blue-900">
                                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-white">ID</th>
                                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-white">Name</th>
                                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-white">Application Name</th>
                                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-white">Date</th>
                                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-white">Time</th>
                                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-white">Transaction ID</th>
                                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-white">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((transaction, index) => (
                                <tr key={transaction.id} className="bg-white">
                                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">
                                        {index + 1 + (currentPage - 1) * itemsPerPage}
                                    </td>
                                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{transaction.name}</td>
                                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{transaction.applicationName}</td>
                                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{transaction.date}</td>
                                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{transaction.time}</td>
                                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{transaction.transactionId}</td>
                                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{transaction.amount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </motion.div>
                <div className="flex justify-between mt-4">
                    <button
                        onClick={prevPage}
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <span className="text-gray-600">
                        Page {currentPage} of {totalPages}
                    </span>
                    <button
                        onClick={nextPage}
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TransactionHistory;
