import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import "react-toastify/dist/ReactToastify.css";

const User_shiftvoter = () => {
    const { id } = useParams();
    const [licenseData, setLicenseData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [files, setFiles] = useState({});
    const [showDocument, setShowDocument] = useState(null);
    const statusEnum = ["In-Progress", "Submitted", "Rejected", "Completed"];

    useEffect(() => {
        const fetchLicenseData = async () => {
            try {
                const response = await axios.get(
                    `/api/shiftVoterID/getShiftVoterID/${id}`
                );
                console.log(response);
                setLicenseData(response.data);
            } catch (err) {
                setError("Error fetching license data",'error');
            } finally {
                setLoading(false);
            }
        };

        fetchLicenseData();
    }, [id]);

    const handleUpdate = async (event) => {
        event.preventDefault();
        const formData = new FormData();

        // Append all fields from licenseData to formData
        formData.append("fullName", licenseData.fullName);
        formData.append("gender", licenseData.gender);
        formData.append("dateOfBirth", licenseData.dateOfBirth);
        formData.append("mobileNumber", licenseData.mobileNumber);
        formData.append("email", licenseData.email);
        formData.append("birthState", licenseData.birthState);
        formData.append("birthDistrict", licenseData.birthDistrict);
        formData.append("relation", licenseData.relation);
        formData.append("relationName", licenseData.relationName);
        formData.append("relationAddress", licenseData.relationAddress);
        formData.append("relationPincode", licenseData.relationPincode);
        formData.append("livingAddressSince", licenseData.livingAddressSince);
        formData.append("oldVoterIDNumber", licenseData.oldVoterIDNumber);
        formData.append("previousConstituencyState", licenseData.previousConstituencyState);
        formData.append("previousConstituency", licenseData.previousConstituency);
        formData.append("rejectedNote", licenseData.rejectedNote);
        formData.append("submitNote", licenseData.submitNote);
        formData.append("completedNote", licenseData.completedNote);
        formData.append("rejectedAt", licenseData.rejectedAt);
        formData.append("submitedAt", licenseData.submitedAt);
        formData.append("CompletedAt", licenseData.CompletedAt);
        formData.append("Status", licenseData.Status);

        // Append files to formData
        for (const [key, value] of Object.entries(files)) {
            formData.append(`documents.${key}`, value);
        }

        try {
            const response = await axios.put(
                `/api/shiftVoterID/updateShiftVoterID/${id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            showCustomToast(response.data.message);
        } catch (error) {
            showCustomToast("Failed to update license.",'error');
        }
    };

    const renderImage = (imageData) => {
        if (
            imageData &&
            imageData.contentType &&
            imageData.data &&
            imageData.data.data
        ) {
            const byteArray = new Uint8Array(imageData.data.data);
            const base64String = btoa(String.fromCharCode(...byteArray));
            return `data:${imageData.contentType};base64,${base64String}`;
        }
        return null;
    };
    const DocumentPreviewModal = ({ document, onClose }) => {
        if (!document) return null;

        return (
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full">
                    <img
                        src={document}
                        alt=" Make Easy Documents Document"
                        className="max-w-full max-h-[80vh] mx-auto"
                    />
                    <div className="mt-4 flex justify-between">
                        <button
                            className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <a
                            href={document}
                            download
                            className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
                        >
                            Download
                        </a>
                    </div>
                </div>
            </div>
        );
    };

    const DownloadableDocuments = () => {
        console.log("License Data Documents:", licenseData.documents); // Debug log
        if (!licenseData.documents) return null; // Ensure documents is defined

        const { receipt, finisheddoc } = licenseData.documents;

        const status = licenseData.Status;

        return (
            <div className="col-span-3 bg-white shadow-md p-6 rounded-lg mb-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">
                    Downloadable Documents
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {status === "Submitted" && receipt && (
                        <div className="border p-4 rounded-lg text-center bg-gray-50">
                            <h3 className="font-semibold mb-2">Receipt</h3>
                            <button
                                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                                onClick={() => setShowDocument(renderImage(receipt))}
                            >
                                View Receipt
                            </button>
                        </div>
                    )}
                    {status === "Completed" && finisheddoc && (
                        <div className="border p-4 rounded-lg text-center bg-gray-50">
                            <h3 className="font-semibold mb-2">Finished Document</h3>
                            <button
                                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                                onClick={() => setShowDocument(renderImage(finisheddoc))}
                            >
                                View Document
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    if (loading) return <div className="text-center mt-10">Loading...</div>;
    if (error) return <div className="text-center mt-10 text-red-500">{error}</div>;
    if (!licenseData) return <div>No data found</div>;

    const labelClass = "block text-sm mb-2 font-medium text-gray-700";
    const inputClass = "border border-gray-300 rounded-md p-2 w-full";

    return (
        <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
            <div className="max-w-5xl mx-auto">
                <div className="bg-white shadow-xl rounded-lg overflow-hidden">
                    <div className="px-4 py-5 sm:p-6">
                        <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
                            Shift Voter Card
                        </h1>
                        <DownloadableDocuments />
                        <form onSubmit={handleUpdate} className="space-y-6">
                            <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
                                {/* Personal Information Section */}
                                <div className="col-span-3">
                                    <h2 className="text-xl font-semibold text-gray-900 mb-4">
                                        Personal Information
                                    </h2>
                                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
                                        <div>
                                            <label htmlFor="fullName" className={labelClass}>
                                                Full Name
                                            </label>
                                            <input
                                                type="text"
                                                id="fullName"
                                                className={inputClass}
                                                value={licenseData.fullName}
                                                onChange={(e) =>
                                                    setLicenseData({
                                                        ...licenseData,
                                                        fullName: e.target.value,
                                                    })
                                                }
                                                placeholder="Enter full name"
                                                required
                                                disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="gender" className={labelClass}>
                                                Gender
                                            </label>
                                            <select
                                                id="gender"
                                                className={inputClass}
                                                value={licenseData.gender}
                                                onChange={(e) =>
                                                    setLicenseData({
                                                        ...licenseData,
                                                        gender: e.target.value,
                                                    })
                                                }
                                                required
                                                disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                            >
                                                <option value="">Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>

                                        <div>
                                            <label htmlFor="dateOfBirth" className={labelClass}>
                                                Date of Birth
                                            </label>
                                            <input
                                                type="date"
                                                id="dateOfBirth"
                                                className={inputClass}
                                                value={licenseData.dateOfBirth}
                                                onChange={(e) =>
                                                    setLicenseData({
                                                        ...licenseData,
                                                        dateOfBirth: e.target.value,
                                                    })
                                                }
                                                required
                                                disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="mobileNumber" className={labelClass}>
                                                Mobile Number
                                            </label>
                                            <input
                                                type="number"
                                                id="mobileNumber"
                                                className={inputClass}
                                                value={licenseData.mobileNumber}
                                                onChange={(e) =>
                                                    setLicenseData({
                                                        ...licenseData,
                                                        mobileNumber: e.target.value,
                                                    })
                                                }
                                                placeholder="Enter mobile number"
                                                required
                                                disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="email" className={labelClass}>
                                                Email Address
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                className={inputClass}
                                                value={licenseData.email}
                                                onChange={(e) =>
                                                    setLicenseData({
                                                        ...licenseData,
                                                        email: e.target.value,
                                                    })
                                                }
                                                placeholder="Enter email"
                                                required
                                                disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="birthState" className={labelClass}>
                                                Birth State
                                            </label>
                                            <input
                                                type="text"
                                                id="birthState"
                                                className={inputClass}
                                                value={licenseData.birthState}
                                                onChange={(e) =>
                                                    setLicenseData({
                                                        ...licenseData,
                                                        birthState: e.target.value,
                                                    })
                                                }
                                                placeholder="Enter birth state"
                                                required
                                                disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="birthDistrict" className={labelClass}>
                                                Birth District
                                            </label>
                                            <input
                                                type="text"
                                                id="birthDistrict"
                                                className={inputClass}
                                                value={licenseData.birthDistrict}
                                                onChange={(e) =>
                                                    setLicenseData({
                                                        ...licenseData,
                                                        birthDistrict: e.target.value,
                                                    })
                                                }
                                                placeholder="Enter birth district"
                                                required
                                                disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="relation" className={labelClass}>
                                                Relation
                                            </label>
                                            <input
                                                type="text"
                                                id="relation"
                                                className={inputClass}
                                                value={licenseData.relation}
                                                onChange={(e) =>
                                                    setLicenseData({
                                                        ...licenseData,
                                                        relation: e.target.value,
                                                    })
                                                }
                                                placeholder="Enter relation"
                                                required
                                                disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="relationName" className={labelClass}>
                                                Relation Name
                                            </label>
                                            <input
                                                type="text"
                                                id="relationName"
                                                className={inputClass}
                                                value={licenseData.relationName}
                                                onChange={(e) =>
                                                    setLicenseData({
                                                        ...licenseData,
                                                        relationName: e.target.value,
                                                    })
                                                }
                                                placeholder="Enter relation name"
                                                required
                                                disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="relationAddress" className={labelClass}>
                                                Relation Address
                                            </label>
                                            <input
                                                type="text"
                                                id="relationAddress"
                                                className={inputClass}
                                                value={licenseData.relationAddress}
                                                onChange={(e) =>
                                                    setLicenseData({
                                                        ...licenseData,
                                                        relationAddress: e.target.value,
                                                    })
                                                }
                                                placeholder="Enter relation address"
                                                required
                                                disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="relationPincode" className={labelClass}>
                                                Relation Pincode
                                            </label>
                                            <input
                                                type="text"
                                                id="relationPincode"
                                                className={inputClass}
                                                value={licenseData.relationPincode}
                                                onChange={(e) =>
                                                    setLicenseData({
                                                        ...licenseData,
                                                        relationPincode: e.target.value,
                                                    })
                                                }
                                                placeholder="Enter relation pincode"
                                                required
                                                disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="livingAddressSince" className={labelClass}>
                                                Living Address Since
                                            </label>
                                            <input
                                                type="date"
                                                id="livingAddressSince"
                                                className={inputClass}
                                                value={licenseData.livingAddressSince}
                                                onChange={(e) =>
                                                    setLicenseData({
                                                        ...licenseData,
                                                        livingAddressSince: e.target.value,
                                                    })
                                                }
                                                required
                                                disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="oldVoterIDNumber" className={labelClass}>
                                                Old Voter ID Number
                                            </label>
                                            <input
                                                type="text"
                                                id="oldVoterIDNumber"
                                                className={inputClass}
                                                value={licenseData.oldVoterIDNumber}
                                                onChange={(e) =>
                                                    setLicenseData({
                                                        ...licenseData,
                                                        oldVoterIDNumber: e.target.value,
                                                    })
                                                }
                                                placeholder="Enter old Voter ID number"
                                                required
                                                disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="previousConstituencyState" className={labelClass}>
                                                Previous Constituency State
                                            </label>
                                            <input
                                                type="text"
                                                id="previousConstituencyState"
                                                className={inputClass}
                                                value={licenseData.previousConstituencyState}
                                                onChange={(e) =>
                                                    setLicenseData({
                                                        ...licenseData,
                                                        previousConstituencyState: e.target.value,
                                                    })
                                                }
                                                placeholder="Enter previous constituency state"
                                                required
                                                disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="previousConstituency" className={labelClass}>
                                                Previous Constituency
                                            </label>
                                            <input
                                                type="text"
                                                id="previousConstituency"
                                                className={inputClass}
                                                value={licenseData.previousConstituency}
                                                onChange={(e) =>
                                                    setLicenseData({
                                                        ...licenseData,
                                                        previousConstituency: e.target.value,
                                                    })
                                                }
                                                placeholder="Enter previous constituency"
                                                required
                                                disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Document Upload Section */}
                                <div className="col-span-3">
                                    <h2 className="text-xl font-semibold text-gray-900 mb-4">
                                        Documents
                                    </h2>
                                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
                                        {['aadhaarCard', 'voterID', 'addressProof', 'photo'].map((doc) => (
                                            <div key={doc}>
                                                <label htmlFor={doc} className={labelClass}>
                                                    {doc.replace(/([A-Z])/g, ' $1').toUpperCase()}
                                                </label>
                                                <input
                                                    type="file"
                                                    id={doc}
                                                    className={inputClass}
                                                    onChange={(e) => setFiles({ ...files, [doc]: e.target.files[0] })}
                                                    accept=".jpg,.jpeg,.png,.pdf"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {/* Application History */}
                                <div className="col-span-3 bg-green-50 shadow-md p-6 rounded-lg w-full lg:w-full space-y-4">
                                    <h2 className="text-xl text-center font-bold mb-4 text-gray-700 border-b-2 border-gray-400 pb-2">
                                        Application History
                                    </h2>

                                    <div className="flex justify-between text-gray-800">
                                        <strong className="text-gray-600 flex-1">Created At (Date & Time):</strong>
                                        <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                                            {new Date(licenseData.createdAt).toLocaleString('en-IN', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                                hour12: true,
                                            })}
                                        </span>
                                    </div>

                                    <div className="flex justify-between text-gray-800">
                                        <strong className="text-gray-600 flex-1">Updated At (Date & Time):</strong>
                                        <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                                            {licenseData.updatedAt
                                                ? new Date(licenseData.updatedAt).toLocaleString('en-IN', {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    second: '2-digit',
                                                    hour12: true,
                                                })
                                                : 'N/A'}
                                        </span>
                                    </div>

                                    <div className="flex justify-between text-gray-800">
                                        <strong className="text-gray-600 flex-1">Current Status:</strong>
                                        <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                                            {licenseData.Status ? licenseData.Status : 'N/A'}
                                        </span>
                                    </div>

                                    {/* Rejected Note */}
                                    {licenseData.Status === "Rejected" && (
                                        <div>
                                            <label htmlFor="rejectedNote" className={labelClass}>
                                                Rejected Note
                                            </label>
                                            <input
                                                disabled
                                                type="text"
                                                id="rejectedNote"
                                                className={inputClass}
                                                value={licenseData.rejectedNote}
                                                required
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="col-span-3">
                                    <button
                                        type="submit"
                                        className="w-full rounded-md bg-indigo-600 py-2 px-4 text-white font-semibold shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form>
                        <DocumentPreviewModal
                            document={showDocument}
                            onClose={() => setShowDocument(null)}
                        />

                        <CustomToastContainer />
                    </div>
                </div>
            </div >
        </div >
    );
};

export default User_shiftvoter;
