import React, { useState, useContext, useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import logo from "../../assets/logo.png";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc"; // Import Google icon

const Sign_in = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();

  // Initialize Google Sign-In
  useEffect(() => {
    // Load Google API Script
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      
      return () => {
        document.body.removeChild(script);
      };
    };

    loadGoogleScript();
  }, []);

  // Handle Google Sign-In Success
  const handleGoogleSuccess = async (response) => {
    try {
      const res = await fetch('/api/auth/google', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          credential: response.credential,
        }),
      });

      const data = await res.json();

      if (data.success) {
        // Extract user ID from token
        const tokenParts = data.token.split('.');
        const payload = JSON.parse(atob(tokenParts[1]));
        const userId = payload._id;

        const userWithId = {
          ...data.user,
          _id: userId,
        };

        // Update auth context and localStorage
        setAuth({
          user: userWithId,
          token: data.token,
        });
        localStorage.setItem('auth', JSON.stringify({ user: userWithId, token: data.token }));

        // Redirect based on role
        if (data.user.role === 1) {
          navigate('/admin/adashboard');
        } else {
          navigate('/dashboard/allServices');
        }

        showCustomToast('Logged in successfully!', 'success');
      } else {
        showCustomToast(data.message || 'Google login failed', 'error');
      }
    } catch (error) {
      console.error('Google login error:', error);
      showCustomToast('Something went wrong with Google login!', 'error');
    }
  };

  // Handle regular form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Regular sign-in handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/api/auth/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (data.error) {
        showCustomToast(data.error);
      } else {
        const tokenParts = data.token.split('.');
        if (tokenParts.length !== 3) {
          throw new Error('Invalid token format');
        }

        const payload = JSON.parse(atob(tokenParts[1]));
        const userId = payload._id;

        const userWithId = {
          ...data.user,
          _id: userId,
        };

        setAuth({
          user: userWithId,
          token: data.token,
        });
        localStorage.setItem('auth', JSON.stringify({ user: userWithId, token: data.token }));

        if (data.user.role === 1) {
          navigate('/admin/adashboard');
        } else {
          navigate('/dashboard/allServices');
        }

        showCustomToast('Logged in successfully!', 'success');
      }
    } catch (err) {
      console.error(err);
      showCustomToast('Something went wrong!', 'error');
    }
  };

  return (
    <div>
      <Header />
      <br /> <br /> <br />
      <div className="flex w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-2xl dark:bg-gray-800 lg:max-w-4xl">
        <div
          className="hidden bg-cover lg:block lg:w-1/2"
          style={{ backgroundImage: "url('https://images.unsplash.com/photo-1606660265514-358ebbadc80d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1575&q=80')" }}
        ></div>
        <div className="w-full px-6 py-8 md:px-8 lg:w-1/2">
          <div className="flex justify-center mx-auto">
            <img className="h-20 w-30 mr-3" src={logo} alt=" Make Easy Documents Logo" />
          </div>
          <p className="mt-3 text-xl text-center text-gray-600 dark:text-gray-200">
            Welcome back!
          </p>
          
          {/* Google Sign-In Button */}
          <div className="mt-4">
            <div id="g_id_onload"
                data-client_id="YOUR_GOOGLE_CLIENT_ID"
                data-context="signin"
                data-callback="handleGoogleSuccess"
                data-auto_select="false"
                data-itp_support="true">
            </div>
            
            <div className="g_id_signin"
                data-type="standard"
                data-size="large"
                data-theme="outline"
                data-text="sign_in_with"
                data-shape="rectangular"
                data-logo_alignment="left">
            </div>
          </div>

          <div className="flex items-center justify-between mt-4">
            <span className="w-1/5 border-b dark:border-gray-600 md:w-1/4"></span>
            <span className="text-xs text-gray-500 uppercase dark:text-gray-400">or sign in with email</span>
            <span className="w-1/5 border-b dark:border-gray-600 md:w-1/4"></span>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="mt-4">
              <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" htmlFor="email">Email Address</label>
              <input
                id="email"
                name="email"
                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                type="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <div className="mt-4">
              <div className="flex justify-between">
                <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" htmlFor="password">
                  Password
                </label>
                <Link to="/forgot_password" className="text-xs text-gray-500 dark:text-gray-300 hover:underline">
                  Forgot Password?
                </Link>
              </div>
              <div className="relative">
                <input
                  id="password"
                  name="password"
                  className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="absolute right-2 top-2 text-gray-600 dark:text-gray-300"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                </button>
              </div>
            </div>

            <div className="mt-6">
              <button type="submit" className="w-full px-6 py-3 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-gray-800 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-50">
                Sign In
              </button>
            </div>
          </form>

          <div className="flex items-center justify-between mt-4">
            <span className="w-1/5 border-b dark:border-gray-600 md:w-1/4"></span>
            <Link to="/sign_up" className="text-xs text-gray-500 uppercase dark:text-gray-400 hover:underline">or sign up</Link>
            <span className="w-1/5 border-b dark:border-gray-600 md:w-1/4"></span>
          </div>
        </div>
      </div>
      <br /> <br /> <br />
      <CustomToastContainer />
      <Footer />
    </div>
  );
};

export default Sign_in;