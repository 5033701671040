import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { showCustomToast, CustomToastContainer } from "../../components/CustomToast";
import "react-toastify/dist/ReactToastify.css";

const User_centralfoodlicense = () => {
  const { id } = useParams();
  const [licenseData, setLicenseData] = useState({
    fullName: "",
    businessName: "",
    natureOfBusiness: "",
    ownerQualification: "",
    email: "",
    mobileNumber: "",
    businessAddress: "",
    licenseRequireYears: "",
    formPrice: "",
    application_type: "",
    submitNote: "",
    completedNote: "",
    Status: "In-Progress",
    rejectedNote: "",
  });
  const [showDocument, setShowDocument] = useState(null);
  const [documents, setDocuments] = useState({
    aadharCard: null,
    panCard: null,
    photo: null,
    electricBill: null,
    rentAggrement: null,
    shopActLicense: null,
    uddyamAadhar: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const statusEnum = ["In-Progress", "Submitted", "Rejected", "Completed"];

  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `/api/centralFoodLicense/getCentralFoodLicense/${id}`
        );
        const data = response.data;
        setLicenseData({
          fullName: data.fullName || "",
          businessName: data.businessName || "",
          natureOfBusiness: data.natureOfBusiness || "",
          ownerQualification: data.ownerQualification || "",
          email: data.email || "",
          mobileNumber: data.mobileNumber || "",
          businessAddress: data.businessAddress || "",
          licenseRequireYears: data.licenseRequireYears || "",
          formPrice: data.formPrice || "",
          application_type: data.application_type || "",
          submitNote: data.submitNote || "",
          completedNote: data.completedNote || "",
          Status: data.Status || "In-Progress",
          rejectedNote: data.rejectedNote || "",
          createdAt: data.createdAt || "",
          updatedAt: data.updatedAt || "",
          documents: data.documents || {}, // Assign documents properly here

        });
        console.log('API Response:', response.data); // Log the entire response

      } catch (err) {
        setError("Error fetching license data",'error');
      } finally {
        setLoading(false);
      }
    };
    fetchLicenseData();
  }, [id]);

  const handleUpdate = async (event) => {
    event.preventDefault();
  
    // Set the Status to "In-Progress" before making the API call
    setLicenseData((prevData) => ({ ...prevData, Status: "In-Progress" }));
    
    const formData = new FormData();
    Object.keys(licenseData).forEach((key) => {
      if (key !== "documents") {
        formData.append(key, key === "Status" ? "In-Progress" : licenseData[key]); // Set Status to "In-Progress"
      }
    });
  
    // Add documents
    Object.keys(documents).forEach((key) => {
      if (documents[key]) {
        formData.append(`documents.${key}`, documents[key]);
      }
    });
  
    try {
      const response = await axios.put(
        `/api/centralFoodLicense/updatecentralfoodlicense/${id}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
  
      showCustomToast(response.data.message);
      // Optionally update Status to "In-Progress" here as well to reflect it instantly
      setLicenseData((prevData) => ({ ...prevData, Status: "In-Progress" }));
    } catch (error) {
      showCustomToast("Failed to update license.", 'error');
    }
  };
  

  const renderImage = (imageData) => {
    if (
      imageData &&
      imageData.contentType &&
      imageData.data &&
      imageData.data.data
    ) {
      const byteArray = new Uint8Array(imageData.data.data);
      const base64String = btoa(String.fromCharCode(...byteArray));
      return `data:${imageData.contentType};base64,${base64String}`;
    }
    return null;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLicenseData({ ...licenseData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      setDocuments({ ...documents, [name]: files[0] });
    }
  };

  const DocumentPreviewModal = ({ document, onClose }) => {
    if (!document) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full">
          <img
            src={document}
            alt="Document"
            className="max-w-full max-h-[80vh] mx-auto"
          />
          <div className="mt-4 flex justify-between">
            <button
              className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
              onClick={onClose}
            >
              Close
            </button>
            <a
              href={document}
              download
              className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
            >
              Download
            </a>
          </div>
        </div>
      </div>
    );
  };

  const DownloadableDocuments = () => {
    console.log('License Data Documents:', licenseData.documents); // Debug log
    if (!licenseData.documents) return null; // Ensure documents is defined
  
    const { receipt, finisheddoc } = licenseData.documents;
  
    const status = licenseData.Status;
  
    return (
      <div className="col-span-3 bg-white shadow-md p-6 rounded-lg mb-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Downloadable Documents
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {status === "Submitted" && receipt && (
            <div className="border p-4 rounded-lg text-center bg-gray-50">
              <h3 className="font-semibold mb-2">Receipt</h3>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                onClick={() => setShowDocument(renderImage(receipt))}
              >
                View Receipt
              </button>
            </div>
          )}
          {status === "Completed" && finisheddoc && (
            <div className="border p-4 rounded-lg text-center bg-gray-50">
              <h3 className="font-semibold mb-2">Finished Document</h3>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                onClick={() => setShowDocument(renderImage(finisheddoc))}
              >
                View Document
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };
  
  

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error) return <div className="text-center mt-10 text-red-500">{error}</div>;

  const labelClass = "block text-sm mb-2 font-medium text-gray-700";
  const inputClass = "border border-gray-300 rounded-md p-2 w-full";


  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
              Central Food License
            </h1>
            <DownloadableDocuments />
            <form onSubmit={handleUpdate} className="space-y-6" >
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {/* Full Name */}
                <div className="relative">
                  <label className={labelClass} htmlFor="fullName">
                    Full Name
                  </label>
                  <input
                    id="fullName"
                    name="fullName"
                    type="text"
                    className={`border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                    value={licenseData.fullName}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                {/* Business Name */}
                <div className="relative">
                  <label className={labelClass} htmlFor="businessName">
                    Business Name
                  </label>
                  <input
                    id="businessName"
                    name="businessName"
                    type="text"
                    className={`border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                    value={licenseData.businessName}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                {/* Nature of Business */}
                <div className="relative">
                  <label className={labelClass} htmlFor="natureOfBusiness">
                    Nature of Business
                  </label>
                  <input
                    id="natureOfBusiness"
                    name="natureOfBusiness"
                    type="text"
                    className={`border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                    value={licenseData.natureOfBusiness}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                {/* Owner Qualification */}
                <div className="relative">
                  <label className={labelClass} htmlFor="ownerQualification">
                    Owner Qualification
                  </label>
                  <input
                    id="ownerQualification"
                    name="ownerQualification"
                    type="text"
                    className={`border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                    value={licenseData.ownerQualification}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                {/* Mobile Number */}
                <div className="relative">
                  <label className={labelClass} htmlFor="mobileNumber">
                    Mobile Number
                  </label>
                  <input
                    id="mobileNumber"
                    name="mobileNumber"
                    type="text"
                    className={`border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                    value={licenseData.mobileNumber}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                {/* Business Address */}
                <div className="relative">
                  <label className={labelClass} htmlFor="businessAddress">
                    Business Address
                  </label>
                  <input
                    id="businessAddress"
                    name="businessAddress"
                    type="text"
                    className={`border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                    value={licenseData.businessAddress}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                {/* License Require Years */}
                <div className="relative">
                  <label className={labelClass} htmlFor="licenseRequireYears">
                    License Require Years
                  </label>
                  <input
                    id="licenseRequireYears"
                    name="licenseRequireYears"
                    type="text"
                    className={`border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                    value={licenseData.licenseRequireYears}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="md:col-span-3"> {/* Make this span across all columns */}
                  <h2 className="text-green-600 font-semibold text-center text-2xl">
                    - Documents Upload -
                  </h2>
                </div>

                {/* File Uploads */}
                {['aadharCard', 'panCard', 'photo', 'electricBill', 'rentAggrement', 'shopActLicense', 'uddhyamAdhar', 'signature'].map((field, index) => (
                  <div className="relative" key={index}>
                    <label className={labelClass} htmlFor={field}>
                      {field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                    </label>
                    <input
                      id={field}
                      name={field}
                      type="file"
                      className={`border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                      accept="image/*"
                      onChange={handleFileChange}
                      disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                    />
                  </div>
                ))}
              </div>


              {/* Application History */}
              <div className="bg-green-50 shadow-md p-6 rounded-lg w-full lg:w-full space-y-4">
                <h2 className="text-xl text-center font-bold mb-4 text-gray-700 border-b-2 border-gray-400 pb-2">
                  Application History
                </h2>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">Created At (Date & Time):</strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {new Date(licenseData.createdAt).toLocaleString('en-IN', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                      hour12: true,
                    })}
                  </span>
                </div>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">Updated At (Date & Time):</strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {licenseData.updatedAt
                      ? new Date(licenseData.updatedAt).toLocaleString('en-IN', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: true,
                      })
                      : 'N/A'}
                  </span>
                </div>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">Current Status:</strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {licenseData.Status ? licenseData.Status : 'N/A'}
                  </span>
                </div>

                {/* Rejected Note */}
                {licenseData.Status === "Rejected" && (
                  <div className="relative">
                    <label className={labelClass} htmlFor="rejectedNote">
                      Rejected Note
                    </label>
                    <textarea
                      disabled
                      id="rejectedNote"
                      name="rejectedNote"
                      className={`border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                      value={licenseData.rejectedNote}
                      onChange={handleInputChange}
                    />
                  </div>
                )}
              </div>

              {/* Submit Button */}
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 transition duration-200"
                >
                  Update License
                </button>
              </div>
            </form>
            <DocumentPreviewModal
              document={showDocument}
              onClose={() => setShowDocument(null)}
            />
            <CustomToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default User_centralfoodlicense;
