import React from 'react';
import { toast, ToastContainer } from 'react-toastify';

// SVG for Success Tick
const AnimatedTick = () => (
    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
);

// SVG for Error Cross
const AnimatedCross = () => (
    <svg className="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle className="crossmark__circle" cx="26" cy="26" r="25" fill="none" />
        <path className="crossmark__cross" fill="none" d="M16 16 L36 36 M36 16 L16 36" />
    </svg>
);

const CustomToast = ({ message, type }) => (
    <>
        <div className="w-full">
            {/* Conditionally render tick or cross based on type */}
            {type === 'success' ? <AnimatedTick /> : <AnimatedCross />}
        </div>

        <div className="items-center w-full">
            <div className="w-full">
                <p className="font-bold text-red-600 text-center w-full">
                    <span className='text-green-600'>Make Easy</span> Documents
                </p>
                <p className="text-center w-full">{message}</p>
            </div>
        </div>
    </>
);

export const showCustomToast = (message, type = 'success') => {
    toast[type](<CustomToast message={message} type={type} />, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: false, // Disable default icon
    });
};

export const CustomToastContainer = () => (
    <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999, // Ensure it's above other content
        }}
    />
);

export default CustomToast;
