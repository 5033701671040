import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IoArrowBackCircle } from 'react-icons/io5';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useAuth } from '../../context/auth'; // Adjust the path as needed
import axios from 'axios';
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import LoginModal from '../../components/LoginModel'; // Import the modal
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { AiOutlineAppstore } from 'react-icons/ai';

const Ration_card = () => {
    const navigate = useNavigate(); // Initialize useNavigate

    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
    const [auth] = useAuth(); // Access auth state from context
    const isLoggedIn = Boolean(auth.token); // Determine if user is logged in based on token
    const [formPrices, setFormPrices] = useState({}); // State for form prices
    const [selectedPrice, setSelectedPrice] = useState(0); // State for selected price

    useEffect(() => {
        // Show the modal if the user is not logged in
        if (!isLoggedIn) {
            setIsModalOpen(true); // Show the modal if not logged in
        }
    }, [isLoggedIn]); // Run effect whenever isLoggedIn changes

    const closeModal = () => {
        setIsModalOpen(false); // Function to close modal
    };

    useEffect(() => {
        // Fetch form prices from the backend
        const fetchFormPrices = async () => {
            try {
                const response = await axios.get('/api/formprices/getallformprice');
                const prices = response.data.reduce((acc, formPrice) => {
                    acc[formPrice.formType] = formPrice.price;
                    return acc;
                }, {});
                setFormPrices(prices);
                setSelectedPrice(prices['rationCard'] || 0);  // Default price for foodLicenseYear1
            } catch (error) {
                console.error("Error fetching form prices:", error);
                showCustomToast('Error fetching form prices.', 'error');
            }
        };

        fetchFormPrices();
    }, []);

    const [formData, setFormData] = useState({

        application_type: "rationCard",
        formPrice: "",
        fullNameEnglish: "",
        fullNameMarathi: "",
        fatherFullNameEnglish: "",
        fatherFullNameMarathi: "",
        incomeCertificate: "",
        dateOfBirth: "",
        age: "",
        gender: "",
        mobileNumber: "",
        service: "",
        address: "",
        state: "",
        district: "",
        pincode: "",
        sourceOfIncome: "",
        purposeOfIncomeCertificate: "",

        rationCard: null,
        aadharCard: null,
        photoIdentity: null,
        signature: null,
        talathiIncome: null,

    });

    const [transactionID, setTransactionID] = useState(null);



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];

        // Define validation criteria based on file name
        const isPhotoOrSignature = name === 'photoIdentity' || name === 'signature' || name === 'photo';
        const minSize = isPhotoOrSignature ? 5 * 1024 : 75 * 1024; // 5 KB or 75 KB
        const maxSize = isPhotoOrSignature ? 50 * 1024 : 1 * 1024 * 1024; // 50 KB or 1 MB
        const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const validDocumentTypes = ['application/pdf', 'image/jpeg', 'image/pdf'];

        // Check file validity
        if (file) {
            const { size, type } = file;

            // Check size and type based on the input name
            if (isPhotoOrSignature) {
                // Validate photo and signature files
                if (size < minSize || size > maxSize) {
                    showCustomToast('Document must be between 5 KB and 50 KB.', 'error');
                    // Clear the file input
                    e.target.value = null;
                    return; // Exit if the file size is invalid
                }
                if (!validImageTypes.includes(type)) {
                    showCustomToast('Document must be a JPG, JPEG, or PNG file.', 'error');
                    // Clear the file input
                    e.target.value = null;
                    return; // Exit if the file type is invalid
                }
            } else {
                // Validate other documents
                if (size < minSize || size > maxSize) {
                    showCustomToast('Document must be between 75 KB and 1 MB.', 'error');
                    // Clear the file input
                    e.target.value = null;
                    return; // Exit if the file size is invalid
                }
                if (!validDocumentTypes.includes(type)) {
                    showCustomToast('Document must be a PDF, JPG, or JPEG file.', 'error');
                    // Clear the file input
                    e.target.value = null;
                    return; // Exit if the file type is invalid
                }
            }

            // If validation passes, update the formData
            setFormData({ ...formData, [name]: file });
        }
    };

    const generateTransactionID = () => {
        const timeStamp = Date.now();
        const randomNum = Math.floor(Math.random() * 1000000);
        const merchantPrefix = "T";
        const transactionID = `${merchantPrefix}${timeStamp}${randomNum} `;
        return setTransactionID(transactionID);
      };
    
    
      useEffect(()=> {
        generateTransactionID()
      })

    

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if user is authenticated
        if (!auth.token) {
            showCustomToast('Please login to submit the form.', 'error');
            return;
        }

        // Create FormData object
        const form = new FormData();
        for (const key in formData) {
            if (formData[key] !== null) {
                form.append(
                    key.startsWith('aadharCard') ? 'documents.aadharCard' :
                        key.startsWith('rationCard') ? 'documents.rationCard' :
                            key.startsWith('talathiIncome') ? 'documents.talathiIncome' :
                                key.startsWith('photoIdentity') ? 'documents.photoIdentity' :
                                key.startsWith('signature') ? 'documents.signature' :
                                    key,
                    formData[key]
                );
            }
        }


        try {
            // Post form data to API
            const response = await axios.post(
                '/api/rationcard/createrationcard',
                form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${auth.token}`
                    }
                }
            );

            // Proceed with the PayU payment logic after form submission
    const payuResponse = await axios.post("/api/payu/hash", {
        fullName: formData.fullName,
        email: formData.email,
        amount: selectedPrice,
        transactionID: transactionID,
        udf1: "detail 1",
        udf2: "detail 2",
        udf3: "",
        udf4: "",
        udf5: ""
      });
  
      const { hash } = payuResponse.data;
  
      // Redirect to PayU
      const payuForm = document.createElement("form");
      payuForm.action = "https://secure.payu.in/_payment";
      payuForm.method = "POST";
  
      const fields = {
        key: 'nC1EmY',
        txnid: transactionID,
        amount: selectedPrice,
        productinfo: "central food license",
        firstname: formData.fullName,
        email: formData.email,
        phone: formData.mobileNumber,
        surl: '/api/payu/success', // Success URL after payment
        furl: "/api/payu/failure",
        hash: hash,
        udf1: "detail 1",
        udf2: "detail 2",
        udf3: "",
        udf4: "",
        udf5: ""
      };
  
      for (const key in fields) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = fields[key];
        payuForm.appendChild(input);
      }
  
      document.body.appendChild(payuForm);
      payuForm.submit();

            
        } catch (error) {
            // Notify user of error
            showCustomToast('Error submitting Ration Card.', 'error');
            console.error('Error:', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div>
            <Header />
            <div className="page-title py-6 bg-slate-300" data-aos="fade">
                <div className="container mx-auto px-4 lg:px-20 flex flex-col lg:flex-row justify-between items-start lg:items-center">
                    <h1 className="text-black text-xl md:text-2xl font-semibold">Ration Card</h1>
                    <h1
                        className="text-lg md:text-xl font-bold underline underline-offset-8 mb-2 lg:mb-0 lg:ml-4 animate-[intenseBlink_1s_ease-in-out_infinite]"
                    >
                        100% Fees Refundable, if Service is not Completed!
                    </h1>
                    <nav className="breadcrumbs">
                        <ol className="flex space-x-4 text-sm">
                            <li><Link to={'/'} className="text-black hover:underline hover:text-black text-base">Home</Link></li>
                            <li className="text-black">/</li>
                            <li className="flex items-center">
                                <AiOutlineAppstore className="mr-1 text-base" />
                                <Link to={'/all_service'} className="text-black hover:underline text-base">All Services</Link>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div>
                <h2 className="text-green-600 text-center font-semibold text-2xl pt-8 sm:mt-0">- Ration Card -</h2>
            </div>
            <form
                className="max-w-6xl mx-auto bg-white shadow-2xl rounded-md p-6 mt-10 mb-10"
                onSubmit={handleSubmit}
            >
                <br />
                <div className="flex flex-col sm:flex-row sm:justify-between mt-10 pb-6">
                    <h2 className="text-green-600 font-semibold text-xl text-center sm:text-left">
                        Service Charge : <span className='text-red-600' id='formprice'> ₹ {selectedPrice}</span>
                    </h2>
                </div>
                <br />
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {/* Form Fields */}
                    <div className="form-group">
                        <label htmlFor="fullNameEnglish" className="block text-gray-600 font-semibold mb-2">Full Name (English)</label>
                        <input
                            type="text"
                            name="fullNameEnglish"
                            id="fullNameEnglish"
                            placeholder=" - - - "
                            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                            value={formData.fullNameEnglish}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="fullNameMarathi" className="block text-gray-600 font-semibold mb-2">Full Name (Marathi)</label>
                        <input
                            type="text"
                            name="fullNameMarathi"
                            id="fullNameMarathi"
                            placeholder=" - - - "
                            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                            value={formData.fullNameMarathi}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="fatherFullNameEnglish" className="block text-gray-600 font-semibold mb-2">Father's Full Name (English)</label>
                        <input
                            type="text"
                            name="fatherFullNameEnglish"
                            id="fatherFullNameEnglish"
                            placeholder=" - - - "
                            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                            value={formData.fatherFullNameEnglish}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="fatherFullNameMarathi" className="block text-gray-600 font-semibold mb-2">Father's Full Name (Marathi)</label>
                        <input
                            type="text"
                            name="fatherFullNameMarathi"
                            id="fatherFullNameMarathi"
                            placeholder=" - - - "
                            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                            value={formData.fatherFullNameMarathi}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="incomeCertificate" className="block text-gray-600 font-semibold mb-2">Income Certificate</label>
                        <input
                            type="text"
                            name="incomeCertificate"
                            id="incomeCertificate"
                            placeholder=" - - - "
                            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                            value={formData.incomeCertificate}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="dateOfBirth" className="block text-gray-600 font-semibold mb-2">Date of Birth</label>
                        <input
                            type="date"
                            name="dateOfBirth"
                            id="dateOfBirth"
                            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                            value={formData.dateOfBirth}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="age" className="block text-gray-600 font-semibold mb-2">Age</label>
                        <input
                            type="number"
                            name="age"
                            id="age"
                            placeholder=" - - - "
                            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                            value={formData.age}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="gender" className="block text-gray-600 font-semibold mb-2">Gender</label>
                        <select
                            name="gender"
                            id="gender"
                            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                            value={formData.gender}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="mobileNumber" className="block text-gray-600 font-semibold mb-2">Mobile Number</label>
                        <input
                            type="text"
                            name="mobileNumber"
                            id="mobileNumber"
                            placeholder=" - - - "
                            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                            value={formData.mobileNumber}
                            onChange={handleInputChange}
                            pattern="[0-9]{10}"
                            maxLength="10"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="service" className="block text-gray-600 font-semibold mb-2">Service</label>
                        <input
                            type="text"
                            name="service"
                            id="service"
                            placeholder=" - - - "
                            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                            value={formData.service}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    {/* Address */}
                    <div className="form-group">
                        <label htmlFor="address" className="block text-gray-600 font-semibold mb-2">Address</label>
                        <input
                            type="text"
                            name="address"
                            id="address"
                            placeholder="Enter your address"
                            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                            value={formData.address}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    {/* State */}
                    <div className="form-group">
                        <label htmlFor="state" className="block text-gray-600 font-semibold mb-2">State</label>
                        <input
                            type="text"
                            name="state"
                            id="state"
                            placeholder="Enter your state"
                            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                            value={formData.state}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    {/* District */}
                    <div className="form-group">
                        <label htmlFor="district" className="block text-gray-600 font-semibold mb-2">District</label>
                        <input
                            type="text"
                            name="district"
                            id="district"
                            placeholder="Enter your district"
                            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                            value={formData.district}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    {/* Pincode */}
                    <div className="form-group">
                        <label htmlFor="pincode" className="block text-gray-600 font-semibold mb-2">Pincode</label>
                        <input
                            type="text"
                            name="pincode"
                            id="pincode"
                            placeholder="Enter pincode"
                            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                            value={formData.pincode}
                            onChange={handleInputChange}
                            pattern="\d{6}" // Restrict to exactly 6 digits
                            maxLength="6"
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
                            }}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="sourceOfIncome" className="block text-gray-600 font-semibold mb-2">Source of Income</label>
                        <input
                            type="text"
                            name="sourceOfIncome"
                            id="sourceOfIncome"
                            placeholder=" - - - "
                            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                            value={formData.sourceOfIncome}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="purposeOfIncomeCertificate" className="block text-gray-600 font-semibold mb-2">Purpose of Income Certificate</label>
                        <input
                            type="text"
                            name="purposeOfIncomeCertificate"
                            id="purposeOfIncomeCertificate"
                            placeholder=" - - - "
                            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                            value={formData.purposeOfIncomeCertificate}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>
                <div className="mt-10 text-center">
                    <h2 className="text-green-600 font-semibold text-2xl">
                        - Upload Required Documents -
                    </h2>
                </div> <br />
                {/* File Uploads */}
                <div className="mt-6 mx-auto max-w-4xl">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="mb-6">
                            <label className="block text-gray-600 font-semibold mb-2">Ration Card
                            <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
                            </label>
                            <input
                                type="file"
                                name="rationCard"
                                onChange={handleFileChange}
                                className="w-full py-2 px-3 border border-gray-300 rounded focus:outline-none"
                            />
                        </div>

                        <div className="mb-6">
                            <label className="block text-gray-600 font-semibold mb-2">Aadhar Card 
                            <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
                            </label>
                            <input
                                type="file"
                                name="aadharCard"
                                onChange={handleFileChange}
                                className="w-full py-2 px-3 border border-gray-300 rounded focus:outline-none"
                            />
                        </div>

                        <div className="mb-6">
                            <label className="block text-gray-600 font-semibold mb-2">Photo Identity
                            <br /><span className='text-red-600 text-xs'>(Max Size: PNG,JPG,JPEG / 5kb to 50kb)</span>
                            </label>
                            <input
                                type="file"
                                name="photoIdentity"
                                onChange={handleFileChange}
                                className="w-full py-2 px-3 border border-gray-300 rounded focus:outline-none"
                            />
                        </div>

                        <div className="mb-6">
                            <label className="block text-gray-600 font-semibold mb-2">Signature <br /><span className='text-red-600 text-xs'>(Max Size: PNG,JPG,JPEG / 5kb to 50kb)</span></label>
                            <input
                                type="file"
                                name="signature"
                                onChange={handleFileChange}
                                className="w-full py-2 px-3 border border-gray-300 rounded focus:outline-none"
                            />
                        </div>

                        <div className="mb-6">
                            <label className="block text-gray-600 font-semibold mb-2">Talathi Income
                            <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
                            </label>
                            <input
                                type="file"
                                name="talathiIncome"
                                onChange={handleFileChange}
                                className="w-full py-2 px-3 border border-gray-300 rounded focus:outline-none"
                            />
                        </div>
                    </div>
                </div>

                {/* Submit Button */}
                <div className="text-center mt-6">
                    <button
                        type="submit"
                        className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 focus:outline-none"
                    >
                        Submit
                    </button>
                </div>
            </form>
            {/* Render Login Modal only if not logged in */}
            {!isLoggedIn && isModalOpen && <LoginModal closeModal={closeModal} />}
            <CustomToastContainer />
            <Footer />
        </div>
    );
};

export default Ration_card
