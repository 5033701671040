import React from 'react'
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 bg-white shadow-md rounded-md my-6">
        {/* <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 pt-3">Refund and Cancellation Policy</h1> */}
        {/* <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" /> */}
        <p className="text-gray-600 text-base sm:text-base leading-relaxed ">
          The use of the terms “We” / “Us” / “Our”/ “Company” relates to Viransh Globle legal private Limited, whereas the terms “You” / “Your” / “Yourself” primary focuses on the Users. This Privacy Policy is an electronic record in the form of an electronic contract created by the Information Technology Act of 2000, and rules made there under for the time being in force with respect to electronic documents, records and advantages amended by the Information Technology Act of 2000. This Privacy Policy does not need any paper based signed signature or electronic or digital signature. This Privacy Policy is an agreement between you and the company Viransh Globle legal Private Limited. The terms of this Policy shall be effective immediately upon your acceptance of the same (whether electronically, by clicking on the I accept tab or otherwise using the website or any other means). It shall govern the relations - understand the relations intended by this Policy - between you Viransh Globle legal Private Limited and excess of the website “Website” (as defined below) you will use. This document is published and shall be interpreted in compliance with the Information Technology (reasonable security practices and procedures and sensitive personal data of information) rules, 2011 under Information Technology Act, 2000; which mandates publication of the Privacy Policy regarding collection, usage, storage and transfer of sensitive personal information. This Privacy Policy shall be read as the part of the website and in any way the Privacy Policy shall be deemed to the Users acceptance of the website. If you do not agree with the terms of this Privacy Policy, please do not use this Website. You give us your Information and/or use the services/ facilities of the Website, then You shall be deemed to have agreed and given your consent to the collection, storage, processing and transfer of Information in the manner provided herein.
        </p>

        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 pt-3">USER INFORMATION</h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-base leading-relaxed ">
          For certain services available on our Websites, Users are expected to provide specific details during the registration process which includes: a) Full Name, b) Email ID, c) Gender, d) Date of Birth, e) Zip Code, f) Details of any credit card or debit card, g) Any medical records and history, h) Orgasmic Preference, i) Finger Prints, j) Secret Codes etc., related to one’s vocation, hobbies and the like. The details as provided by the individuals help us enhance our websites and offer you a better more friendly service. All compulsory information is dependent on the service requested and this information may be used by us and/or incurred to, maintain, safeguard and enhance its services (including the advertising services) and also for new service creation purposes. Such information shall not be classified as sensitive in nature if the same is in the public domain and freely accessible or provided in accordance with the Right to Information Act, 2005 or any other statute in force. The visitor, who is showing interest in our service by completing the service form, agrees to be contacted by any means of communication available, SMS or email or phone calls.
        </p>

        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 pt-3">COOKIES</h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-base leading-relaxed ">
          In order to enhance the responsiveness of the sites for our users, we might employ “cookies,” or equivalent electronic devices for the purposes of collecting data which will help in the provision of a unique random number to each visitor which will be User Identification also known as User ID and help in recognition of user’s specific preferences using an Identified Computer. If you do not self-identify or otherwise voluntarily provide us with any information (such as through membership registration), we will not know who you are, even if we place a cookie on a particular machine. The only personal data that may be stored within a cookie is the data filled in by the user (for instance, when filling in the form of Request for Personalized Horoscope). There is no way, a cookie can extract any information from your hard disk. Also, as a result of their advertisements, our advertisement partners may drop their own cookies to your browser (in case you responded to their ads), which is beyond our administration.
          <br /> Any time you go to our website, our web server captures some basic details regarding your computer's Internet connectivity, including the IP address. (IP addresses simply allow connecting computers on the internet to send information such as web pages to the user requesting them.) We do not identify you by your ip address. This data is utilized to send the requested web pages to you, to customize the site’s content according to users’ preferences, to analyze the users’ flow on the site, and to inform advertisers about the regions from which the site visitors come.
        </p>

        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 pt-3">LINKS TO THE OTHER SITES</h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-base leading-relaxed ">
          Our policy discloses the privacy practices for our own web site only. Our site provides links to other websites also that are beyond our control. We shall in no way be responsible in way for your use of such sites.        </p>

        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 pt-3">INFORMATION SHARING
        </h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-base leading-relaxed ">
          There are also certain limitations on us sharing your personal information with third parties without prior user consent.
          (a) Law, legal processes or any competent authority may in certain situations demand the user’s identification or demand any information for the purpose of preventing or criminally investigating an act including cyber crimes or for the purpose of aiding in the prosecution of any offence. Such disclosures are made in good faith and in a belief that the enforcement of these Terms is mandatory; with respect to the applicable laws or regulations in effect.
          (b) Within a few group companies and their officers and employees, the Company may share such information for the purposes of services performed on its own personal data. Also, we make sure that the recipients of such information will process the information with our instructions, according to this Privacy Policy and all other reasonable protection policy and procedures.
        </p>

        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 pt-3">INFORMATION SECURITY
        </h1>
        <hr class="h-1 my-2 w-16 bg-blue-700 border-none dark:bg-blue-700" />
        <p className="text-gray-600 text-base sm:text-base leading-relaxed ">
        We implement appropriate security safeguards to deter and protect against access to data and its alteration, disclosure, or destruction by unauthorized parties. This includes internal assessments of the data collected and processes established for the safety of the data and the implementation of security measures such as appropriate levels of encryption and physical barriers to entry to where personal information is kept.
Any information collected from our Website is stored on a database that is under our management. This database is found in computers located behind a firewall and access to those computers is restricted by a password. Nonetheless, our security systems are effective as they are, there is no security system that is foolproof. We do not promise that our database will be safe from any threats, neither do we assure you that the information you send us through the Internet will not be compromised in any way. Naturally, any and all posting information in the discussion areas will be accessible to anyone with an active Internet connection.
But, the internet is a developing platform. Therefore, the need to adopt the subsequent changes will lead us to modify our Privacy Policy as needed. Certainly, the use of any information we gather will always be in accordance with the policy from which we collected the information, notwithstanding the potential change in the policy.
        </p>
      </div>

      <Footer />
    </div>
  )
}

export default PrivacyPolicy
