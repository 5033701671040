import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/send-email', formData);
      if (response.data.success) {
        toast.success('Email sent successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setFormData({ name: '', email: '', number: '', message: '' }); // Clear form
      } else {
        toast.error('Failed to send email.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error('An error occurred while sending the email.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div>
      <Header />
      <div className="page-title py-6 bg-slate-300" data-aos="fade">
        <div className="container mx-auto px-4 lg:px-20 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          <h1 className="text-black text-xl md:text-2xl font-semibold">Contact</h1>

          <nav className="breadcrumbs mt-2 lg:mt-0">
            <ol className="flex space-x-2 lg:space-x-4 text-sm">
              <li><Link to={'/'} className="text-black hover:underline text-base">Home</Link></li>
              <li className="text-black">/</li>
              <li><Link to={'/contact'} className="text-black hover:underline text-base">Contact</Link></li>
            </ol>
          </nav>
        </div>
      </div>
      <main className="main">
        <section id="contact" className="contact section">
          <div className="container mx-auto py-10 pl-10 pr-10">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
              <div className="p-5 bg-white shadow-lg rounded-md border-t-4 border-red-600">
                <h3 className="text-2xl font-semibold mb-4">Get in touch</h3>
                <p className="text-gray-700 mb-5">
                  Get in Touch with <span className="text-green-600">Make Easy</span> <span className="text-red-500">Documents</span> – Simplifying Your Paperwork Hassle-Free!
                </p>
                <div className="flex items-start mb-6">
                  <div className="flex-shrink-0">
                    <i className="bi bi-geo-alt text-red-600 text-2xl"></i>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-semibold text-red-600">Location:</h4>
                    <p className="text-gray-700">
                      1034, 10<sup>th</sup> Floor, Gera Imperium Rise, Wipro Circle, Rajiv Gandhi InfoTech Park-Phase 2, Hinjawadi, Pune.
                    </p>
                  </div>
                </div>
                <div className="flex items-start mb-6">
                  <div className="flex-shrink-0">
                    <i className="bi bi-envelope text-red-600 text-2xl"></i>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-semibold text-red-600">Email:</h4>
                    <p className="text-gray-700">info@makeeasydocuments.com</p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <i className="bi bi-phone text-red-600 text-2xl"></i>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-semibold text-red-600">Call:</h4>
                    <p className="text-gray-700">+91 9168 7415 15</p>
                    {/* <p className="text-gray-700">+91 9168 7415 15</p> */}
                  </div>
                </div>
              </div>

              <div className="lg:col-span-2 p-8 bg-white shadow-lg rounded-md border-t-4 border-red-600">
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
                    <div>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-red-600"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-red-600"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                    <div className="mb-6">
                      <input
                        type="text"
                        name="number"
                        value={formData.number}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-red-600"
                        placeholder="Number"
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-6">
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      className="w-full h-32 px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-red-600"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="bg-red-600 text-white py-3 px-8 rounded-full hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Contact;
