import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';

const Team = () => {
    const [teamMembers, setTeamMembers] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state

    // Fetch team members on component mount
    useEffect(() => {
        const fetchTeamMembers = async () => {
            try {
                const response = await axios.get('/api/teammembers/getAllTeamMembers');
                console.log('Fetched team members:', response.data.teamMembers); // Debug log
                setTeamMembers(response.data.teamMembers || []); // Ensure it's an array
            } catch (error) {
                console.error('Error fetching team members:', error);
                showCustomToast('Failed to fetch team members.','error'); // Show error notification
                setTeamMembers([]); // Reset team members on error
            } finally {
                setLoading(false); // Stop loading state
            }
        };

        fetchTeamMembers();
    }, []);

    // Safe image conversion helper function
    const convertToBase64 = (buffer) => {
        try {
            const uint8Array = new Uint8Array(buffer);
            const chunks = [];
            for (let i = 0; i < uint8Array.length; i++) {
                chunks.push(String.fromCharCode(uint8Array[i]));
            }
            return btoa(chunks.join(''));
        } catch (error) {
            console.error('Error converting image to base64:','error', error);
            return null;
        }
    };

    const getImageSrc = (member) => {
        if (Array.isArray(member.images) && member.images.length > 0 && member.images[0].data) {
            const byteArray = new Uint8Array(member.images[0].data.data || []);
            const base64String = convertToBase64(byteArray);
            return `data:${member.images[0].contentType};base64,${base64String}`;
        }
        return 'https://via.placeholder.com/150'; // Fallback to a default image if needed
    };

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <p className="text-lg font-semibold text-blue-900">Loading team members...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <p className="text-lg font-semibold text-red-600">{error}</p>
            </div>
        );
    }

    return (
        <div>
            <Header />
            <div className="min-h-screen bg-blue-50 p-6">
                <div className="container mx-auto">
                    <h1 className="text-4xl font-extrabold text-center mb-8 text-blue-900">Meet Our Team</h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {teamMembers.map((member) => (
                            <div
                                key={member._id} // Use a unique identifier for the key
                                className="bg-white p-6 rounded-lg shadow-md transition duration-300 transform hover:scale-105 hover:shadow-lg hover:bg-blue-100 text-center"
                            >
                                <img
                                    src={getImageSrc(member)} // Use the helper function to get image src
                                    alt={member.name}
                                    className="w-28 h-28 rounded-full mx-auto mb-4 object-cover transition-transform duration-300 ease-in-out hover:scale-110 hover:rotate-6"
                                />
                                <h2 className="text-2xl font-semibold text-blue-800 mb-1">{member.name}</h2>
                                <p className="text-blue-600">{member.designation}</p>
                                <p className='text-blue-600'>{member.description}</p>

                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <CustomToastContainer/>
            <Footer />
        </div>
    );
};

export default Team;
