import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FaTrash } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import axios from 'axios';
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

const Addlinks = () => {
  const [videoFiles, setVideoFiles] = useState([]);
  const [videoCarousels, setVideoCarousels] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [carouselToDelete, setCarouselToDelete] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch video carousels on component mount
  useEffect(() => {
    const fetchVideoCarousels = async () => {
      try {
        const response = await axios.get('/api/videoroutes/getAllVideoCarousels');
        setVideoCarousels(response.data.carousels);
      } catch (error) {
        console.error('Error fetching video carousels:', error);
        showCustomToast('Failed to fetch video carousels. Please try again later.','error');
        setError('Failed to fetch video carousels.','error');
      } finally {
        setLoading(false);
      }
    };
    fetchVideoCarousels();
  }, []);

  // Handle video file selection
  const handleFileChange = (e) => {
    setVideoFiles(e.target.files);
  };

  // Handle form submission for video upload
  const handleUpload = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (let i = 0; i < videoFiles.length; i++) {
      formData.append('videos', videoFiles[i]);
    }

    try {
      await axios.post('/api/videoroutes/createVideoCarousel', formData);
      const response = await axios.get('/api/videoroutes/getAllVideoCarousels');
      setVideoCarousels(response.data.carousels);
      setVideoFiles([]); // Reset file input
      showCustomToast('Videos uploaded successfully!');
    } catch (error) {
      console.error('Error uploading videos:', error);
      showCustomToast('Failed to upload videos. Please try again.','error');
    }
  };

  // Handle delete carousel
  const handleDeleteCarousel = async () => {
    setDeleteLoading(true);
    try {
      await axios.delete(`/api/videoroutes/deleteVideoCarousel/${carouselToDelete}`);
      setVideoCarousels(videoCarousels.filter((carousel) => carousel._id !== carouselToDelete));
      showCustomToast('Video Carousel deleted successfully!');
    } catch (error) {
      console.error('Error deleting video carousel:', error);
      showCustomToast('Failed to delete video carousel. Please try again.','error');
    } finally {
      setShowDeleteModal(false);
      setCarouselToDelete(null);
      setDeleteLoading(false);
    }
  };

  // Handle video modal opening
  const handleOpenVideoModal = (videoData) => {
    setVideoLoading(true);
    try {
      const videoBuffer = videoData.videos[0].data.data;
      const blob = new Blob([new Uint8Array(videoBuffer)], { type: videoData.videos[0].contentType });
      const videoUrl = URL.createObjectURL(blob);

      setSelectedVideo(videoUrl);
      setShowVideoModal(true);
    } catch (error) {
      console.error('Error decoding video data:', error);
      showCustomToast('Failed to open video. Invalid format.','error');
    } finally {
      setVideoLoading(false);
    }
  };

  // Handle opening the delete confirmation modal
  const handleOpenDeleteModal = (id) => {
    setCarouselToDelete(id);
    setShowDeleteModal(true);
  };

  // Handle closing the delete confirmation modal
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setCarouselToDelete(null);
  };

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error) return <div className="text-center mt-10 text-red-500">{error}</div>;

  return (
    <div className='flex1'>
      <h2 className="mt-3 mx-3 mb-3 font-bold text-3xl border border-black text-blue-800 text-center bg-gray-300 p-2 rounded-lg shadow-sm transition-transform duration-300">
        Add Video Link (For User Dashboard)
      </h2>

      {/* Upload Video Form */}
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: 'auto', opacity: 1 }}
        exit={{ height: 0, opacity: 0 }}
        className='overflow-x-auto w-full'
      >
        <section className="max-w-4xl p-6 mb-12 mt-10 mx-4 md:mx-auto border border-black bg-blue-100 rounded-md shadow-xl">
          <form onSubmit={handleUpload}>
            <div className="grid justify-center grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
              <div>
                <label className=" font-semibold text-xl" htmlFor="video">
                  Upload Video Carousels: <span className="text-red-500">*</span>
                </label>
                <input
                  id="video"
                  type="file"
                  multiple
                  accept="video/*"
                  onChange={handleFileChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-dark border border-black rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
                  required
                />
              </div>
            </div>
            <div className='flex items-center justify-end gap-4 mt-6'>
              <button
                type="submit"
                className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
              >
                Upload
              </button>
            </div>
          </form>
        </section>
      </motion.div>

      {/* Video Carousel */}
      <div className='mt-10 mx-4 md:mx-auto px-3'>
        {videoCarousels.length === 0 ? (
          <div className="text-center text-gray-500">No video carousels found.</div>
        ) : (
          videoCarousels.map((carousel) => (
            <div key={carousel._id} className='flex justify-between items-center p-4 bg-gray-800 rounded-lg mb-4'>
              <span className='text-white'>{carousel.videos.length} Videos</span>
              <div className='flex space-x-4'>
                <button className="text-blue-600 hover:underline" onClick={() => handleOpenVideoModal(carousel)}>
                  {videoLoading ? 'Loading...' : 'View Videos'}
                </button>
                <button className="text-red-600 hover:underline" onClick={() => handleOpenDeleteModal(carousel._id)}>
                  <FaTrash />
                </button>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            className="bg-white rounded-lg shadow-lg p-6"
          >
            <h3 className="text-lg font-semibold">Confirm Deletion</h3>
            <p className="mt-2">Are you sure you want to delete this video carousel?</p>
            <div className="flex justify-end mt-4">
              <button className="px-4 py-2 text-white bg-red-600 rounded hover:bg-red-700" onClick={handleDeleteCarousel} disabled={deleteLoading}>
                {deleteLoading ? 'Deleting...' : 'Delete'}
              </button>
              <button className="px-4 py-2 text-gray-500 border rounded hover:bg-gray-200" onClick={handleCloseDeleteModal}>
                <MdClose />
              </button>
            </div>
          </motion.div>
        </div>
      )}

      {/* Video Modal */}
      {showVideoModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            className="bg-white rounded-lg shadow-lg p-6"
          >
            <h3 className="text-lg font-semibold">Video</h3>
            <video controls className="w-full h-auto mt-4">
              <source src={selectedVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="flex justify-end mt-4">
              <button className="px-4 py-2 text-gray-500 border rounded hover:bg-gray-200" onClick={() => setShowVideoModal(false)}>
                <MdClose />
              </button>
            </div>
          </motion.div>
        </div>
      )}

      {/* Toast Container for Notifications */}
      <CustomToastContainer />
    </div>
  );
};

export default Addlinks;
