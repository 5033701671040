import { createContext, useContext, useState } from 'react';

const SearchContext = createContext();

export const useSearch = () => useContext(SearchContext);

export const SearchProvider = ({ children }) => {
    const [searchResults, setSearchResults] = useState([]);

    const handleSearch = (results) => {
        setSearchResults(results);
    };

    return (
        <SearchContext.Provider value={{ searchResults, handleSearch }}>
            {children}
        </SearchContext.Provider>
    );
};
